import React from "react";
import { API_URL } from "./config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import Members from "./pages/Members";
import { Layout, ConfigProvider, message, notification, Row } from "antd";
import "moment/locale/tr";
import trTR from "antd/lib/locale/tr_TR";
import jwt from "jsonwebtoken";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Schedules from "./pages/Schedules";
import Roles from "./pages/Roles";
import { getAllowedEnpoints, responseControl } from "./assets/scripts";
import Dashboard from "./pages/Dashboard";
import Logs from "./pages/Logs";
import Payment from "./pages/Payment";
import Cards from "./pages/Cards";
import Now from "./components/Now";
import Clubs from "./pages/Clubs";
import Trainers from "./pages/Trainer";
import PoolCalendar from "./pages/PoolCalendar";
import "./App.css";
import Devices from "./pages/Devices";
import Files from "./pages/Files";
import Whoami from "./components/Whoami";
import Verifications from "./pages/Verifications";
import Terminal from "./pages/Terminal";
import UserSettings from "./pages/UserSettings";
import Bills from "./pages/Bills";
// import Vault from "./pages/Vault";
import logo from "./assets/images/logo512.png";
import Facilities from "./pages/Facilities";
import Seasons from "./pages/Seasons";
import Tickets from "./pages/Tickets";
import Appointments from "./pages/Appointments";
import Programs from "./pages/Programs";
import BankAccount from "./pages/BankAccount";
import CJoyride from "./helpers/CJoyride";
import steps from "./assets/scripts/steps";
import About from "./pages/About";
import Refunds from "./pages/Refunds";
import PoolUsage from "./pages/PoolUsage";
import Courses from "./pages/Courses";
import Reports from "./pages/Reports";
import GeneralBills from "./pages/GeneralBills";
import SubsTickets from "./pages/Subscriptions";
import Notifications from "./pages/Notifications";
import Rules from "./pages/Rules";
import HealthReports from "./pages/HealthReports";
import SingleHealthReport from "./pages/SingleHealthReport";

const { Header, Content, Footer } = Layout;

const contents = {
  reports: <Reports />,
  members: <Members />,
  cards: <Cards />,
  logs: <Logs />,
  schedules: <Schedules />,
  roles: <Roles />,
  users: <Users />,
  payments: <Payment />,
  bills_all: <GeneralBills />,
  bills: <Bills />,
  clubs: <Clubs />,
  pool_usage_requests: <PoolUsage />,
  trainers: <Trainers />,
  calendars: <PoolCalendar />,
  devices: <Devices />,
  files: <Files />,
  verifications: <Verifications />,
  terminal: <Terminal />,
  subscriptions: <SubsTickets />,
  facilities: <Facilities />,
  seasons: <Seasons />,
  tickets: <Tickets />,
  appointments: <Appointments />,
  programs: <Programs />,
  facility_bank_accounts: <BankAccount />,
  refunds: <Refunds />,
  courses: <Courses />,
  notifications: <Notifications />,
  rules: <Rules />,
  health_checks: <HealthReports />
};

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [credentials, setCredentials] = React.useState({});
  const [decodedToken, setDecodedToken] = React.useState({});
  const [allowedEndpoints, setAllowedEndpoints] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [appVersion, setAppVersion] = React.useState(null);

  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      jwt.verify(
        localStorage.getItem("access_token"),
        process.env.REACT_APP_SECRET_KEY,
        (error, decoded) => {
          if (decoded) {
            setDecodedToken(decoded);
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        }
      );
      document.getElementsByTagName("body")[0].style.background =
        "rgb(240 242 245)";
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(decodedToken).length > 0) {
      const roleInfo = decodedToken?.hash || decodedToken.role;
      getAllowedEnpoints(roleInfo, setAllowedEndpoints);
    }
  }, [decodedToken]);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/version`)
      .then((response) => response.text())
      .then((result) => setAppVersion(result))
      .catch((error) => {
        //prettier-ignore
        return notification.error({ message: error instanceof Error ? error.message : error })
      });
  }, []);

  const handleCredentials = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = async () => {
    setIsLoading(true);
    await fetch(`${API_URL}/api/v1/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => responseControl(response, true))
      .then((result) => {
        if (result?.token === undefined) {
          return false;
        }
        localStorage.setItem("access_token", result?.token);
        return true;
      })
      .then((reload) => (reload ? window.location.reload() : ""))
      .catch((error) => {
        if (error instanceof Error) {
          return message.error({
            content: (
              <div
                dangerouslySetInnerHTML={{ __html: error.message }}
                style={{ display: "inline-block" }}
              ></div>
            ),
            style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
          });
        }
        if (typeof error === "string") {
          return message.error({
            content: (
              <div
                dangerouslySetInnerHTML={{ __html: error }}
                style={{ display: "inline-block" }}
              ></div>
            ),
            style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
          });
        } else {
          return console.log(error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleExit = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  return (
    <ConfigProvider locale={trTR}>
      <Router>
        {isLoggedIn === true ? (
          <Layout className="layout" style={{ height: "100%" }}>
            <CJoyride steps={steps} />
            <Header className="header">
              <div className="logo" />
              <Whoami
                profilePhoto={decodedToken?.profile_photo}
                username={decodedToken?.full_name}
                handleExit={handleExit}
              />
              <Now />
              <NavBar
                role={decodedToken.hash || decodedToken.role}
                facility_id={decodedToken.facility_id}
              />
            </Header>
            <Content
              style={{
                padding: "0 50px",
                minHeight: "100vh",
              }}
              className="content"
            >
              <Switch>
                <Route path="/health-check">
                  <SingleHealthReport />
                </Route>
                {allowedEndpoints.length === 0
                  ? null
                  : allowedEndpoints.includes("*")
                    ? Object.keys(contents).map((endpoint) => {
                      if (Number(decodedToken.facility_id) === 0) {
                        if (
                          ![
                            "tickets",
                            "seasons",
                            "terminal",
                            "facility_bank_accounts",
                          ].includes(endpoint)
                        ) {
                          return (
                            <Route path={`/${endpoint}`}>
                              {contents[endpoint]}
                            </Route>
                          );
                        }
                      } else {
                        return (
                          <Route path={`/${endpoint}`}>
                            {contents[endpoint]}
                          </Route>
                        );
                      }
                    })
                    : allowedEndpoints.map((endpoint) => {
                      if (Number(decodedToken.facility_id) === 0) {
                        if (
                          ![
                            "tickets",
                            "seasons",
                            "terminal",
                            "facility_bank_accounts",
                          ].includes(endpoint)
                        ) {
                          return (
                            <Route path={`/${endpoint}`}>
                              {contents[endpoint]}
                            </Route>
                          );
                        }
                      } else {
                        return (
                          <Route path={`/${endpoint}`}>
                            {contents[endpoint]}
                          </Route>
                        );
                      }
                    })}
                <Route path="/me">
                  <UserSettings />
                </Route>
                {Number(decodedToken.facility_id) === 0 ? (
                  <Route path="/facilities">
                    <Facilities />
                  </Route>
                ) : null}
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/">
                  <Dashboard allowedEndpoints={allowedEndpoints} />
                </Route>
                <Route path="/reports">
                  <Reports allowedEndpoints={allowedEndpoints} />
                </Route>

              </Switch>
            </Content>
            <Footer
              style={{
                backgroundColor: "#262626",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                margin: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "center",
                  textAlign: "end",
                }}
              >
                <img src={logo} width="2%" height="2%" alt="logo" />
                <p style={{ marginLeft: 5, marginRight: 5 }}>
                  GSB Havuz Takip Terminali © 2023 <br />
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={"/about"}
                  style={{ color: "tomato", marginRight: "10px" }}
                >
                  HAKKIMIZDA
                </Link>{" "}
                <div dangerouslySetInnerHTML={{ __html: appVersion }}></div>
              </div>
              <p style={{ marginLeft: 5, marginRight: 5 }}>Demo Ürün</p>
            </Footer>
          </Layout>
        ) : (
          <Switch>
            <Route path="/">
              <div className="login-wrapper">
                <Login
                  loading={isLoading}
                  handleChange={handleCredentials}
                  handleClick={handleLogin}
                />
              </div>
            </Route>
            <Redirect to="/" />
          </Switch>
        )}
      </Router>
    </ConfigProvider>
  );
}
