import React from "react";
import { Layout } from "antd";
import Group from "../assets/images/Group.svg";

const { Header, Content } = Layout;

export default function About() {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "2em 5em",
        height: "98vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <div>
        <Header style={{ background: "transparent" }}>
          <h2
            style={{
              fontSize: 63,
              letterSpacing: 14,
              fontWeight: 200,
              color: "#DC002E",
            }}
          >
            HAKKIMIZDA
          </h2>
        </Header>
        <Content
          style={{
            fontSize: 18,
            padding: "0  3.1em",
            maxWidth: "60ch",
            marginTop: "1.3em",
          }}
        >
          <p>
            Tesis Yönetim Terminali tesisler yapılan giriş/çıkış işlemlerinin,
            üye, tesis ve program bilgilerinin kaydını tutar ve sizin
            tesislerinizi yönetmeniz de kolaylık sağlar.
          </p>

          <p style={{ fontStyle: "italic", marginTop: "2em" }}>
            Tesis Yönetim Terminali bir güvenlik uygulaması{" "}
            <span style={{ color: "#DC002E" }}>değildir</span>.
            <br />
            Tesis Yönetim Terminali bir muhasebe yazılımı{" "}
            <span style={{ color: "#DC002E" }}>değildir</span>.
          </p>

          <p
            style={{
              fontWeight: "lighter",
              fontSize: "1rem",
              marginTop: "1em",
            }}
          >
            Sorularınız için bize{" "}
            <a
              style={{ color: "#DC002E" }}
              href="mailto:info@stelab.com.tr"
              rel="noreferrer nofollow"
            >
              info@stelab.com.tr
            </a>{" "}
            den ulaşabilirsiniz.
            <br />
            Ek bilgiler için{" "}
            <a
              href="https://www.stelab.com.tr/"
              style={{ color: "#DC002E" }}
              rel="noreferrer nofollow"
              target={"_blank"}
            >
              www.stelab.com.tr
            </a>
            Bu bir demo ürünüdür
          </p>
        </Content>
      </div>
      <img src={Group} alt="people at a desk" />
    </div>
  );
}
