import { API_URL } from "../config";
import { Tag } from "antd";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { useLocation } from "react-router-dom";



const HealthReports = () => {

    const columns = [
        {
            title: "Ad",
            dataIndex: "first_name",
            defaultSelected: true,
            width: 200
        },
        {
            title: "Soyad",
            dataIndex: "last_name",
            defaultSelected: true,
            width: 200
        },
        {
            title: "Kimlik Numarası",
            dataIndex: "tc_no",
            defaultSelected: true,
            width: 200
        },
        {
            title: "Hastalıkları",
            dataIndex: "health_report",
            render: (list, record) => {
                return list.reduce((a, i) => {
                    if (i.has_illness === "evet") {
                        a.push(i.name)
                    }
                    return a
                }, []).join(", ")
            },
            defaultSelected: true,
        },
        {
            title: "KVKK metni",
            dataIndex: "kvkk_checked",
            width: 150,
            render: bool => {
                return bool ? <Tag color="green">ONAYLANDI</Tag> : <Tag color="red">ONAYLANMADI</Tag>
            },
            defaultSelected: true,
        },
    ];
    let location = useLocation();
    const [dataSource, setDataSource] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        setIsLoading(true)
        fetch(`${API_URL}/api/v1/health_checks`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.ok && response.json())
            .then((r) => {
                if (Array.isArray(r)) setDataSource(r)
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false))
    }, [location]);

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <CTable
                title="SAĞLIK RAPORLARI"
                scrollX={3500}
                dataSource={dataSource}
                columns={columns}
                type="health_checks"
                excel={false}
                isLoading={isLoading}
            />
        </ErrorBoundary>
    );
};

export default HealthReports;
