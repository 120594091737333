import { message } from "antd";
import { responseControl } from ".";

const uploadFile = (
  fileWillSend,
  url,
  isUpdate,
  additionals = null,
  reload = false
) => {
  const data =
    additionals === null || typeof additionals !== "object"
      ? {
          base64: fileWillSend,
          date: new Date().getTime(),
        }
      : {
          base64: fileWillSend,
          ...additionals,
          date: new Date().getTime(),
        };

  fetch(url, {
    method: isUpdate ? "PUT" : "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => responseControl(response))
    .then((result) => {
      if (result) {
        if (reload === true) {
          window.location.reload();
        }
      }
    })
    .catch((error) =>
      error instanceof Error
        ? message.error(error.message)
        : typeof error === "string"
        ? message.error(error)
        : console.log(error)
    );
};

export default uploadFile;
