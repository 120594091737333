import { API_URL } from "../config";
import React from "react";
import { Button, Form, Input, Modal, Select } from "antd";

import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  UserOutlined,
  TeamOutlined,
  PayCircleOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  StarOutlined,
  SkinOutlined,
  DashboardOutlined,
  CalendarOutlined,
  DesktopOutlined,
  VerifiedOutlined,
  FilePdfOutlined,
  CalculatorOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  PlusCircleOutlined,
  BookOutlined,
  BankOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const endpoints = [
  "members",
  "schedules",
  "verifications",
  "cards",
  "logs",
  "users",
  "roles",
  "payments",
  "bills",
  "clubs",
  "gsbClubs",
  "pool_usage_requests",
  "trainers",
  "programs",
  "calendars",
  "devices",
  "seasons",
  "tickets",
  // "vault",
  "notifications",
  "appointments",
  "facility_bank_accounts",
  "health_checks"
];

const returns = {
  members: "Üyeler",
  files: "Dosyalar",
  verifications: "Onaylamalar",
  cards: "Kartlar",
  logs: "Loglar",
  schedules: "Tarifeler",
  roles: "Roller",
  users: "Kullanıcılar",
  payments: "Ödemeler",
  bills: "Harcama Detayı",
  clubs: "Spor Kulüpleri",
  gsbClubs: "GSB Spor Kulüpleri",
  pool_usage_requests: "Havuz Kullanım talebleri",
  trainers: "Antrenörler",
  programs: "Programlar",
  calendars: "Takvim",
  devices: "Cihazlar",
  // vault: "Kasa",
  seasons: "Seanslar",
  tickets: "Biletler",
  appointments: "Randveular",
  facility_bank_accounts: "Banka Hesabı",
  notifications: "Bildirimler",
  "*": "Hepsi",
};

const icons = {
  members: <UserOutlined />,
  files: <FilePdfOutlined />,
  verifications: <VerifiedOutlined />,
  cards: <CreditCardOutlined />,
  logs: <SaveOutlined />,
  schedules: <PayCircleOutlined />,
  roles: <UserAddOutlined />,
  users: <TeamOutlined />,
  payments: <DollarCircleOutlined />,
  bills: <CalculatorOutlined />,
  clubs: <SkinOutlined />,
  gsbClubs: <SkinOutlined />,
  pool_usage_requests: <FileTextOutlined />,
  trainers: <DashboardOutlined />,
  programs: <BookOutlined />,
  calendars: <CalendarOutlined />,
  devices: <DesktopOutlined />,
  // vault: <MoneyCollectOutlined />,
  seasons: <ScheduleOutlined />,
  tickets: <ContainerOutlined />,
  appointments: <PlusCircleOutlined />,
  facility_bank_accounts: <BankOutlined />,
  notifications: <ContainerOutlined />,
  "*": <StarOutlined />,
};

const RolesModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setIsUpdate(false);
      setFields({});
    }
  }, [props]);

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    return await fetch(`${API_URL}/api/v1/roles`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
          body: { ...fields },
          where: { role_id: props.record.role_id },
        })
        : JSON.stringify({ ...fields }),
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Rol Adı" name="role_name">
          <Input name="role_name" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Kullanıcı Onayı" name="verification">
          <Select
            showArrow
            value={fields.verification}
            onSelect={(value) =>
              handleChange({ target: { name: "verification", value } })
            }
          >
            <Option value={true}>Var</Option>
            <Option value={false}>Yok</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Görüntüleyebilir" name="get">
          <div style={{ display: "none" }}>{fields?.get?.split(",")}</div>
          <Select
            showArrow
            value={fields?.get?.split(",")}
            mode="multiple"
            onChange={(value) =>
              handleChange({ target: { name: "get", value: value.join(",") } })
            }
          >
            {endpoints.map((endpoint) => (
              <Option value={endpoint}>
                {icons[endpoint]} {returns[endpoint]}
              </Option>
            ))}
            <Option value="*">Hepsi</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Ekleyebilir" name="post">
          <div style={{ display: "none" }}>{fields?.get?.split(",")}</div>

          <Select
            showArrow
            value={fields?.post?.split(",")}
            mode="multiple"
            onChange={(value) =>
              handleChange({ target: { name: "post", value: value.join(",") } })
            }
          >
            {endpoints.map((endpoint) => (
              <Option value={endpoint}>
                {icons[endpoint]} {returns[endpoint]}
              </Option>
            ))}
            <Option value="*">Hepsi</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Düzenleyebilir" name="put">
          <div style={{ display: "none" }}>{fields?.get?.split(",")}</div>

          <Select
            showArrow
            value={fields?.put?.split(",")}
            mode="multiple"
            onChange={(value) =>
              handleChange({ target: { name: "put", value: value.join(",") } })
            }
          >
            {endpoints.map((endpoint) => (
              <Option value={endpoint}>
                {icons[endpoint]} {returns[endpoint]}
              </Option>
            ))}
            <Option value="*">Hepsi</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Silebilir" name="delete">
          <div style={{ display: "none" }}>{fields?.get?.split(",")}</div>

          <Select
            showArrow
            mode="multiple"
            value={fields?.delete?.split(",")}
            onChange={(value) =>
              handleChange({
                target: { name: "delete", value: value.join(",") },
              })
            }
          >
            {endpoints.map((endpoint) => (
              <Option value={endpoint}>
                {icons[endpoint]} {returns[endpoint]}
              </Option>
            ))}
            <Option value="*">Hepsi</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RolesModal;
