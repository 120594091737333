import { API_URL } from "../../config";
import React from "react";
import { Bar, Column, Line } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";
import moment from "moment";

const GenderActivities = (props) => {
  const config = {
    appendPadding: [30, 50],
    xField: "value",
    yField: "gender",
    seriesField: "value",
    label: {
      position: "middle",
      style: {
        fontSize: 50,
        fill: "#00000",
        opacity: 10,
      },
    },
    point: {
      size: 5,
      shape: "diamond | circule",
    },
  };

  const [logs, setLogs] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/logs?include=member`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((result) => setLogs(result))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let dailyLogs = {};
    const activities = [];
    if (logs?.length > 0) {
      logs.reverse().map((log) => {
        if (log?.member?.gender) {
          //prettier-ignore
          const logDate = moment(new Date(log.login)).format("YYYY-MM");
          if (logDate === props.date) {
            if (dailyLogs.hasOwnProperty(`${log?.member?.gender}`)) {
              //prettier-ignore
              dailyLogs[`${log?.member?.gender}`] = dailyLogs[`${log?.member?.gender}`] + 1;
            } else {
              dailyLogs[`${log?.member?.gender}`] = 1;
            }
          }
        } else {
          if (dailyLogs.hasOwnProperty("Belirtilmemiş")) {
            dailyLogs["Belirtilmemiş"] += 1;
          } else {
            dailyLogs["Belirtilmemiş"] = 1;
          }
        }
      });
      Object.keys(dailyLogs).map((key) =>
        //prettier-ignore
        activities.push( { gender: key === "male" ? "Erkek" : key === "female" ? "Kadın" : key, value: dailyLogs[key] })
      );

      setChartData(activities);
    }
  }, [logs]);

  return <Bar data={chartData} {...config} />;
};

export default GenderActivities;
