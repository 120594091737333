const nationalHolidays = {
    2024: [
        {
            date: '2024-01-01',
            name: 'Yılbaşı',
            duration: '1 gün',
        },
        {
            date: '2024-04-09',
            name: 'Ramazan Bayramı (Arefe yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2024-04-10',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-04-11',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-04-12',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-04-23',
            name: 'Ulusal Egemenlik ve Çocuk Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-05-01',
            name: 'Emek ve Dayanışma Günü',
            duration: '1 gün',
        },
        {
            date: '2024-05-19',
            name: 'Atatürk’ü Anma, Gençlik ve Spor Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-06-15',
            name: 'Kurban Bayramı (Arefe yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2024-06-16',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-06-17',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-06-18',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-06-19',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-07-15',
            name: 'Demokrasi ve Milli Birlik Günü',
            duration: '1 gün',
        },
        {
            date: '2024-08-30',
            name: 'Zafer Bayramı',
            duration: '1 gün',
        },
        {
            date: '2024-10-28',
            name: 'Cumhuriyet Bayramı (yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2024-10-29',
            name: 'Cumhuriyet Bayramı',
            duration: '1 gün',
        },
    ],
    2023: [
        {
            date: '2023-01-01',
            name: 'Yılbaşı',
            duration: '1 gün',
        },
        {
            date: '2023-04-20',
            name: 'Ramazan Bayramı (Arefe yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2023-04-21',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-04-22',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-04-23',
            name: 'Ramazan Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-04-23',
            name: 'Ulusal Egemenlik ve Çocuk Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-05-01',
            name: 'Emek ve Dayanışma Günü',
            duration: '1 gün',
        },
        {
            date: '2023-05-19',
            name: 'Atatürk’ü Anma, Gençlik ve Spor Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-06-27',
            name: 'Kurban Bayramı (Arefe yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2023-06-28',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-06-29',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-06-30',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-06-01',
            name: 'Kurban Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-07-15',
            name: 'Demokrasi ve Milli Birlik Günü',
            duration: '1 gün',
        },
        {
            date: '2023-08-30',
            name: 'Zafer Bayramı',
            duration: '1 gün',
        },
        {
            date: '2023-10-28',
            name: 'Cumhuriyet Bayramı (yarım gün)',
            duration: 'Yarım gün',
        },
        {
            date: '2023-10-29',
            name: 'Cumhuriyet Bayramı',
            duration: '1 gün',
        },
    ]
}

export default nationalHolidays;