import React from "react";
import jwt from "jsonwebtoken";
import { Button, Menu, message, notification } from "antd";
import {
  UserOutlined,
  PieChartOutlined,
  TeamOutlined,
  PayCircleOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  SaveOutlined,
  DollarCircleOutlined,
  SkinOutlined,
  DashboardOutlined, AppstoreOutlined,
  CalendarOutlined,
  DesktopOutlined,
  FilePdfOutlined,
  VerifiedOutlined,
  DatabaseOutlined,
  CalculatorOutlined,
  BankOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  PlusCircleOutlined,
  BookOutlined,
  MenuOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  ReadOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getAllowedEnpoints, responseControl } from "../assets/scripts";
import { API_URL } from "../config";
import { io } from "socket.io-client";


const returns = {
  reports: "Genel Raporlar",
  // files: "Dosyalar",
  members: "Üyeler",
  trainers: "Antrenörler",
  cards: "Kartlar",
  logs: "Hareketler",
  verifications: "Onaylamalar",
  subscriptions: "Abonelikler",
  bills_all: "Ödeme Özetleri",
  payments: "Ödeme Detayı",
  tickets: "Biletler",
  bills: "Harcama Detayı",
  seasons: "Seanslar",
  appointments: "Randevular",
  schedules: "Tarifeler",
  clubs: "Spor Kulüpleri",
  pool_usage_requests: "Havuz Kullanım Talebleri",
  programs: "Programlar",
  calendars: "Takvim",
  devices: "Cihazlar",
  terminal: "Terminal Bilgileri",
  roles: "Roller",
  users: "Kullanıcılar",
  facility_bank_accounts: "Banka Hesabı",
  refunds: "İade Talepleri",
  courses: "Kurslar",
  notifications: "Bildirimler",
  rules: "Tesis Kuralları"
};

const icons = {
  reports: <PieChartOutlined />,
  members: <UserOutlined />,
  files: <FilePdfOutlined />,
  verifications: <VerifiedOutlined />,
  cards: <CreditCardOutlined />,
  subscriptions: <CreditCardOutlined />,
  logs: <SaveOutlined />,
  schedules: <PayCircleOutlined />,
  roles: <UserAddOutlined />,
  users: <TeamOutlined />,
  payments: <DollarCircleOutlined />,
  bills_all: <CalculatorOutlined />,
  bills: <CalculatorOutlined />,
  clubs: <SkinOutlined />,
  pool_usage_requests: <FileTextOutlined />,
  trainers: <DashboardOutlined />,
  programs: <BookOutlined />,
  calendars: <CalendarOutlined />,
  devices: <DesktopOutlined />,
  terminal: <DatabaseOutlined />,
  seasons: <ScheduleOutlined />,
  tickets: <ContainerOutlined />,
  appointments: <PlusCircleOutlined />,
  facility_bank_accounts: <BankOutlined />,
  refunds: <ScheduleOutlined />,
  courses: <ReadOutlined />,
  notifications: <ContainerOutlined />,
  rules: <BookOutlined />,
  health_checks: <BookOutlined />
};

const NavBar = (props) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      KAPAT
    </Button>
  );

  React.useEffect(() => {

    const socket = io(API_URL, {
      auth: {
        token: "Bearer " + localStorage.getItem("access_token"),
      },
    });

    socket?.on("danger_log", (message) => {
      if (message) {
        notification.error({
          duration: 0,
          btn,
          key,
          message:
            message.member.first_name + " " + message.member.last_name + " giriş yaptı ve sağlık raporu uygun değil.\n" + message.message
        })
      }
    });
    socket.emit("danger_log", "danger");
    socket.on("disconnect", () => {
      socket.connect();
    });
  }, []);

  const [current, setCurrent] = React.useState("");
  const [allowedEndpoints, setAllowedEndpoints] = React.useState([]);
  const [collapseIcon, setCollapseIcon] = React.useState(
    <MenuOutlined className="open-menu-btn" id="open-menu-btn" />
  );
  React.useEffect(() => {
    if (window.location.pathname === "/") {
      setCurrent("dashboard");
    } else {
      setCurrent(window.location.pathname.split("/")[1]);
    }
  }, []);

  React.useEffect(() => {
    getAllowedEnpoints(props.role, setAllowedEndpoints);
  }, [props.role]);

  const handleClick = (event) => {
    setCurrent(event.key);
  };
  const [facility, setFacility] = React.useState({});
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/facility-info`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => responseControl(response))
      .then((f) => {
        if (f) setFacility(f)
      })
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
  }, []);

  const [decodedToken, setDecodedToken] = React.useState({});
  React.useEffect(() => {
    const token = jwt.decode(localStorage.getItem("access_token"));
    if (token) {
      setDecodedToken(token);
    }
  }, []);
  return (
    <Menu
      overflowedIndicator={collapseIcon}
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      className="navbar-main"
      theme="light"
      style={{ justifyContent: "flex-end" }}
      triggerSubMenuAction="click"
      onOpenChange={(status) =>
        status.length > 0
          ? setCollapseIcon(<CloseOutlined />)
          : setCollapseIcon(
            <MenuOutlined className="open-menu-btn" id="open-menu-btn" />
          )
      }
    >
      <Menu.Item style={{ marginRight: "auto" }}>
        <span>
          {facility.province}  {facility.facility_name ? " | " + facility.facility_name : ""}
        </span>

      </Menu.Item>
      <Menu.Item
        className="dashboard"
        key="dashboard"
        icon={<PieChartOutlined />}
      >
        <Link to="/">Panel</Link>
      </Menu.Item>
      {
        allowedEndpoints.includes("*") ? <Menu.Item
          className="reports"
          key="reports"
          icon={<PieChartOutlined />}
        >
          <Link to="/reports">Genel Raporlar</Link>
        </Menu.Item>
          : allowedEndpoints.includes("reports") ? <Menu.Item
            className="reports"
            key="reports"
            icon={<PieChartOutlined />}
          >
            <Link to="/reports">Genel Raporlar</Link>
          </Menu.Item> : null
      }
      <Menu.SubMenu key="facility_member" title="Tesis Üyeleri" icon={<AppstoreOutlined />} >
        {
          Object.entries({
            members: "Üyeler",
            trainers: "Antrenörler",
            cards: "Kartlar",
            logs: "Hareketler",
            verifications: "Onaylamalar",
            health_checks: "Sağlık Raporları"
          }).filter(([k, v]) => allowedEndpoints.includes("*") ? true : allowedEndpoints.includes(k)).map(([key, value]) => {
            return <Menu.Item
              className={key}
              key={key}
              icon={icons[key]}
            >
              <Link to={"/" + key}>{value}</Link>
            </Menu.Item>
          })
        }
      </Menu.SubMenu>

      <Menu.SubMenu key="manager_grp" title="Tesis Yönetimi" icon={<AppstoreOutlined />} >
        {
          Object.entries({
            schedules: "Tarifeler",
            programs: "Programlar",
            courses: "Kurslar",
            subscriptions: "Abonelikler",
            appointments: "Randevular",
            calendars: "Takvim",
            notifications: "Bildirimler",
            rules: "Tesis Kuralları"
          }).filter(([k, v]) => allowedEndpoints.includes("*") ? true : allowedEndpoints.includes(k)).map(([key, value]) => {
            return <Menu.Item
              className={key}
              key={key}
              icon={icons[key]}
            >
              <Link to={"/" + key}>{value}</Link>
            </Menu.Item>
          })
        }
      </Menu.SubMenu>


      <Menu.SubMenu key="sport_grp" title="Spor Kulüpleri" icon={<AppstoreOutlined />} >
        {
          Object.entries({
            clubs: "Spor Kulüpleri",
            pool_usage_requests: "Havuz Kullanım Talebleri",
          }).filter(([k, v]) => allowedEndpoints.includes("*") ? true : allowedEndpoints.includes(k)).map(([key, value]) => {
            return <Menu.Item
              className={key}
              key={key}
              icon={icons[key]}
            >
              <Link to={"/" + key}>{value}</Link>
            </Menu.Item>
          })
        }
      </Menu.SubMenu>

      <Menu.SubMenu key="money_ops" title="Para İşlemleri" icon={<AppstoreOutlined />} >
        {
          allowedEndpoints.includes("*") ? <Menu.Item
            className={"spendings"}
            key={"spendings"}
            icon={icons["bills"]}
          >
            <Link to={"/" + "bills"}>Harcamalar</Link>
          </Menu.Item> : allowedEndpoints.includes("bills") ? <Menu.Item
            className={"spendings"}
            key={"spendings"}
            icon={icons["bills"]}
          >
            <Link to={"/" + "bills"}>Harcamalar</Link>
          </Menu.Item> : null
        }

        {<Menu.SubMenu key="credit_ops" title="Bakiye İşlemleri" icon={<AppstoreOutlined />}  >
          {
            Object.entries({
              bills_all: "Ödeme Özetleri",
              payments: "Ödeme Detayı",
            }).filter(([k, v]) => allowedEndpoints.includes("*") ? true : allowedEndpoints.includes(k)).map(([key, value]) => {
              return <Menu.Item
                className={key}
                key={key}
                icon={icons[key]}
              >
                <Link to={"/" + key}>{value}</Link>
              </Menu.Item>
            })
          }
        </Menu.SubMenu>}

        {allowedEndpoints.includes("*") ?
          <Menu.Item
            className={"refunds"}
            key={"refunds"}
            icon={icons["refunds"]}
          >
            <Link to={"/" + "refunds"}>İade Talepleri</Link>
          </Menu.Item> : allowedEndpoints.includes("refunds") ? <Menu.Item
            className={"refunds"}
            key={"refunds"}
            icon={icons["refunds"]}
          >
            <Link to={"/" + "refunds"}>İade Talepleri</Link>
          </Menu.Item> : null
        }

      </Menu.SubMenu>

      <Menu.SubMenu key="settings_grp" title="Ayarlar" icon={<AppstoreOutlined />} >
        {
          Object.entries({
            facilities: "Tesisler",
            terminal: "Tesis Bilgileri",
            devices: "Cihazlar",
            users: "Kullanıcılar",
            roles: "Roller",
            about: "Hakkında",
          }).filter(([k, v]) => k === "facilities" && ![null, undefined, 0].includes(decodedToken.facility_id) ? false : allowedEndpoints.includes("*") ? true : allowedEndpoints.includes(k)).map(([key, value]) => {

            if (key === "facilities") {
              return <Menu.Item
                className={key}
                key={key}
                icon={icons["terminal"]}
              >
                <Link to={"/" + key}>{value}</Link>
              </Menu.Item>
            }

            return <Menu.Item
              className={key}
              key={key}
              icon={icons[key]}
            >
              <Link to={"/" + key}>{value}</Link>
            </Menu.Item>
          })
        }
      </Menu.SubMenu>
    </Menu >

  );
};

export default NavBar;
