import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, message, Modal, notification } from "antd";
import React from "react";
import { API_URL } from "../config";
import ReactToPrint from "react-to-print";
import { responseControl } from "../assets/scripts";

const TicketModal = ({ visible, setVisible }) => {
  let componentRef = React.useRef();
  const [tcno, setTcno] = React.useState(null);
  const handleClick = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/buy_ticket`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tc_no: tcno, is_direct: false }),
      });
      const result = await responseControl(response);
      if (result?.hasOwnProperty("barcode")) {
        message.open({
          style: { marginTop: "10%", fontSize: 30 },
          duration: 0,
          content: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={result.barcode}
                style={{ width: 500, height: 500 }}
                ref={(el) => (componentRef = el)}
              />
              <ReactToPrint
                trigger={() => <Button type="primary">Yazdır</Button>}
                content={() => componentRef}
                onAfterPrint={() => window.location.reload()}
              />
            </div>
          ),
        });
      }
    } catch (error) {
      return notification.error({ message: error?.message || error });
    }
  };
  const handleChange = (event) => {
    setTcno(event.target.value);
  };
  return (
    <Modal
      okButtonProps={{
        form: "ticket-form",
        key: "submit",
        htmlType: "submit",
        icon: <SaveOutlined />,
        onClick: handleClick,
      }}
      cancelButtonProps={{
        form: "ticket-form",
        key: "cancel",
        danger: true,
        type: "primary",
        icon: <CloseCircleOutlined />,
      }}
      okText="Bilet Kes"
      cancelText={"İptal"}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
    >
      <Form id="ticket-form" style={{ marginTop: "5%" }}>
        <Form.Item label="TC Kimlik Numarası" name="tc_no">
          <Input name="tc_no" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TicketModal;
