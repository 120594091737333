import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { notification } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import moment from "moment";

const methods = {
    cash: "Nakit",
    kiosk: "Kiosk",
    credit_card: "Kredi kartı",
    card_selling: "Kart ücreti",
    credit_cutting: "Kesinti",
};

const GeneralBills = () => {
    const [dataSource, setDataSource] = React.useState([]);

    const [columns, setColumns] = React.useState([]);
    React.useEffect(() => {


        const initialColumns = [
            {
                title: "TC Kimlik Numarası",
                dataIndex: "tc_no",
                key: "tc_no",
                defaultSelected: true,
                responsive: ["xs", "md", "lg"],
            },
            {
                title: "Ödenen Miktar",
                dataIndex: "amount",
                key: "amount",
                defaultSelected: true,
                responsive: ["xs", "md", "lg"],
            },
            {
                title: "Tarih",
                dataIndex: "date",
                key: "date",
                defaultSelected: true,
                responsive: ["xs", "md", "lg"],

                render: (item, row, idx) => {
                    if (item === null) return null;

                    return (
                        <p>
                            {new Date(item)
                                .toLocaleDateString("tr-TR")
                                .split(".")
                                .reverse()
                                .join("-")}{" "}
                            {new Date(item)
                                .toLocaleTimeString("tr-TR")
                                .split(".")
                                .reverse()
                                .join("-")}
                        </p>
                    );
                },
            },
        ];
        setColumns(initialColumns);

    }, [])
    const [isLoading, setIsLoading] = React.useState(false);
    const [dates, setDates] = React.useState([moment("2023-01-01"), moment()]);
    const [report, setReport] = React.useState(null);
    React.useEffect(() => {
        setIsLoading(true);
        try {
            fetch(`${API_URL}/api/v1/paymentlist?start=${dates[0].format("YYYY-MM-DD")}&end=${dates[1].format("YYYY-MM-DD")}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    setDataSource(result.list);
                    setReport(result.report)
                })
                .finally(() => setIsLoading(false));
        } catch (error) {
            notification.error({
                message: error instanceof Error ? error.message : error,
            });
        }
        return () => {
            setDataSource([]);
            setReport(null);
        }
    }, [dates]);
    const handleDatePicker = (dates) => {
        setDates(dates)
    }





    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <CTable
                title="ÖDEME DETAYLARI"
                scrollX={3500}
                dataSource={dataSource}
                columns={columns}
                time_range={true}
                timeFilter={true}
                columnChoice={false}
                handleDatePicker={handleDatePicker}
                isLoading={isLoading}
                dates={dates}
                report={report}
                paymentMethods={methods}
            />
        </ErrorBoundary>
    );
};

export default GeneralBills;
