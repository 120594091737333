import React, { useCallback } from "react";
import {
  BorderlessTableOutlined,
  CheckCircleOutlined,
  ConsoleSqlOutlined,
  DeleteOutlined,
  DownOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  Button,
  Dropdown,
  Table,
  Menu,
  Checkbox,
  Divider,
  Tag,
  Popconfirm,
  notification,
  DatePicker,
} from "antd";
import SchedulesModal from "./SchedulesModal";
import MembersModal from "./MembersModal";
// import PaymentsModal from "./PaymentsModal";
import RolesModal from "./RolesModal";
import UsersModal from "./UsersModal";
import ClubsModal from "./ClubsModal";
import TrainersModal from "./TrainersModal";
import FilterDropdown from "./FilterDropdown";
import "../assets/styles/components/cTable.css";
import DevicesModal from "./DevicesModal";
import * as XLSX from "xlsx";
import UploadExcel from "./UploadExcel";
import { deleteRecord } from "../assets/scripts";
import verifyRecord from "../assets/scripts/verifyRecord";
import FacilitiesModal from "./FacilitiesModal";
import TicketModal from "./TicketModal";
import ShowTicket from "./ShowTicket";
import TicketModalWithName from "./TicketModalWithName";
import AppointmentsModal from "./AppointmentModal";
import createDirectTicket from "../assets/scripts/createDirectTicket";
import ProgramModal from "./ProgramModal";
import PoolUsageModal from "./PoolUsageModal";
import CoursesModal from "./CoursesModal";
import SubscriptionModal from "./SubscriptionModal";
import NotificationModal from "./NotificationModal";
import RuleModal from "./RuleModal";

const dateIndexes = [
  "birthdate",
  "createdAt",
  "updatedAt",
  "login",
  "logout",
  "file_timeout",
  "used_time",
];

const deleteZeroFromCardNum = (card_num) => {
  let patt = /^0*/;
  let result = patt.test(card_num);
  if (result === true) {
    var cardnum = card_num?.replace(/^0*/g, "");
    return cardnum;
  }
};

const mutes = ["payment", "card", "log", "ticket", "refunds"];

const CTable = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [record, setRecord] = React.useState({});
  const [columnsWillRender, setColumnsWillRender] = React.useState([]);
  const [filteredColumnsWillRender, setFilteredColumnsWillRender] =
    React.useState([]);
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const setSearchInput = React.useRef(null);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [openRowSelection, setOpenRowSelection] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [openTicketModal, setOpenTicketModal] = React.useState(false);
  const [openTicketModalWithName, setOpenTicketModalWithName] =
    React.useState(false);
  let componentRef = React.useRef();

  React.useEffect(() => {
    if (props.columns.length > 0) {
      let arr = props.columns;
      arr.map((source) => {
        if (source.dataIndex !== "actions") {
          source.showSorterTooltip = false;
          source.defaultSortOrder = "descend";
          source.sorter = (a, b) => {
            try {
              if (
                source.dataIndex instanceof Array &&
                source.dataIndex.length > 0
              ) {
                let sortRecordA = a[source.dataIndex[0]];
                let sortRecordB = b[source.dataIndex[0]];
                for (let index = 1; index < source.dataIndex.length; index++) {
                  if (sortRecordA) {
                    sortRecordA = sortRecordA[source.dataIndex[index]];
                  }
                  if (sortRecordB) {
                    sortRecordB = sortRecordB[source.dataIndex[index]];
                  }
                }
                if (sortRecordA && sortRecordB) {
                  if (isNaN(sortRecordA)) {
                    return sortRecordA.localeCompare(sortRecordB);
                  } else {
                    return parseInt(sortRecordA) - parseInt(sortRecordB);
                  }
                } else {
                  return;
                }
              } else if (typeof a[source.dataIndex]?.map === "function") {
                if (source?.key === "cards") {
                  if (!isNaN(a[source?.dataIndex][0]?.card_number)) {
                    return (
                      parseInt(a[source?.dataIndex][0]?.card_number) -
                      parseInt(b[source?.dataIndex][0]?.card_number || 0)
                    );
                  } else {
                    return 0;
                  }
                } else if (source?.key === "credits") {
                  if (!isNaN(a[source.dataIndex][0]?.credits)) {
                    return (
                      parseInt(a[source.dataIndex][0]?.credits) -
                      parseInt(b[source.dataIndex][0]?.credits || 0)
                    );
                  }
                } else if (source.key === "schedule") {
                  return (
                    a[source.dataIndex][0]?.schedule_name?.localeCompare(
                      b[source.dataIndex][0]?.schedule_name
                    ) ||
                    a[source.dataIndex][0]?.club_name?.localeCompare(
                      b[source.dataIndex][0]?.club_name
                    ) ||
                    a[source.dataIndex][0]?.first_name?.localeCompare(
                      b[source.dataIndex][0]?.first_name
                    )
                  );
                } else if (source.key === "club_name") {
                  return a[source.dataIndex][0]?.club_name?.localeCompare(
                    b[source.dataIndex][0]?.club_name
                  );
                } else if (source?.key === "card_number") {
                  if (!isNaN(a[source?.dataIndex][0]?.card_number)) {
                    return (
                      parseInt(a[source?.dataIndex][0]?.card_number) -
                      parseInt(b[source?.dataIndex][0]?.card_number || 0)
                    );
                  } else {
                    return 0;
                  }
                } else {
                  if (a[source.dataIndex]?.length === 0) {
                    return 0;
                  } else {
                    return a[source.dataIndex][0][source.key]?.localeCompare(
                      b[source.dataIndex][0][source.key]
                    );
                  }
                }
              } else {
                if (typeof a[source.dataIndex] === "string") {
                  return a[source.dataIndex]?.localeCompare(
                    b[source.dataIndex]
                  );
                } else if (typeof a[source.dataIndex] === "number") {
                  return a[source.dataIndex] - b[source.dataIndex];
                } else {
                  return;
                }
              }
            } catch (error) {
              //prettier-ignore
              return notification.error({ message: error?.message || error });
            }
          };
        }
      });
      setColumnsWillRender(arr);
      setSelectedColumns(
        props.columns
          .filter((column) => column.defaultSelected === true)
          .map((item) => item.key)
      );
    }
  }, [props.columns]);

  React.useEffect(() => {
    const getColumnSearchProps = (dataIndex, key) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropdown
          timeFilter={props.timeFilter}
          isDate={dateIndexes.includes(dataIndex)}
          setSearchedColumn={setSearchedColumn}
          setSearchInput={setSearchInput}
          setSearchText={setSearchText}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          dataIndex={dataIndex}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      ),

      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "user") {
          return record[dataIndex]?.full_name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        }
        if (["get", "post", "put", "delete"].includes(dataIndex)) {
          return props.endpoints[record[dataIndex]]
            ?.toLowerCase()
            .includes(value.toLowerCase());
        }

        if (dataIndex === "is_employee") {
          return (record[dataIndex] ? "evet" : "hayır").includes(
            value?.toLowerCase()
          );
        }

        if (dataIndex === "verification") {
          return (record[dataIndex] ? "var" : "yok").includes(
            value?.toLowerCase()
          );
        }

        if (dataIndex === "payment_method") {
          const payment = props.paymentMethods[record[dataIndex]];
          return payment?.toLowerCase() === value.toLowerCase();
        }

        if (dataIndex === "days") {
          return record[dataIndex].some((day) =>
            props.weekdays
              .filter((weekday) => weekday.en === day)[0]
              .tr.toLowerCase()
              .includes(value.toLowerCase())
          );
        }

        if (dataIndex === "cards" && dataIndex != null) {
          const res = deleteZeroFromCardNum(value);
          return record?.[dataIndex]?.[0]?.card_number
            ?.toString()
            ?.includes(res);
        }

        if (dateIndexes.includes(dataIndex)) {
          const firstDate = new Date(value.split(",")[0]).getTime();
          const secondDate = new Date(value.split(",")[1]).getTime();
          const recordDate = new Date(record[dataIndex]).getTime();

          if (recordDate >= firstDate && recordDate <= secondDate) {
            return record[dataIndex];
          }
        } else if (record[dataIndex]) {
          if (dataIndex.includes("cards") && props.type === "member") {
            const res = deleteZeroFromCardNum(value);

            return record?.[dataIndex]?.[0]?.card_number
              ?.toString()
              ?.includes(res);
          } else {
            if (record[dataIndex] instanceof Object) {
              if (Object.keys(record[dataIndex]).length === 0) {
                return;
              } else {
                if (record[dataIndex] instanceof Array) {
                  return record[dataIndex][0][key]
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase());
                } else if (record[dataIndex] instanceof Object) {
                  return record[dataIndex][key]
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase());
                }
              }
            }

            if (dataIndex.includes("card_number")) {
              const res = deleteZeroFromCardNum(value);
              return record?.[dataIndex]?.toString()?.includes(res.toString());
            } else {
              return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
            }
          }
        } else {
          if (dataIndex === "is_logout") {
            const data = record[dataIndex] === false ? "hayır" : "evet";
            return data.includes(value.toLowerCase());
          }
          if (dataIndex instanceof Array && dataIndex.length > 0) {
            let rowRecord = record[dataIndex[0]];
            for (let index = 1; index < dataIndex.length; index++) {
              if (rowRecord) {
                rowRecord = rowRecord[dataIndex[index]];
              }
            }

            return rowRecord
              ?.toString()
              ?.toLowerCase()
              ?.includes(value?.toLowerCase());
          }
          return "";
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible && setSearchInput?.current) {
          setTimeout(
            () =>
              setSearchInput?.current ? setSearchInput.current?.select() : null,
            100
          );
        }
      },
      render: (text) => {
        if (searchedColumn === dataIndex) {
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          );
        } else {
          return text;
        }
      },
      responsive: ["md"],
    });

    let columnsWithFilter = [];
    columnsWillRender.map((column) => {
      let additionals = getColumnSearchProps(column.dataIndex, column.key);

      if (column.hasOwnProperty("render")) {
        delete additionals.render;
        additionals.render = column.render;
      }

      if (column.hasOwnProperty("filters")) {
        delete additionals.filters;
        delete additionals.filterDropdown;
        additionals.filter = column.filter;
      }

      if (column.hasOwnProperty("onFilter")) {
        delete additionals.onFilter;
        additionals.onFilter = column.onFilter;
      }
      if (column.hasOwnProperty("responsive")) {
        columnsWithFilter.push({
          ...column,
          ...additionals,
          responsive: column.responsive,
        });
      } else {
        columnsWithFilter.push({
          ...column,
          ...additionals,
        });
      }
    });

    setFilteredColumnsWillRender(columnsWithFilter);
  }, [
    columnsWillRender,
    props.timeFilter,
    props.type,
    searchText,
    searchedColumn,
    props.paymentMethods,
    props.weekdays,
    props.endpoints,
  ]);

  const handleClick = (record) => {
    setVisible(true);
    if (record) {
      setRecord(record);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (setSelectedKeys, clearFilters, confirm) => {
    setSelectedKeys(null);
    clearFilters();
    setSearchText("");
    confirm();
  };

  const exportCustomExcel = () => {

    const fileBuffer = props.report.file;
    const buffer = new Uint8Array(atob(fileBuffer).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([buffer]);
    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = props.report.report_name;
    downloadLink.textContent = props.report.report_name;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    URL.revokeObjectURL(blobUrl);
  }

  const exportToExcel = (data = props.dataSource) => {
    if (props.report) {
      return exportCustomExcel()
    }
    const dataWillExport = [];

    const fileName = `${window.location.pathname.split("/")[1]}-${new Date()
      .toLocaleDateString("tr-TR")
      .split(".")
      .reverse()
      .join("-")}.xlsx`;

    data.map((item) => {
      let objectWillPush = {};
      Object.keys(item).map((key) => {
        if (props.type === "card") {
          if (selectedColumns.includes("card_owner")) {
            objectWillPush[
              "card_owner"
            ] = `${item.member.first_name} ${item.member.last_name}`;
          }

          if (selectedColumns.includes("national_id")) {
            objectWillPush["national_id"] = `${item.member.tc_no}`;
          }
        }

        if (props.type === "payment") {
          if (selectedColumns.includes("first_name")) {
            objectWillPush["first_name"] = `${item?.member?.first_name}`;
          }
          if (selectedColumns.includes("last_name")) {
            objectWillPush["last_name"] = `${item?.member?.last_name}`;
          }
          if (selectedColumns.includes("tckn")) {
            objectWillPush["tc_no"] = `${item?.member?.tc_no}`;
          }
          if (selectedColumns.includes("Tarife")) {
            objectWillPush["Tarife"] =
              item?.member?.schedules instanceof Array &&
                item?.member?.schedules?.length > 0
                ? `${item?.member?.schedules[0]?.schedule_name}`
                : null;
          }
          if (selectedColumns.includes("Kulüp")) {
            objectWillPush["Kulüp"] =
              item?.member?.clubs instanceof Array &&
                item?.member?.clubs?.length > 0
                ? `${item?.member?.clubs[0]?.club_name}`
                : null;
          }
          if (selectedColumns.includes("card_number")) {
            objectWillPush["card_number"] = `${item?.card?.card_number}`;
          }
          if (selectedColumns.includes("Ödemeyi Kaydeden")) {
            objectWillPush["Ödemeyi Kaydeden"] = `${item?.user?.full_name || item?.user?.username
              }`;
          }
        }
        if (selectedColumns.includes(key)) {
          if (key === "cards") {
            objectWillPush[key] = item[key][0].card_number;
          } else {
            objectWillPush[key] = item[key];
          }
        }
      });
      dataWillExport.push(objectWillPush);
    });

    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(dataWillExport);
    var range = XLSX.utils.decode_range(ws["!ref"]);

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!ws[address]) continue;
      props.columns.map((column) => {
        if (ws[address].v === column.dataIndex || ws[address].v === "id") {
          if (ws[address].v === "id") {
            ws[address].v = "ID";
          } else {
            ws[address].v = column.title;
          }
        }
      });
    }
    /* add to workbook */
    var wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, fileName);
  };

  const handleSelectedColumns = (columnKey) => {
    setSelectedColumns(columnKey);
  };

  const getExcelTemplate = () => {
    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet([
      {
        first_name: "Üye adını buraya giriniz",
        last_name: "Üye soyadını buraya giriniz",
        tc_no: "Üye TC Kimlik numarasını buraya giriniz",
        card_number: "Üye kart numarasını buraya giriniz",
        credits: "Üyenin kartındaki bakiyeyi giriniz",
        registered:
          "Üye otomatik olarak onaylanacaksa true yazınız, onaylanmayacaksa false yazınız",
        schedule:
          "Üyenin tarife adını buraya giriniz. (Dikkat: Büyük harf/küçük harf duyarılıdır!)  ",
        club: "Üye bir spor klübüne üye ise kulüp adını buraya giriniz. (Dikkat: Büyük harf/küçük harf duyarılıdır!)",
        trainer:
          "Üye bir antrenöre sahip ise antrenör adını ve soyadını buraya giriniz. (Dikkat: Büyük harf/küçük harf duyarılıdır!)",
        birthplace: "Üyenin doğum yerini buraya giriniz",
        birthdate: "Üyenin doğum tarihini buraya giriniz",
        father_name: "Üyenin baba adını buraya giriniz",
        mother_name: "Üyenin ana adını buraya giriniz",
        gender: "Üyenin cisiyetini buraya giriniz",
        profession: "Üyenin mesleğini buraya giriniz",
        address: "Üyenin adresini buraya giriniz",
        phone_number: "Üyenin telefon numrasını buraya giriniz",
        hes_code: "Üyenin hes kodunu buraya giriniz",
        blood_type: "Üyenin kan grubunu buraya giriniz",
      },
    ]);

    /* add to workbook */
    var wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    XLSX.writeFile(wb, new Date().toISOString() + "_pdks_havuz.xlsx");
  };

  const handleApplyFilter = () => {
    const selectedColumnsWillRender = [];
    filteredColumnsWillRender?.map((column) => {
      if (selectedColumns.includes(column?.key)) {
        column.defaultSelected = true;
        selectedColumnsWillRender.push({ ...column, defaultSelected: true });
      } else {
        selectedColumnsWillRender.push({ ...column, defaultSelected: false });
      }
    });
    setFilteredColumnsWillRender(selectedColumnsWillRender);
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    return;
  };

  const handleBulkDelete = () =>
    deleteRecord(`id=${selectedRowKeys}&bulk=true`, props?.type + "s");

  const handleBulkVerify = () => verifyRecord(selectedRowKeys);
  return (
    <div
      style={{
        display: "block",
      }}
    >
      <h2 style={{ fontWeight: "bold", marginLeft: "1em", marginTop: "1em" }}>{props.title}</h2>
      {
        props.time_range === true ?
          <div style={{ marginLeft: "2em", marginTop: "2em" }}>
            <p>Ödemelerin zaman aralığını seciniz</p>
            <DatePicker.RangePicker value={props.dates} onChange={props.handleDatePicker} />
          </div>
          : null
      }
      {props.columnChoice === false ? null : (
        <Dropdown
          className="columns-dropdown"
          trigger="click"
          overlay={
            <Menu>
              <Menu
                className="column-filters"
                style={{ overflowY: "scroll", maxHeight: 300 }}
              >
                <Checkbox.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 10,
                  }}
                  defaultValue={props.columns
                    .filter((column) => column?.defaultSelected === true)
                    .map((field) => field.key)}
                  onChange={handleSelectedColumns}
                >
                  {props.columns.map((column, idx) => (
                    <Checkbox
                      style={{ marginLeft: 0 }}
                      value={column.key}
                      className={
                        typeof column?.key === "string" ? column?.key : ""
                      }
                      id={typeof column?.key === "string" ? column?.key : ""}
                    >
                      {column.title}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Menu>
              <Menu style={{ paddingLeft: 10, marginTop: 5 }}>
                <Button
                  className="filter-btn"
                  id="filter-btn"
                  type="primary"
                  htmlType="button"
                  onClick={handleApplyFilter}
                >
                  Filtrele
                </Button>
              </Menu>
            </Menu>
          }
        >
          <Button
            type="primary"
            size="large"
            className="filters"
            style={{
              marginTop: 16,
              marginLeft: 20,
            }}
          >
            Sütunlar <DownOutlined />
          </Button>
        </Dropdown>
      )}
      <div className="exit-button" style={{ marginTop: 0, marginBottom: 50 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {props?.type === undefined ||
            props.type === null ||
            props.type === "reports" ||
            props?.type === "health_checks" ||
            mutes.includes(props.type) ? null : props.newRecords ===
              false ? null : (
            <Button
              id="new-record-btn"
              className="new-record-btn"
              onClick={() => handleClick({})}
              type="primary"
              size="large"
              icon={<PlusCircleOutlined />}
              style={{
                marginTop: 16,
              }}
            >
              Yeni Kayıt
            </Button>
          )}
          {props.type === "ticket" ? (
            <>
              <Button
                style={{
                  marginTop: 16,
                  marginLeft: 20,
                  backgroundColor: "#faad14",
                  borderColor: "#faad14",
                }}
                type="primary"
                size="large"
                onClick={() => createDirectTicket(componentRef)}
              >
                Bilet Bas
              </Button>
              <Button
                style={{
                  marginTop: 16,
                  marginLeft: 20,
                }}
                type="primary"
                size="large"
                onClick={() => setOpenTicketModal(true)}
              >
                TC İle Bilet Bas
              </Button>
              <Button
                style={{
                  marginTop: 16,
                  marginLeft: 20,
                }}
                type="primary"
                size="large"
                onClick={() => setOpenTicketModalWithName(true)}
              >
                Ad Soyad İle Bilet Bas
              </Button>
            </>
          ) : null}
          {props.excel === false ? null : (
            <Button
              onClick={() => exportToExcel()}
              icon={<FileExcelOutlined />}
              type="primary"
              size="large"
              style={{
                backgroundColor: "#52c41a",
                borderColor: "#52c41a",
                marginTop: 16,
                marginLeft: 20,
              }}
              className="export-excel-btn"
            >
              Excel'e Çıkar
            </Button>
          )}

          {props.uploadExcel === true ? (
            <>
              <Button
                className="excel-template-btn"
                type="primary"
                onClick={getExcelTemplate}
                size="large"
                icon={<BorderlessTableOutlined />}
                style={{
                  marginTop: 16,
                  marginLeft: 20,
                  backgroundColor: "#f759ab",
                  border: "#f759ab",
                }}
              >
                Excel Şablonu
              </Button>
              <UploadExcel />
            </>
          ) : null}
          {/* {props.bulkSelect === false ? null : (
            <Button
              className="bulk-selection-btn"
              style={{
                marginTop: 16,
                marginLeft: 20,
                backgroundColor: "#08979c",
                border: "#08979c",
              }}
              type="primary"
              size="large"
              onClick={() => setOpenRowSelection(!openRowSelection)}
              icon={<CheckCircleOutlined />}
            >
              Toplu Seçimi {openRowSelection ? "Kapat" : "Aç"}
            </Button>
          )}
          {openRowSelection && selectedRowKeys.length > 0 ? (
            <>
              <Popconfirm
                title="Seçili kayıtları silmek istediğinize emin misiniz?"
                onConfirm={handleBulkDelete}
                onCancel={handleCancel}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button
                  style={{
                    marginTop: 16,
                    marginLeft: 20,
                  }}
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  size="large"
                >
                  Seçili verileri sil
                </Button>
              </Popconfirm>
              {props.type === "verification" ? (
                <Popconfirm
                  title="Seçili kayıtları onaylamak istediğinize emin misiniz?"
                  onConfirm={handleBulkVerify}
                  onCancel={handleCancel}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button
                    style={{
                      marginTop: 16,
                      marginLeft: 20,
                    }}
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    size="large"
                  >
                    Seçili kayıtları onayla
                  </Button>
                </Popconfirm>
              ) : null}
            </>
          ) : null} */}
        </div>
      </div>
      {props.type === "ticket" ? (
        <>
          <TicketModal
            setVisible={setOpenTicketModal}
            visible={openTicketModal}
          />
          <TicketModalWithName
            setVisible={setOpenTicketModalWithName}
            visible={openTicketModalWithName}
          />
        </>
      ) : null}
      <Table
        expandable={
          props.type === "club"
            ? {
              expandedRowRender: (record) => {
                console.log("record.trainers ", record.trainers);
                return (
                  <Table
                    style={{ width: "1500px", margin: "auto" }}
                    title={() => <h2>Antrenörler</h2>}
                    columns={[
                      { title: "Ad", dataIndex: "trainer", key: "trainer" },
                      {
                        title: "Telefon numarası",
                        dataIndex: "trainer_phone",
                        key: "trainer_phone",
                      },
                      {
                        title: "e-Mail",
                        dataIndex: "trainer_email",
                        key: "trainer_email",
                      },
                    ]}
                    dataSource={record?.trainers?.map((t) => JSON.parse(t))}
                  />
                );
              },
              rowExpandable: (record) =>
                record.trainers?.length !== "Not Expandable",
            }
            : null
        }
        className={`table-main ${props.type}-table`}
        rowKey={props?.rowKey ? props?.rowKey : "id"}
        rowSelection={
          openRowSelection
            ? {
              onChange: (selectedRowKeys, selectedRows) =>
                setSelectedRowKeys(selectedRowKeys),
            }
            : null
        }
        scroll={{
          x: props?.scrollX
            ? filteredColumnsWillRender.filter(
              (column) => column.defaultSelected === true
            ).length *
              100 <
              1000
              ? filteredColumnsWillRender.filter(
                (column) => column.defaultSelected === true
              ).length * 100
              : props?.scrollX
            : null,
        }}
        loading={props.isLoading}
        dataSource={props?.dataSource?.length === 0 ? [] : props?.dataSource}
        columns={
          filteredColumnsWillRender.length === 0
            ? []
            : filteredColumnsWillRender.filter(
              (column) => column.defaultSelected === true
            )
        }
        bordered
        onRow={(record, idx) => {
          return {
            onClick: (event) => {
              const propagations = ["Dosya Seç", "Kaydı Sil", "Dosyayı Yükle"];

              if (!propagations.includes(event.target.innerText)) {
                if (record?.clubs?.length > 0) {
                  record.membership = "club";
                } else if (record?.trainers?.length > 0) {
                  record.membership = "trainer";
                } else {
                  record.membership = null;
                }

                return !["schedule", "appointment"].includes(props.type) && handleClick(record);
              }
            },
          };
        }}
        summary={(data, row) => {
          return (
            <div className="table-summary">
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    {/* {props.excel === false ? null : (
                    <Button
                      type="primary"
                      icon={<FileExcelOutlined />}
                      onClick={() => exportToExcel(data)}
                    >
                      Seçili verileri Excel'e Çıkar
                    </Button>
                  )} */}
                    <Divider type="vertical" />
                    <Tag color="success" style={{ fontSize: 18, padding: 3 }}>
                      Toplam veri: {props?.dataSource?.length}
                    </Tag>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            </div>
          )
        }}
      />
      {
        props.type === "subscription" ? (
          <SubscriptionModal
            visible={visible}
            type={props.type}
            record={record}
            setVisible={setVisible}
          />
        ) :
          props.type === "rule" ? (
            <RuleModal
              visible={visible}
              type={props.type}
              record={record}
              setVisible={setVisible}
            />
          )
            :
            props.type === "member" ? (
              <MembersModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "user" ? (
              <UsersModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "schedule" ? (
              <SchedulesModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "role" ? (
              <RolesModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "club" ? (
              <ClubsModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "notification" ? (
              <NotificationModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "course" ? (
              <CoursesModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "trainer" ? (
              <TrainersModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "device" ? (
              <DevicesModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "facility" ? (
              <FacilitiesModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "ticket" ? (
              <ShowTicket
                setVisible={setVisible}
                visible={visible}
                record={record}
                type={props.type}
              />
            ) : props.type === "appointment" ? (
              <AppointmentsModal
                setVisible={setVisible}
                visible={visible}
                type={props.type}
              />
            ) : props.type === "program" ? (
              <ProgramModal
                setVisible={setVisible}
                visible={visible}
                record={record}
                setRecord={setRecord}
              />
            ) : props.type === "poolUsage" ? (
              <PoolUsageModal
                setVisible={setVisible}
                visible={visible}
                record={record}
              />
            ) : null}
    </div>
  );
};

export default CTable;
