import React from 'react';
import { Button, Modal, Table } from 'antd';
import {CloseCircleOutlined} from "@ant-design/icons";

export default function SessionsDisplay(props) {
    const columns = [{
        title: "Gün",
        dataIndex: "day",
        key: "day", 
        responsive: ["xs", "md", "lg"],
      },{
        title: "Başlangıç Saat",
        dataIndex: "start_time",
        key: "start_time", 
        responsive: ["xs", "md", "lg"],
      },{
        title: "Bitiş Saat",
        dataIndex: "finish_time",
        key: "finish_time", 
        responsive: ["xs", "md", "lg"],
      },
  ];
  const weekdays = { 
        "Monday": "Pazartesi",
        "Tuesday": "Salı",
        "Wednesday": "Çarşamba",
        "Thursday": "Perşembe",
        "Friday": "Cuma",
        "Saturday": "Cumartesi",
        "Sunday": "Pazar",
        };

  return (
    <Modal
    width={"600px"}
    visible={props.openSessions.open} 
    onCancel={() => props.setOpenSessions(false)} 
    footer={[
        
        <Button
          type="primary" 
          icon={<CloseCircleOutlined />}
          onClick={() =>  props.setOpenSessions(false)} 
        >
          Kapat
        </Button>,
      ]}
  >

<h2 style={{margin:"1.3em"}}>SEANSLAR</h2>
    
    <Table
        style={{margin:"2em"}}
        columns={columns}
        dataSource={props.openSessions?.data?.map(s=>({...s, day: weekdays[s.day]}))}
    />
    </Modal>
  )
}
