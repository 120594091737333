import { API_URL } from "../config";
import React from "react";
import { Button, Calendar, message } from "antd";
import CalendarModal from "../components/CalendarModal";
import { responseControl } from "../assets/scripts/index";
import CalendarCell from "../components/CalendarCell";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import ProgramModal from "../components/ProgramModal";

const local = {
  lang: {
    locale: "tr_TR",
    placeholder: "Tarih seçimi",
    rangePlaceholder: ["Başlangıç tarihi", "Bitiş tarihi"],
    today: "Bugün",
    now: "Şu an",
    backToToday: "Önceki gün",
    ok: "Tamam",
    clear: "Temizle",
    month: "Ay",
    year: "Yıl",
    timeSelect: "Zaman seçimi",
    dateSelect: "Tarih seçimi",
    monthSelect: "Ay seçimi",
    yearSelect: "Yıl seçimi",
    decadeSelect: "On yıl seçimi",
    yearFormat: "YYYY",
    dateFormat: "YYYY-MM-DD",
    dayFormat: "D",
    dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Önceki ay (Önceki sayfa)",
    nextMonth: "Sonraki ay (Sonraski sayfa)",
    previousYear: "Geçen yıl (Control + left)",
    nextYear: "Gelecek yıl (Control + right)",
    previousDecade: "Geçen onyıl",
    nextDecade: "Gelecek onyıl",
    previousCentury: "Geçen yüzyıl",
    nextCentury: "Gelecek yüzyıl",
  },
  timePickerLocale: {
    placeholder: "Zaman seçimi",
  },

  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM",
};

const PoolCalendar = () => {
  const [visible, setVisible] = React.useState(false);
  const [record, setRecord] = React.useState({});
  const [event_date, setEventDate] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [showProgramModal, setShowProgramModal] = React.useState(false);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/calendars?include=club,trainer,schedule`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => responseControl(response))
      .then((result) => {
        setEvents(result);
      })
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
    fetch(`${API_URL}/api/v1/programs`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => responseControl(response))
      .then((result) => {
        setPrograms(result);
      })
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
  }, []);

  React.useEffect(() => {
    if (event_date !== null) {
    }
  }, [event_date]);

  const handleListData = (value) => {
    const formatted = value.format("YYYY-MM-DD").toString();
    if (typeof events?.map === "function") {
      return events.map((event) => {
        const eventFormattedDate = new Date(event.event_date)
          .toLocaleDateString("tr-TR")
          .split(".")
          .reverse()
          .join("-")
          .toString();

        if (eventFormattedDate === formatted) {
          return event || [];
        }
      });
    }
  };

  const dateCellRender = (value) => {
    const events = handleListData(value);
    const day = value.locale("en").format("dddd");
    const date = value.format("YYYY-MM-DD");
    return (
      <div>
        {programs instanceof Array && programs.length > 0
          ? programs.map((program) => {
            if (
              program?.days instanceof Array &&
              program?.days.includes(day) &&
              new Date(program?.startline) <= new Date(date) &&
              new Date(program?.deadline) > new Date(date)
            ) {
              return <CalendarCell data={program} type="Program" />;
            }
          })
          : null}
        {events instanceof Array
          ? events.map((data) => {
            if (data === undefined) {
              return null;
            }
            return <CalendarCell data={data} type="Etkinlik" />;
          })
          : null}
      </div>
    );
  };

  const handleEventDate = (value, type) => {
    setEventDate(value.format("YYYY-MM-DD"));
    fetch(
      `${API_URL}/api/v1/calendars?event_date=${new Date(
        value.format("YYYY-MM-DD")
      ).toISOString()}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => responseControl(response))
      .then((result) => {
        if (result?.length > 0) {
          const { filter, ...rest } = result[0];
          if (result[0]) {
            let recordWillSet = {};
            let innerDateTypes = ["birthdate"];
            let rangerDateTypes = ["birthdate"];
            // prettier-ignore
            const filtersObject = !filter ? {} : JSON.parse(filter || {});

            let innerFilterField = Object.keys(filtersObject).map((key) => {
              if (key === "in") {
                return {
                  [key]: filtersObject[key].map((object) => {
                    if (innerDateTypes.includes(Object.keys(object)[0])) {
                      var filterDate = object[Object.keys(object)[0]];
                      return {
                        [Object.keys(object)[0]]: [
                          moment(new Date(filterDate)),
                        ],
                      };
                    } else {
                      return object;
                    }
                  }),
                };
              }
            });

            let rangerFilterField = Object.keys(filtersObject).map((key) => {
              if (key === "between") {
                return {
                  [key]: filtersObject[key].map((object) => {
                    if (rangerDateTypes.includes(Object.keys(object)[0])) {
                      var filterDates = object[Object.keys(object)[0]];

                      return {
                        [Object.keys(object)[0]]: [
                          moment(new Date(filterDates[0])),
                          moment(new Date(filterDates[1])),
                        ],
                      };
                    } else {
                      return object;
                    }
                  }),
                };
              }
            });

            recordWillSet = {
              ...rest,
              filter: {
                ...innerFilterField[0],
                ...rangerFilterField.filter((item) => item)[0],
              },
            };
            setRecord(recordWillSet);
          }
        } else {
          setRecord(result);
        }
      })
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
    setVisible(true);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <h2 style={{ fontWeight: "bold", margin: "1em" }}>TAKVİM</h2>
      <Button
        type="primary"
        style={{ marginLeft: "1%" }}
        onClick={() => setShowProgramModal(true)}
      >
        Program Ekle
      </Button>
      <Calendar
        fullscreen
        locale={{ ...local }}
        style={{ marginTop: "1%" }}
        dateCellRender={dateCellRender}
        onSelect={handleEventDate}
      />

      <CalendarModal
        setVisible={setVisible}
        visible={visible}
        record={record}
        type="calendar"
      />
      <ProgramModal
        setVisible={setShowProgramModal}
        visible={showProgramModal}
        type="calendar"
      />
    </ErrorBoundary>
  );
};

export default PoolCalendar;
