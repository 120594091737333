import { Button, Alert } from "antd";
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Alert
      style={{ margin: "1%" }}
      type="error"
      message={
        <>
          <h1>Bir şeyler ters gitti :/</h1>
          <pre>{error.message}</pre>
          <Button type="primary" onClick={() => window.location.reload()}>
            Yeniden Dene
          </Button>
        </>
      }
    />
  );
}
export default ErrorFallback;
