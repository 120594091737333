import { Button, message, notification } from "antd";
import ReactToPrint from "react-to-print";
import { API_URL } from "../../config";
import responseControl from "./responseControl";

const createDirectTicket = async (componentRef) => {
  try {
    const response = await fetch(`${API_URL}/api/v1/buy_ticket`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tc_no: new Date().getTime().toString(),
        is_direct: true,
      }),
    });
    const result = await responseControl(response);
    if (result?.hasOwnProperty("barcode")) {
      message.open({
        duration: 0,
        content: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={result.barcode}
              style={{ width: 500, height: 500 }}
              ref={(el) => (componentRef = el)}
            />
            <ReactToPrint
              trigger={() => <Button type="primary">Yazdır</Button>}
              content={() => componentRef}
              onAfterPrint={() => window.location.reload()}
            />
          </div>
        ),
      });
    }
  } catch (error) {
    return notification.error({ message: error?.message || error });
  }
};
export default createDirectTicket;
