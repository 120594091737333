import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Alert, Button, Modal, notification } from "antd";
import React from "react";
import ReactToPrint from "react-to-print";
import { responseControl } from "../assets/scripts";
import { API_URL } from "../config";

const ShowTicket = (props) => {
  let componentRef = React.useRef();
  const [barcode, setBarcode] = React.useState(null);

  React.useEffect(() => {
    setBarcode(null);
    if (props.visible) {
      getBarcode();
    }
  }, [props]);

  const getBarcode = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/query-ticket`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tc_no: props.record.tc_no,
          createdAt: props.record.createdAt,
        }),
      });
      const result = await responseControl(response, false);
      if (result?.hasOwnProperty("barcode")) {
        return setBarcode(result.barcode);
      }
    } catch (error) {
      return notification.error({ message: error?.message || error });
    }
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      title={<h3>{props.record.tc_no} Bileti</h3>}
      footer={null}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
    >
      {barcode ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={barcode}
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
              marginTop: "5%",
              marginBottom: "3%",
            }}
            ref={(el) => (componentRef = el)}
          />
          <ReactToPrint
            trigger={() => (
              <Button type="primary" className="print-ticket">
                Yazdır
              </Button>
            )}
            content={() => componentRef}
            onAfterPrint={() => window.location.reload()}
          />
        </div>
      ) : (
        <Alert
          style={{ marginTop: "5%", marginBottom: "3%" }}
          type="error"
          message="Kullanılmış Bilet"
          description={
            <div>
              {" "}
              Bu bilet{" "}
              {new Date(props.record.used_time).toLocaleDateString(
                "tr-TR"
              )}{" "}
              {new Date(props.record.used_time).toLocaleTimeString("tr-TR")}{" "}
              tarihinde kullanılmış.{" "}
            </div>
          }
        />
      )}
    </Modal>
  );
};

export default ShowTicket;
