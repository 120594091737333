import { API_URL } from "../config";
import React from "react";
import PieChart from "../components/dashboard/PieChart";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Row,
  Typography,
} from "antd";
import ColumnChart from "../components/dashboard/ColumnChart";
import LineChart from "../components/dashboard/LineChart";
import { responseControl } from "../assets/scripts";
import "../assets/styles/components/dashboard.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import NewMembersChart from "../components/dashboard/NewMembersChart";
import GenderActivities from "../components/dashboard/GenderActivities";
import moment from "moment";
import DailyActivities from "../components/dashboard/DailyActivities";
import DailyPayments from "../components/dashboard/DailyPayments";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FilePdfOutlined } from "@ant-design/icons/lib/icons";
import react from "react";

const horizontalStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 10,
  boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
  height: 500,
  width: "100%",
  paddingTop: 0,
  marginLeft: "1%",
  marginRight: 3,
};
const verticalStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 10,
  boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
  height: 810,
  paddingTop: "85%",
};
const MonthlyPayment = (props) => (
  <Badge.Ribbon
    style={{
      fontSize: 25,
      height: 50,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      display: "flex",
      marginLeft: props.direction === "horizontal" ? "1%" : 0,
    }}
    text={`Aylık Ciro (${new Date().getFullYear()}/TL)`}
    placement="start"
    color="#389e0d"
  >
    <Card
      className="monthly-payments"
      style={props.direction === "vertical" ? verticalStyle : horizontalStyle}
      bordered
    >
      <ColumnChart allowedEndpoints={props.allowedEndpoints} />
    </Card>
  </Badge.Ribbon>
);

const Dashboard = (props) => {
  const [logs, setLogs] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const [currentVisitor, setCurrentVisitor] = React.useState(0);
  const [totalVisitor, setTotalVisitor] = React.useState(0);
  const [currentTill, setCurrentTill] = React.useState(0);
  const [totalTill, setTotalTill] = React.useState(0);
  const [showCharts, setShowCharts] = React.useState(true);
  const [currentSystem, setCurrentSystem] = React.useState({});
  //prettier-ignore
  const [date, setDate] = React.useState(moment().format("YYYY-MM"));
  const [ratios, setRatios] = React.useState(0);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((logs) => setLogs(logs))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
              ? message.error(error)
              : console.log(error)
        );
    }
    if (
      props?.allowedEndpoints?.includes("payments") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/payments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((payments) => setPayments(payments))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
              ? message.error(error)
              : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let current = 0;
    let total = 0;
    if (logs?.length > 0) {
      setShowCharts(true);
      logs.map((log) => {
        const currentYear = new Date().getFullYear();

        const logYear = new Date(log.login).getFullYear();

        if (currentYear === logYear) {
          total++;
          if (log.logout === null) {
            current++;
          }
        }
      });
    } else {
      setShowCharts(false);
    }


    setTotalVisitor(total);
    setCurrentVisitor(current);
  }, [logs]);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/system_datas`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {
        setCurrentSystem(result[0].capacity);
      })
      .catch((error) => console.log(error));

    let ratioSt = ((currentVisitor / currentSystem) * 100);
    let ratio = isNaN(ratioSt) ? "--" : (Number(ratioSt).toFixed(0));
    setRatios(ratio);
  }, [currentSystem])
  React.useEffect(() => {
    let current = 0;
    let total = 0;
    if (payments?.length > 0) {
      payments.map((payment) => {
        total = parseInt(total) + parseInt(payment.amount);
        if (payment.paid === false) {
          current = parseInt(current) + parseInt(payment.amount);
        }
      });
    }
    setTotalTill(total);
    setCurrentTill(current);
  }, [payments]);

  function printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      let imgWidth = 208;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.output("dataurlnewwindow");
      pdf.save("download.pdf");
    });
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="dashboard-main">
        <Row
          className="dashboard-cards-row"
          style={{
            marginTop: "1%",
            marginBottom: 0,
            paddingLeft: "1%",
            paddingRight: "1%",
          }}
          gutter={24}
        >
          <Col md={showCharts ? 18 : 24}>
            <div className="dashboard-first-section">
              <Row gutter={24} style={{ display: "flex", justifyContent: "center" }} className="dashboard-head-wrapper">
                <Col md={4} xs={24} className="dashboard-head-cols">
                  <Badge.Ribbon
                    style={{
                      fontSize: 20,
                      height: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                    }}
                    text="Toplam Ciro"
                    color="green"
                    placement="start"
                  >
                    <Card
                      className="total-till"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        height: 200,
                        borderRadius: 10,
                        boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      }}
                      bordered
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Typography.Title level={1}>
                          {totalTill} TL
                        </Typography.Title>
                      </Typography>
                    </Card>
                  </Badge.Ribbon>
                </Col>
                <Col md={4} xs={24} className="dashboard-head-cols">
                  <Badge.Ribbon
                    style={{
                      fontSize: 20,
                      height: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                    }}
                    text="Toplam Ziyaret"
                    color="cyan"
                    placement="start"
                  >
                    <Card
                      className="total-visitor"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        height: 200,
                        borderRadius: 10,
                        boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      }}
                      bordered
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Typography.Title level={1}>
                          {totalVisitor}
                        </Typography.Title>
                      </Typography>
                    </Card>
                  </Badge.Ribbon>
                </Col>
                <Col md={4} xs={24} className="dashboard-head-cols">
                  <Badge.Ribbon
                    style={{
                      fontSize: 20,
                      height: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                    }}
                    text="Tesis Kasa"
                    color="gold"
                    placement="start"
                  >
                    <Card
                      className="current-till"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        height: 200,
                        borderRadius: 10,
                        boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                        // padding: 50,
                      }}
                      bordered
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Typography.Title level={1}>
                          {currentTill} TL
                        </Typography.Title>
                      </Typography>
                    </Card>
                  </Badge.Ribbon>
                </Col>

                <Col md={4} xs={24} className="dashboard-head-cols">
                  <Badge.Ribbon
                    style={{
                      fontSize: 20,
                      height: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                    }}
                    text="Mevcut Ziyaretçi"
                    color="magenta"
                    placement="start"
                  >
                    <Card
                      className="current-visitor"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        height: 200,
                        borderRadius: 10,
                        boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      }}
                      bordered
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Typography.Title level={1}>
                          {currentVisitor}
                        </Typography.Title>
                      </Typography>
                    </Card>
                  </Badge.Ribbon>
                </Col>
                <Col md={4} xs={24} className="dashboard-head-cols">
                  <Badge.Ribbon
                    style={{
                      fontSize: 20,
                      height: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                    }}
                    text="Tesis Doluluk Oranı"
                    color="red"
                    placement="start"
                  >
                    <Card
                      className="solidity-ratio"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                        height: 200,
                        borderRadius: 10,
                        boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      }}
                      bordered
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Typography.Title level={1}>
                          % {ratios}
                        </Typography.Title>
                      </Typography>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              </Row>
              {showCharts === true ? (
                <Row gutter={24}>
                  <Col md={12} xs={24} className="dashboard-middle-cols">
                    <Badge.Ribbon
                      style={{
                        fontSize: 22,
                        height: 50,
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                      }}
                      text={`Ziyaretçi Saatleri (${new Date().getFullYear()})`}
                      placement="start"
                      color="volcano"
                    >
                      <Card
                        className="chart-card hourly-visitors"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          borderRadius: 10,
                          boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                          height: 600,
                        }}
                        bordered
                      >
                        <PieChart allowedEndpoints={props.allowedEndpoints} />
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                  <Col md={12} xs={24}>
                    <Badge.Ribbon
                      style={{
                        fontSize: 22,
                        height: 50,
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                      }}
                      text={`Aylık Ziyaretçi Sayısı (${new Date().getFullYear()})`}
                      placement="start"
                      color="#003a8c"
                    >
                      <Card
                        className="chart-card monthly-visitors"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          borderRadius: 10,
                          boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                          height: 600,
                        }}
                        bordered
                      >
                        <LineChart allowedEndpoints={props.allowedEndpoints} />
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
          {showCharts === true ? (
            <Col md={6} xs={24} className="dashboard-footer-cols">
              {
                <MonthlyPayment
                  direction="vertical"
                  allowedEndpoints={props.allowedEndpoints}
                />
              }
            </Col>
          ) : null}
        </Row>
        {showCharts === false ? (
          <MonthlyPayment
            direction="horizontal"
            allowedEndpoints={props.allowedEndpoints}
          />
        ) : null}
        {/**
         * Tarih bazlı raporlama
         */}
        <Row className="dashboard-time-based-main" gutter={24}>
          <Row style={{ width: "100%" }}>
            <Card
              className="date-selection"
              style={{
                borderRadius: 10,
                boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                width: "100%",
                margin: "0 1%",
              }}
              bordered
              title="Tarih Seçimi"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <DatePicker
                  picker="month"
                  onChange={(dateObject, dateIndex) =>
                    setDate(dateObject.format("YYYY-MM"))
                  }
                />
                <Button
                  className="pdf-export-btn"
                  type="primary"
                  icon={<FilePdfOutlined />}
                  onClick={printDocument}
                >
                  PDF Yazdır
                </Button>
              </div>
            </Card>
          </Row>
          <div id="divToPrint" style={{ width: "100%", height: "100%" }}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col md={12} style={{ padding: "1%" }}>
                <Badge.Ribbon
                  style={{
                    fontSize: 22,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                  }}
                  //prettier-ignore
                  text={`Yeni Üye Sayısı (${new Date(date).toLocaleString("tr", { month: "long" })} ${new Date(date).getFullYear()})`}
                  placement="start"
                  color="green"
                >
                  <Card
                    className="new-users"
                    style={{
                      overflow: "auto",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                      borderRadius: 10,
                      boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      height: 600,
                      width: "100%",
                    }}
                    bordered
                  >
                    <NewMembersChart
                      allowedEndpoints={props.allowedEndpoints}
                      date={date}
                    />
                  </Card>
                </Badge.Ribbon>
              </Col>
              <Col md={12} style={{ padding: "1%" }}>
                <Badge.Ribbon
                  style={{
                    fontSize: 22,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                  }}
                  //prettier-ignore
                  text={`Cinsiyet Bazlı Üye Giriş Sayısı (${new Date(date).toLocaleString("tr", { month: "long" })} ${new Date(date).getFullYear()})`}
                  placement="start"
                  color="gold"
                >
                  <Card
                    className="gender-activities"
                    style={{
                      overflow: "auto",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                      borderRadius: 10,
                      boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      height: 600,
                      width: "100%",
                    }}
                    bordered
                  >
                    <GenderActivities
                      allowedEndpoints={props.allowedEndpoints}
                      date={date}
                    />
                  </Card>
                </Badge.Ribbon>
              </Col>
            </Row>

            <Row style={{ width: "100%", margin: "1%" }}>
              <Col md={24}>
                <Badge.Ribbon
                  style={{
                    fontSize: 22,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                  }}
                  //prettier-ignore
                  text={`Günlük Üye Giriş Sayısı (${new Date(date).toLocaleString("tr", { month: "long" })} ${new Date(date).getFullYear()})`}
                  placement="start"
                  color="orange"
                >
                  <Card
                    className="time-based-card daily-activities"
                    style={{
                      overflow: "auto",
                      alignItems: "center",
                      borderRadius: 10,
                      boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      height: 600,
                      width: "100%",
                      paddingTop: "5%",
                    }}
                    bordered
                  >
                    <div style={{ width: 1000 }}>
                      <DailyActivities
                        allowedEndpoints={props.allowedEndpoints}
                        date={date}
                      />
                    </div>
                  </Card>
                </Badge.Ribbon>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "0 1%" }}>
              <Col md={24}>
                <Badge.Ribbon
                  style={{
                    fontSize: 22,
                    height: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                  }}
                  //prettier-ignore
                  text={`Günlük Ödeme (${new Date(date).toLocaleString("tr", { month: "long" })} ${new Date(date).getFullYear()})`}
                  placement="start"
                  color="orange"
                >
                  <Card
                    className="time-based-card daily-payments"
                    style={{
                      overflow: "auto",
                      alignItems: "center",
                      borderRadius: 10,
                      boxShadow: "0 0 11px rgba(0, 0, 0, 0.2)",
                      height: 600,
                      width: "100%",
                      paddingTop: "5%",
                    }}
                    bordered
                  >
                    <div style={{ width: 1000 }}>
                      <DailyPayments
                        allowedEndpoints={props.allowedEndpoints}
                        date={date}
                      />
                    </div>
                  </Card>
                </Badge.Ribbon>
              </Col>
            </Row>
          </div>
        </Row>
        {showCharts === false ? (
          <MonthlyPayment
            direction="horizontal"
            allowedEndpoints={props.allowedEndpoints}
          />
        ) : null}
      </div>
    </ErrorBoundary>
  );
};
export default Dashboard;
