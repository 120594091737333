import { API_URL } from "../config";
import React from "react";
import { Form, Input, message, Modal, notification, Select } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16, offset: 2 },
};
const UsersModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [facilities, setFacilities] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }
    getRoles()
      .then((result) => setRoles(result))
      .catch((error) => console.log(error));
    getFacilities()
      .then((result) => setFacilities(result))
      .catch((error) => console.log(error));

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
      setIsUpdate(false);
    }
  }, [props]);

  const getRoles = async () => {
    return await fetch(`${API_URL}/api/v1/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const getFacilities = async () => {
    return await fetch(`${API_URL}/api/v1/facilitys`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        }
        return [];
      })
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    const { newPassword, password, ...rest } = fields;
    if (newPassword && !(/^(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&*+=!.]).{6,}$/gm.test(newPassword))) {
      return notification.error({
        message: <div>
          Parola aşağıdaki kurallarda yazılmalıdır:
          <ol>
            <li>En az 6 karakter uzunluğunda olmalı,</li>
            <li>En az bir numara içermeli,</li>
            <li>En az bir küçük harf içermeli,</li>
            <li>En az bir büyük harf içermeli.</li>
          </ol>
        </div>
      })
    }
    if (!rest.full_name) {
      return notification.error({ message: "AD SOYAD gerekli alan" })
    }
    if (rest.username?.length >= 5) {
      return await fetch(`${API_URL}/api/v1/users`, {
        method: isUpdate ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: isUpdate
          ? newPassword
            ? JSON.stringify({
              body: { ...rest, password: newPassword },
              where: { user_id: props.record.user_id },
            })
            : JSON.stringify({
              body: rest,
              where: { user_id: props.record.user_id },
            })
          : JSON.stringify({ ...rest, password: newPassword }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          if (result && !result.errors) {
            return window.location.reload();
          }
        })
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
              ? message.error(error)
              : notification.error({ message: error })
        );
    }
  };

  return (
    <Modal
      width={600}
      okButtonProps={{
        form: "user-form",
        key: "submit",
        htmlType: "submit",
        icon: <SaveOutlined />,
        onClick: handleClick,
      }}
      cancelButtonProps={{
        form: "user-form",
        key: "cancel",
        danger: true,
        type: "primary",
        icon: <CloseCircleOutlined />,
      }}
      okText="Kaydet"
      cancelText={props.type === "request" ? "Reddet" : "İptal"}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
    >
      <Form
        style={{ margin: "5% 1%", padding: "5%" }}
        {...layout}
        form={form}
        id="user-form"
      >
        <Form.Item label="Ad Soyad" name="full_name">
          <Input
            name="full_name"
            onChange={handleChange}
            disabled={
              (facilities?.length === 0 || !facilities) &&
              isUpdate &&
              fields.is_device === true
            }
          />
        </Form.Item>
        <Form.Item
          label="Kullanıcı Adı"
          name="username"
          rules={[
            {
              required: !isUpdate,
              warningOnly: false,
              type: "string",
              whitespace: true,
            },
            () => ({
              async validator(_, value) {
                if (value?.length < 5) {
                  return Promise.reject(
                    new Error("Kullanıcı adı en az 5 karakter olmalı.")
                  );
                }
              },
            }),
          ]}
          validateTrigger="onBlur"
        >
          <Input
            name="username"
            onChange={handleChange}
            disabled={
              (facilities?.length === 0 || !facilities) &&
              isUpdate &&
              fields.is_device === true
            }
          />
        </Form.Item>
        <Form.Item label="Rol" name="role">
          <Select
            disabled={
              (facilities?.length === 0 || !facilities) &&
              isUpdate &&
              fields.is_device === true
            }
            onSelect={(value) =>
              handleChange({ target: { name: "role", value } })
            }
          >
            {roles?.map((role) => {
              const rolename = role.role_name;
              return (
                <>
                  <Option value={role.role_id}>{rolename}</Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        {facilities?.length > 0 ? (
          <>
            <Form.Item label="Tesis" name="facility_id">
              <Select
                onSelect={(value) =>
                  handleChange({ target: { name: "facility_id", value } })
                }
              >
                {facilities?.map((facility) => {
                  const facilityname = facility.name;
                  return (
                    <>
                      <Option value={facility.id}>{facilityname}</Option>
                    </>
                  );
                })}
                <Option value={0}>Bütün Tesisler</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Cihaz Mı" name="is_device">
              <Select
                disabled={
                  facilities.length === 0 &&
                  isUpdate &&
                  fields.is_device === true
                }
                onSelect={(value) => {
                  handleChange({ target: { name: "is_device", value } });
                }}
              >
                {[true, false]?.map((is_device) => {
                  return (
                    <>
                      <Option value={is_device}>
                        {is_device ? "Evet" : "Hayır"}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
            {fields.is_device === true && (
              <Form.Item label="Üye ÇIKIŞ verisi kaydedilsin" name="is_logout">
                <Select
                  onSelect={(value) => {
                    handleChange({ target: { name: "is_logout", value } });
                  }}
                >
                  {[true, false]?.map((is_logout) => {
                    return (
                      <>
                        <Option value={is_logout}>
                          {is_logout ? "Evet" : "Hayır"}
                        </Option>
                      </>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </>
        ) : null}
        <Form.Item
          label="E-posta"
          name="email"
          rules={[
            {
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: "e-Posta formatı yanlış",
            },
          ]}
        >
          <Input
            type={"email"}
            name="email"
            onChange={handleChange}
            disabled={
              (facilities?.length === 0 || !facilities) &&
              isUpdate &&
              fields.is_device === true
            }
          />
        </Form.Item>
        <Form.Item
          label="Telefon"
          name="phone"
          rules={[
            {
              pattern:
                /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/,
              message: "Telefon formatı yanlış veya sonuna boşluk var",
            },
          ]}
        >
          <Input
            name="phone"
            onChange={handleChange}
            disabled={
              facilities?.length === 0 && isUpdate && fields.is_device === true
            }
          />
        </Form.Item>
        <Form.Item
          label="Parola"
          name="newPassword"
          rules={[
            {
              required: isUpdate === false ? true : false,
              warningOnly: false,
              type: "string",
              whitespace: true,
            },
            () => ({
              async validator(_, value) {
                if (value?.length < 6) {
                  return Promise.reject(
                    new Error("Parola en az 6 karakter olmalı.")
                  );
                }
                if (!/\d/.test(value) && value) {
                  return Promise.reject(
                    new Error("Parola en az bir numara içermelidir.")
                  );
                }
                if (!/[a-z]/.test(value) && value) {
                  return Promise.reject(
                    new Error("Parola en az bir küçük harf içermelidir.")
                  );
                }
                if (!/[A-Z]/.test(value) && value) {
                  return Promise.reject(
                    new Error("Parola en az bir büyük harf içermelidir.")
                  );
                }
              },
            }),
          ]}
          validateTrigger="onBlur"
          tooltip={
            <div>
              Parola aşağıdaki kurallarda yazılmalıdır:
              <ol>
                <li>En az 6 karakter uzunluğunda olmalı,</li>
                <li>En az bir numara içermeli,</li>
                <li>En az bir küçük harf içermeli,</li>
                <li>En az bir büyük harf içermeli.</li>
              </ol>
            </div>
          }
        >
          <Input
            disabled={
              (facilities?.length === 0 || !facilities) &&
              isUpdate &&
              fields.is_device === true
            }
            name="newPassword"
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UsersModal;
