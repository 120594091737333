import { API_URL } from "../config";
import React from "react";
import { Button, Tag } from "antd";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import ListMembersCell from "../components/ListMembersCell";




const SubsTickets = () => {
  const [modalMemberListInfo, setModalMemberListInfo] = React.useState({ data: null, open: false });

  const [all, setAll] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/subscriptions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.ok && response?.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);



  const initialColumns = [
    {
      title: "İsim",
      dataIndex: "name",
      key: "name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      width: 150,
    },

    {
      title: "Tarife",
      width: 150,
      dataIndex: "schedule_name",
      key: "schedule_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],

    },
    {
      title: "Geçiş Hakkı",
      width: 150,
      dataIndex: "entries",
      key: "entries",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Geçerlilik Süresi ",
      width: 150,
      dataIndex: "duration",
      key: "duration",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item && item + " ay"
      }
    },
    {
      title: "Fiyat",
      width: 150,
      dataIndex: "cost",
      key: "cost",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: fee => {
        return fee + " TL"
      }
    },
    {
      title: "Kapasite",
      width: 150,
      dataIndex: "capacity",
      key: "capacity",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],

    },
    {
      title: "Personel için",
      width: 100,
      dataIndex: "employee",
      key: "employee",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (d) => {
        return d ? "EVET" : "HAYIR"
      }
    },
    {
      title: "Olüşturulma tarihi",
      width: 150,
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item?.split("T")?.[0]
      }
    }, {
      title: "Üyeler",
      dataIndex: "members",
      key: "members",
      responsive: ["xs", "md", "lg"],
      width: 100,
      defaultSelected: true,
      render: (_, row) => {
        return (
          row.members?.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setAll(true)
                  setModalMemberListInfo({
                    open: true,
                    data: row.members,
                  });
                }}
              >
                Üyeleri görüntüle
              </Button>
            </div>
          )
        );
      },
    },
  ];

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="ABONELİKLER"
        excel={false}
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        isLoading={isLoading}
        type={"subscription"}
      />
      <ListMembersCell
        datasource={modalMemberListInfo.data}
        all={all}
        type="subscription"
        modalInfo={modalMemberListInfo}
        setModalInfo={setModalMemberListInfo}
        setAll={setAll}
      />
    </ErrorBoundary>
  );
};

export default SubsTickets;
