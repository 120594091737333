import { API_URL } from "../config";
import React from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, Tag } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { nationalHolidays, responseControl } from "../assets/scripts";
import moment from "moment";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const AppointmentsModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [program, setProgram] = React.useState(null);

  const [form] = Form.useForm();
  React.useEffect(() => {
    setLoading(true);
    const getMembers = async () => {
      return await fetch(`${API_URL}/api/v1/members`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => console.log(error));
    };
    getMembers()
      .then((result) => setMembers(result))
      .catch((error) => console.log(error));
    const getPrograms = async () => {
      return await fetch(`${API_URL}/api/v1/programs`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => response.json())
        .then((result) => setPrograms(result))
        .catch((error) => console.log(error))
    };
    getPrograms()
      .finally(() => setLoading(false))
  }, [API_URL]);


  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    return await fetch(`${API_URL}/api/v1/appointments`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...fields, isProgram: true }),
    })
      .then((response) => responseControl(response))
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };
  const [member, setMember] = React.useState(null);

  React.useEffect(() => {
    if (fields?.member_id) {
      const memberObj = members.find((m) => m.id === fields.member_id);
      setMember(memberObj);
    }

  }, [fields, members]);


  const daysListTr = {
    sunday: "Pazar",
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
  };
  const daysListNo = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
  };
  const generateTime = (s, pickedProgram) => {
    return s?.map(s => {
      const startDate = new Date(
        "2022-01-01T" + s.start_time + ".00Z"
      );
      startDate.setMinutes(
        startDate.getMinutes() + parseInt(pickedProgram.entry_time_range)
      );

      const beforeStart = new Date(
        "2022-01-01T" + s.start_time + ".00Z"
      );

      beforeStart.setMinutes(
        beforeStart.getMinutes() - parseInt(pickedProgram.entry_time_range) + 1
      );

      return <p>{beforeStart.toISOString().split("T")[1].split(".")[0].split(":").slice(0, 2).join(":") +
        " - " +
        startDate.toISOString().split("T")[1].split(".")[0].split(":").slice(0, 2).join(":")
      }</p>
    })
  };


  const getSessionsIntervals = () => {
    const pickedProgram = programs.find(p => p.id === program?.id);
    return Object.values(pickedProgram?.sessions?.reduce((obj, s) => {
      if (obj[s.day]) {
        obj[s.day].sessions.push(s);
      } else {
        obj[s.day] = {
          day: s.day,
          sessions: [s]
        }
      }
      return obj;
    }, {}))?.map(s => {

      const sessions = s.sessions;

      return (<>
        <Tag style={{ width: "200px", margin: "4px" }}>
          <p>{daysListTr[s.day.toLowerCase()] + " : "}</p>
          <p>{generateTime(sessions, pickedProgram)}</p>
        </Tag> <br /></>
      );
    });
  }


  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    if (program) {
      const sessionsDays = program?.sessions?.map(d => {
        return daysListNo[d.day.toLowerCase()]
      });
      const limitDay = new Date();
      limitDay.setDate(limitDay.getDate() + Number(program.max_days));
      const year = current.format("YYYY");
      const currDate = current.format("YYYY-MM-DD");
      const holidays = nationalHolidays[year];
      const holiday = holidays.find(h => h.date === currDate);
      if (holiday || current.day() === 1) {
        return true
      }
      return !sessionsDays.includes(current.day()) || current > limitDay
    }
  };
  const disabledDateTime = (date) => {
    const hours = [];
    const mins = [];
    if (date && program) {
      for (const s of program.sessions) {
        const dNum = daysListNo[s.day.toLowerCase()];
        if (dNum === date.day()) {
          const sDate = new Date(date);
          sDate.setHours(Number(s.start_time.split(":")[0]))
          sDate.setMinutes(Number(s.start_time.split(":")[1]) - Number(program.entry_time_range) + 1);
          const eDate = new Date(date);
          eDate.setHours(Number(s.start_time.split(":")[0]));
          eDate.setMinutes(Number(s.start_time.split(":")[1]) + Number(program.entry_time_range));
          const start = Number(sDate.getHours());
          const end = Number(eDate.getHours());
          hours.push({ day: dNum, start })
          mins.push({ day: dNum, hour: start, start: Number(sDate.getMinutes()), end: Number(eDate.getMinutes()) });
        }
      }

    }

    return ({
      disabledHours: (d) => range(0, 24).filter(n => {
        let check = true;
        for (const s of hours) {
          if (n === s.start) {
            check = false
          }
        }
        return check;
      }),
      disabledMinutes: (h) => range(0, 60).filter(n => {
        let check = true;
        for (const s of mins) {
          if (s.hour == h) {
            if (s.start <= n && n <= s.end) {
              check = false
            }
          }
        }
        return check;
      }),
    })
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Üye" name="member_id">
          <Select
            showArrow
            loading={loading}
            onSelect={(value) =>
              handleChange({ target: { name: "member_id", value } })
            }
          >
            {typeof members?.map === "function"
              ? members?.map((member) => {
                const membername = member.first_name + " " + member.last_name;
                return (
                  <>
                    <Option value={member.id}>{membername}</Option>
                  </>
                );
              })
              : null}
          </Select>
        </Form.Item>

        <Form.Item label="Program" name="program_id">
          <Select
            showArrow
            loading={loading}
            onSelect={(value) => {
              setProgram(programs?.find(p => p.id === value))
              handleChange({ target: { name: "program_id", value } })
            }}
          >
            {typeof programs?.map === "function"
              ? programs
                ?.filter((p) => {
                  if (p.appointment_based) {
                    const currentDate = new Date();
                    const startProgram = new Date(p.startline);
                    const endProgram = new Date(p.deadline);

                    if (
                      startProgram.getTime() <= currentDate.getTime() &&
                      currentDate.getTime() <= endProgram.getTime()
                    ) {
                      const filters = JSON.parse(p.filter);
                      const isAllowed = Object.entries(filters).every((filter) => {
                        const [key, value] = filter;
                        if (key === "in") {
                          return value.every((filterObj) => {
                            for (let [key, val] of Object.entries(filterObj)) {
                              val = Array.isArray(val) ? val.flat(Infinity) : val;
                              switch (key) {
                                case "birthdate":
                                  {

                                    if (
                                      (new Date(member.birthdate?.split("T")?.[0]).getFullYear()) !== (new Date(Array.isArray(val) ? val?.[0] : val?.split("T")?.[0]).getFullYear())
                                    ) {
                                      return false;
                                    }
                                  }
                                  break;
                                case "gender":
                                  {
                                    if (!val.includes(member?.gender)) {
                                      return false;
                                    }
                                  }
                                  break;
                                case "club":
                                  if (!val.includes(member?.club?.id)) {
                                    return false;
                                  }
                                  break;
                                case "schedule":
                                  if (!val.flat().some(v => member?.schedule?.includes(v))) {
                                    return false;
                                  }
                                  break;
                                case "trainer":
                                  if (!val.includes(member?.trainer?.id)) {
                                    return false;
                                  }
                                  break;
                                case "card_id":
                                  if (!val.includes(member?.card_id)) {
                                    return false;
                                  }
                                  break;
                                default:
                                  break;
                              }
                            }
                            return true;
                          });
                        }
                        if (key === "between") {
                          const birthdates = value?.[0]?.birthdate;
                          if (birthdates) {
                            return (
                              new Date(birthdates[0]).getTime() <=
                              new Date(member?.birthdate).getTime() &&
                              new Date(member?.birthdate) <= new Date(birthdates[1]).getTime()
                            );
                          }
                        }
                        return true;
                      });

                      if (isAllowed) {
                        return true;
                      }
                    }
                  }
                  return false;
                })
                ?.map((program) => {
                  return (
                    <>
                      <Option value={program.id}>{program.title}</Option>
                    </>
                  );
                })
              : null}
          </Select>
        </Form.Item>


        {program && (
          <>
            <Form.Item label="Randevu günleri">
              {program.sessions.map((session) => {
                return <p>{daysListTr[session.day.toLowerCase()]}</p>;
              })}
            </Form.Item>

            <Form.Item label="Seans Aralığı">
              {getSessionsIntervals()}
            </Form.Item>
            <Form.Item label="Kapasitesi">
              {program.appointments_count +
                "/" +
                program.capacity}
            </Form.Item>
          </>
        )}

        <Form.Item label="Tarih" name="date">
          <DatePicker
            showTime={{
              format: 'HH:mm',
            }}
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            onChange={(date, dateIndex) => {
              handleChange({
                target: {
                  name: "date",
                  value: dateIndex,
                },
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppointmentsModal;
