import { API_URL } from "../config";
import { Tag } from "antd";
import React from "react";
import { deleteRecord } from "../assets/scripts";

import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import {
  SaveOutlined,
  UserOutlined,
  TeamOutlined,
  PayCircleOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  SkinOutlined,
  StarOutlined,
  DashboardOutlined,
  CalendarOutlined,
  DesktopOutlined,
  VerifiedOutlined,
  FilePdfOutlined,
  CalculatorOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  PlusCircleOutlined,
  ReadOutlined,
  BookOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const colors = [
  "purple",
  "magenta",
  "gold",
  "geekblue",
  "orange",
  "volcano",
  "lime",
  "red",
  "green",
  "cyan",
  "blue",
];

const returns = {
  members: "Üyeler",
  files: "Dosyalar",
  verifications: "Onaylamalar",
  cards: "Kartlar",
  subscriptions: "Abonelikler",
  logs: "Loglar",
  schedules: "Tarifeler",
  roles: "Roller",
  users: "Kullanıcılar",
  payments: "Ödemeler",
  bills: "Harcama Detayı",
  clubs: "Spor Kulüpleri",
  gsbClubs: "GSB Spor Kulüpleri",
  trainers: "Antrenörler",
  calendars: "Takvim",
  devices: "Cihazlar",
  // vault: "Kasa",
  seasons: "Seanslar",
  tickets: "Biletler",
  appointments: "Randevular",
  programs: "Programlar",
  facility_bank_accounts: "Banka Hesabı",
  courses: "Kurslar",
  notifications: "Bildirimler",
  rules: "Tesis Kuralları",
  health_checks: "Sağlık Raporları",
  "*": "Hepsi",
};

const icons = {
  members: <UserOutlined />,
  files: <FilePdfOutlined />,
  verifications: <VerifiedOutlined />,
  cards: <CreditCardOutlined />,
  subscriptions: <CreditCardOutlined />,
  logs: <SaveOutlined />,
  schedules: <PayCircleOutlined />,
  roles: <UserAddOutlined />,
  users: <TeamOutlined />,
  payments: <DollarCircleOutlined />,
  bills: <CalculatorOutlined />,
  clubs: <SkinOutlined />,
  gsbClubs: <SkinOutlined />,
  trainers: <DashboardOutlined />,
  calendars: <CalendarOutlined />,
  devices: <DesktopOutlined />,
  // vault: <MoneyCollectOutlined />,
  seasons: <ScheduleOutlined />,
  tickets: <ContainerOutlined />,
  appointments: <PlusCircleOutlined />,
  programs: <BookOutlined />,
  facility_bank_accounts: <BankOutlined />,
  courses: <ReadOutlined />,
  notifications: <ContainerOutlined />,
  rules: <BookOutlined />,
  health_checks: <BookOutlined />,
  "*": <StarOutlined />,
};
const columns = [
  {
    title: "Rol Adı",
    dataIndex: "role_name",
    key: "role_name",
    defaultSelected: true,
  },
  {
    title: "Kullanıcı Onayı",
    dataIndex: "verification",
    key: "verification",
    defaultSelected: true,
    render: (item) => (item === true ? <p>Var</p> : <p>Yok</p>),
  },
  {
    title: "Görüntüleyebilir",
    dataIndex: "get",
    key: "get",
    defaultSelected: true,
    render: (items) =>
      items !== null && items
        ? items.split(",").map((item, idx) => (
          <Tag color={colors[idx]} style={{ marginBottom: "1%" }}>
            {item.split(" ")[1] === undefined ? (
              <>
                {icons[item]} {returns[item]}
              </>
            ) : (
              <>
                {icons[items.split(" ")[1]]}
                {returns[items.split(" ")[1]]}
              </>
            )}
          </Tag>
        ))
        : null,
  },
  {
    title: "Ekeyebilir",
    dataIndex: "post",
    key: "post",
    defaultSelected: true,
    render: (items) =>
      items !== null && items
        ? items.split(",").map((item, idx) => (
          <Tag color={colors[idx]} style={{ marginBottom: "1%" }}>
            {item.split(" ")[1] === undefined ? (
              <>
                {icons[item]} {returns[item]}
              </>
            ) : (
              <>
                {icons[items.split(" ")[1]]}
                {returns[items.split(" ")[1]]}
              </>
            )}
          </Tag>
        ))
        : null,
  },
  {
    title: "Düzenleyebilir",
    dataIndex: "put",
    key: "put",
    defaultSelected: true,
    render: (items) =>
      items !== null && items
        ? items.split(",").map((item, idx) => (
          <Tag color={colors[idx]} style={{ marginBottom: "1%" }}>
            {item.split(" ")[1] === undefined ? (
              <>
                {icons[item]} {returns[item]}
              </>
            ) : (
              <>
                {icons[items.split(" ")[1]]}
                {returns[items.split(" ")[1]]}
              </>
            )}
          </Tag>
        ))
        : null,
  },
  {
    title: "Silebilir",
    dataIndex: "delete",
    key: "delete",
    defaultSelected: true,
    render: (items) =>
      items !== null && items
        ? items.split(",").map((item, idx) => (
          <Tag color={colors[idx]} style={{ marginBottom: "1%" }}>
            {item.split(" ")[1] === undefined ? (
              <>
                {icons[item]} {returns[item]}
              </>
            ) : (
              <>
                {icons[items.split(" ")[1]]}
                {returns[items.split(" ")[1]]}
              </>
            )}
          </Tag>
        ))
        : null,
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    defaultSelected: true,
    render: (item, row, idx) => (
      <TableActions
        type="role"
        onClick={() => deleteRecord(`role_id=${row.role_id}`, "roles")}
      />
    ),
  },
];

const Roles = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/roles`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => setDataSource(result))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="ROLLER"
        columns={columns}
        dataSource={dataSource}
        type="role"
        isLoading={isLoading}
        endpoints={returns}
      />
    </ErrorBoundary>
  );
};

export default Roles;
