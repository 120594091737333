import { API_URL } from "../config";
import React from "react";
import { Button, Image, Modal, Tag } from "antd";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";



const Notifications = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [photo, setPhoto] = React.useState({ data: null, open: false });

  React.useEffect(() => {

    const initialColumns = [
      {
        title: "Başlık",
        dataIndex: "title",
        key: "title",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        width: 150,
      },

      {
        title: "Mesaj",
        width: 150,
        dataIndex: "message",
        key: "message",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Oluşturan",
        width: 150,
        dataIndex: "user_fullname",
        key: "user_fullname",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Yayında",
        width: 150,
        dataIndex: "publish",
        key: "publish",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        render: bool => {
          return bool ? <Tag color="green">EVET</Tag> : <Tag color="blue">HAYIR</Tag>
        }
      },
      {
        title: "Bitiş tarihi",
        width: 150,
        dataIndex: "expiry_date",
        key: "expiry_date",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        render: (item) => {
          return item?.split("T")?.[0]
        }
      },
      {
        title: "Oluşturan",
        width: 150,
        dataIndex: "creator_name",
        key: "creator_name",
        defaultSelected: false,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Olüşturulma tarihi",
        width: 150,
        dataIndex: "createdAt",
        key: "createdAt",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        render: (item) => {
          return item?.split("T")?.[0]
        }
      },
      {
        title: "Resim",
        width: 150,
        dataIndex: "photo",
        key: "photo",
        defaultSelected: false,
        responsive: ["xs", "md", "lg"],
        render: (base64) => {
          return <Image onClick={e => e.stopPropagation()} src={base64} />
        },
      }
    ];

    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/notifications`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.ok && response?.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="BİLDİRİMLER"
        excel={false}
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        isLoading={isLoading}
        type={"notification"}

      />

      <Modal visible={photo.open} onCancel={() => setPhoto({ data: null, open: false })} footer={[<Button
        onClick={() => setPhoto({ data: null, open: false })}
      >Kapat</Button>]}>
        <Image style={{ marginTop: "2em" }} src={photo.data} />
      </Modal>
    </ErrorBoundary>
  );
};

export default Notifications;
