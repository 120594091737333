const steps = [
  {
    target: ".dashboard",
    disableBeacon: true,
    content: (
      <div>
        <h4>Panel</h4>
        <h6 style={{ opacity: "0.9" }}>
          Panel sayfasında grafik biçimindeki raporlar gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".total-till",
    disableBeacon: true,
    content: (
      <div>
        <h4>Toplam Ciro</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait toplam ciro burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".total-visitor",
    disableBeacon: true,
    content: (
      <div>
        <h4>Toplam Ziyaret</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait toplam ziyaretçi sayısı burada gösterilir
        </h6>
      </div>
    ),
  },
  {
    target: ".current-till",
    disableBeacon: true,
    content: (
      <div>
        <h4>Tesis Kasası</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait anlık ciro burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".current-visitor",
    disableBeacon: true,
    content: (
      <div>
        <h4>Mevcut Ziyaretçi</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait anlık ziyaretçi sayısı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".hourly-visitors",
    disableBeacon: true,
    content: (
      <div>
        <h4>Ziyaretçi Saatleri</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait saatlik ziyaretçi sayısı kırılımı burada
          gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".monthly-visitors",
    disableBeacon: true,
    content: (
      <div>
        <h4>Aylık Ziyaretçi Sayısı</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait aylık ziyaretçi sayısı kırılımı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".monthly-payments",
    disableBeacon: true,
    content: (
      <div>
        <h4>Aylık Ciro</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait aylık ciro miktarı kırılımı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".date-selection",
    disableBeacon: true,
    content: (
      <div>
        <h4>Tarih Seçimi</h4>
        <h6 style={{ opacity: "0.9" }}>
          Belirli bir aya göre rapor almak istendiğinde ilgili ay buradan
          seçilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".pdf-export-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>PDF Yazdırma Butonu</h4>
        <h6 style={{ opacity: "0.9" }}>Alınan rapor PDF'e çıkartılabilir.</h6>
      </div>
    ),
  },
  {
    target: ".new-users",
    disableBeacon: true,
    content: (
      <div>
        <h4>Yeni Üye Sayısı</h4>
        <h6 style={{ opacity: "0.9" }}>
          İçinde bulunulan ay içerisindeki yeni kayıtların cinsiyete göre
          dağılımı ve sayısı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".gender-activities",
    disableBeacon: true,
    content: (
      <div>
        <h4>Cinsiyet Bazlı Üye Giriş Sayısı</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait cinsiyet bazlı üye giriş sayısı burada gösterilir.
          Üyenin cinsiyeti girilmemişse, üyenin girişleri 'Belirtilmemişler'
          arasına yazılır.
        </h6>
      </div>
    ),
  },
  {
    target: ".daily-activities",
    disableBeacon: true,
    content: (
      <div>
        <h4>Günlük Üye Giriş Sayısı</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait, içinde bulunulan veya tarih seçiminde seçilen
          ayın bütün günleri için günlük üye giriş sayısı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".daily-payments",
    disableBeacon: true,
    content: (
      <div>
        <h4>Günlük Ödeme</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bütün tesislere ait, içinde bulunulan ayın bütün günleri için günlük
          ödeme miktarı burada gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".members",
    disableBeacon: true,
    content: (
      <div>
        <h4>Üyeler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Üyelerin özlük bilgilerinin tutulduğu sayfa.
        </h6>
      </div>
    ),
  },
  {
    target: ".member-table",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Üyeler Tablosu</h4>
        <h6 style={{ opacity: "0.9" }}>
          Üyenin özlük bilgilerini, kart numarasını, bakiyesini, tesis içi
          bilgilerini (tarifesi, klübü, antenorünü vb.) içerir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Mevcut üyelerin bilgilerinde değişiklik yapmak için, ilgili üyenin
          bulunduğu satıra tıklanmalıdır. Bu kural bütün tablolar için
          geçerlidir.
        </h6>
      </div>
    ),
  },
  {
    target: ".filters",
    disableBeacon: true,
    placement: "right",
    content: (
      <div>
        <h4>Sütun Filtresi</h4>

        <h6 style={{ opacity: "0.9" }}>
          Bu filtreleme kullanılarak tablolalarda hangi sütunların gösterilip
          gösterilmeyeceği seçilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".actions",
    disableBeacon: true,
    placement: "right",
    content: (
      <div>
        <h4>İşlemler Menüsü Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          Bu filtreleme kullanılarak tablolalarda işlemler menüsü açılır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Her tablonun kendi işlemler menüsü vardır. Bunlardan bazıları o
          tabloya özel işlemlerken bazıları (örn. silme işlemi) diğer tablolarda
          görülebilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".pdf-export-action-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Üye Bilgileri PDF Aktarma Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          Üyenin bilgilerinin, kurum tarafından iletilen formatta PDF olarak
          indirilmesini ve/veya yazdırılmasını sağlar.
        </h6>
      </div>
    ),
  },
  {
    target: ".print-card",
    disableBeacon: true,
    content: (
      <div>
        <h4>Üyelik Kartı Yazdırma Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili üye için üyelik kartı çıkarılmasını sağlar.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Kart çıkarılabilmesi için kart baskı makinasının, uygulamanın
          çalıştığı cihaza bağlı olması gereklidir.
        </h6>
      </div>
    ),
  },
  {
    target: ".upload-file",
    disableBeacon: true,
    content: (
      <div>
        <h4>Dosya Yükleme Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili üyeye ait dosyaların sisteme kaydedilmesi için buradan dosya
          yüklenir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Dosyalar genellikle onaylama bekleyen tarifeler (örn. öğrenci
          tarifeleri için öğretim belgesi) için üyeden talep edilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Yüklenen dosyalar daha sonra{" "}
          <strong style={{ opacity: "1" }}>Dosyalar</strong> menüsünden
          görüntülenebilir.{" "}
        </h6>
      </div>
    ),
  },
  {
    target: ".new-record-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Yeni Kayıt Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili sayfa için yeni bir kayıt açılmak istendiğinde bu butona
          basılır. Buton yeni bir form açar. Bu form içerisine yeni
          oluşturulacak kayda ait bilgiler girilir.
        </h6>
      </div>
    ),
    spotlightClicks: false,
  },
  {
    target: ".export-excel-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Excel'e Çıkarma Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili sayfada bulunan verilerin Excel formatına aktarılması için
          kullanılır.
        </h6>
      </div>
    ),
  },
  {
    target: ".excel-template-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Excel Şablonu Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili sayfaya toplu olarak Excel ile veri gönderileceği zaman
          kullanılması gereken Excel dosyası şablonunu indirir.
        </h6>
      </div>
    ),
  },
  {
    target: ".upload-excel-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Excel Yükleme Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          İlgili sayfya toplu olarak Excel dosyası ile veri yüklenmek istendiği
          zaman kullanılır.
        </h6>
      </div>
    ),
    spotlightClicks: false,
  },
  {
    target: ".bulk-selection-btn",
    disableBeacon: true,
    content: (
      <div>
        <h4>Toplu Seçim Butonu</h4>

        <h6 style={{ opacity: "0.9" }}>
          Tablodaki verileri toplu olarak silmek için kullanılır.
        </h6>
      </div>
    ),
    spotlightClicks: false,
  },
  {
    target: ".ant-table-selection-column",
    disableBeacon: true,
    content: (
      <div>
        <h4>Toplu Seçim Checkbox</h4>

        <h6 style={{ opacity: "0.9" }}>Silinecek veri buradan seçilebilir.</h6>
      </div>
    ),
    spotlightClicks: false,
  },

  {
    target: ".files",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Dosyalar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Üyelere, <strong>Üyeler</strong> sayfası üzerinden yüklenen özlük
          dosyaları burada listelenir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Özlük dosyalarına bu menüden zamanaşımı süresi atanabilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Üyenin bulunduğu tarife onaya bağlı ise, dosyası zamanaşımı süresine
          uğradığında üye tesise giriş yapamaz.
        </h6>
      </div>
    ),
  },
  {
    target: ".verifications",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Onaylamalar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Onaya bağlı tarifelere kaydedilmiş üyelerin üyeliği bu sayfadan
          onaylanır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Eğer üyeye bir tarife atanmadıysa, üyelik onayı yapılamaz.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Onaya bağlı tarifelere kaydedilmiş üyelerin bilgilerinde yapılan her
          değişiklikten sonra üyenin üyeliğinin tekrar onaylanması gerekir.
        </h6>
      </div>
    ),
  },
  {
    target: ".cards",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Kartlar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Kart numaraları ve bakiyeleri, <strong>Üyeler</strong> menüsüne ek
          olarak bu sayfada da gösterilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".logs",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Hareketler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Tesislerdeki giriş çıkışların gösterildiği sayfadır.
        </h6>
      </div>
    ),
  },
  {
    target: ".payments",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Ödemeler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Üye kartlarına yüklenen bakiye hareketlerini gösterir.
        </h6>
      </div>
    ),
  },
  {
    target: ".tickets",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Biletler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Tek kullanımlık biletler buradan çıkartılır ve burada listelenir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Tek kullanımlık biletin çıkarılabilmesi için öncelikle{" "}
          <strong>Terminal Bilgileri</strong> menüsünde bilet ücreti
          belirlenmelidir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Biletler ister TC Kimlik numarası kullanılarak, ister direkt olarak
          basılabilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".bills",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Harcama Detayları</h4>
        <h6 style={{ opacity: "0.9" }}>
          Seçilen üyeye ait harcama ve ödeme detaylarını gösterir.
        </h6>
      </div>
    ),
  },
  {
    target: ".seasons",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Seanslar</h4>
        <h6 style={{ opacity: "0.9" }}>Tesise ait seansları gösterir.</h6>
        <h6 style={{ opacity: "0.9" }}>
          Tesise seans eklenmesi için öncelikle{" "}
          <strong>Terminal Bilgileri</strong> menüsünden{" "}
          <strong>Seans dışı girişler engellensin</strong> seçeneğinin{" "}
          <strong>Evet</strong> olarak seçilmesi gerekir.
        </h6>
      </div>
    ),
  },

  {
    target: ".appointments",
    disableBeacon: true,
    placement: "center",
    placement: "center",
    content: (
      <div>
        <h4>Randevular</h4>
        <h6 style={{ opacity: "0.9" }}>
          Üyelerin yaptğı randevular burada gösterilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Üyeler kendileri mobil veya web uygulama üzerinden randevu alabilir
          veya yetkili tesis çalışanı da üyelere randevu tanımlayabilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Tesisten randevu alınabilmesi için tesise ait seans olması gerekir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Seans dışı girişlerin engellenmediği tesislerde randevu sistemi olmaz.
        </h6>
      </div>
    ),
  },
  {
    target: ".schedules",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Tarifeler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Tarife tanımlamaları bu sayfada yapılır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Tarife <strong>onay gerektiriyor</strong> olarak tanımlanırsa bu
          tarifenin seçildiği üyelerin üyelik durumu{" "}
          <strong>Onaylamalar</strong> sayfasına düşer. Üyelik onaylanana kadar
          üye tesise giriş yapamaz.
        </h6>
      </div>
    ),
  },
  {
    target: ".clubs",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Kulüpler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Spor Kulüpleri tanımlamaları burada yapılır.
        </h6>
      </div>
    ),
  },
  {
    target: ".trainers",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Antrenörler</h4>
        <h6 style={{ opacity: "0.9" }}>
          Antrenör tanımlamaları burada yapılır.
        </h6>
      </div>
    ),
  },
  {
    target: ".programs",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Programlar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bu sayfada tesise, haftanın günleri üzerinden program atması yapılır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Programlar bu sayfada listelenmesinin yanı sıra{" "}
          <strong>Takvim</strong> sayfasında da gösterilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Programlara başlama ve bitiş tarihi de verilebilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Program eklenirken seçilen filtreye uymayan üyenin, program esnasında
          tesise girişi engellenir.
        </h6>
      </div>
    ),
  },
  {
    target: ".calendars",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Takvim</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bu sayfadan etkinlik ve program eklenebilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Etkinlik tarih bazlıyken program gün bazlıdır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Etkinlik ve program eklenirken seçilen filtreye uymayan üyenin,
          program esnasında tesise girişi engellenir.
        </h6>
      </div>
    ),
  },
  {
    target: ".devices",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Cihazlar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Tesise tanımlanmış tunike içi cihazlar buradan eklenir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Cihazlara olan bağlantı kontrolü bu sayfa üzerinden yapılabilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".roles",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Roller</h4>
        <h6 style={{ opacity: "0.9" }}>Rol tanımlamaları burada yapılır.</h6>
        <h6 style={{ opacity: "0.9" }}>
          Rol kayıtlarının diğer kayıtlardan farkı tesis bazlı olmamasıdır.
          Yani, örneğin A tesisinde eklenen bir rol B tesisi tarafından da
          kullanıcılara atanmak için kullanılabilir. Bu sayede mükerrer ve
          gereksiz rol kayıtlarının önüne geçilmiş, sistem kullanıcıları için
          kullanım kolaylığı sağlanmış olur.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Rol tanımlamaları esnek bir yapıya sahiptir. Hangi rolün hangi menüde
          ne işlem yapacağı buradan belirlenebilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".users",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Kullanıcılar</h4>
        <h6 style={{ opacity: "0.9" }}>
          Kullanıcı tanımlamaları buradan yapılır.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Bir tesise bağlı admin kullanıcısı o tesise ait kullanıcılar
          tanımlayabilir. Başka tesislere ait kullanıcılar atayamaz.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Superadmin kullanıcısı ise istediği tesise istediği kullanıcı
          tanımlamasını yapabilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Turnike içi cihazlarda kullanılacak olan{" "}
          <strong>cihaz kullanıcısını</strong> sadece{" "}
          <strong>superadmin</strong> kullanıcısı atayabilir.
        </h6>
      </div>
    ),
  },
  {
    target: ".facility_bank_accounts",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Banka Hesap Bilgileri</h4>
        <h6 style={{ opacity: "0.9" }}>
          Buraya, üyeler 3D Secure üzerinden bakiye yüklemek istediklerinde
          yüklenen miktarın yatıralacağı banka hesabına ait 3D Secure bilgileri
          girilir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Buraya girilen bilgiler veritabanında saklanır ve güvenlik gerekçesi
          nedeniyla ekranda gösterilmez.
        </h6>
      </div>
    ),
  },
  {
    target: ".terminal",
    disableBeacon: true,
    placement: "center",
    content: (
      <div>
        <h4>Tesis Bilgileri</h4>
        <h6 style={{ opacity: "0.9" }}>Tesis detayları bu sayfada yer alır.</h6>
      </div>
    ),
  },
  {
    target: ".facility_address",
    disableBeacon: true,
    content: (
      <div>
        <h4>Tesis Adres Bilgileri</h4>
        <h6 style={{ opacity: "0.9" }}>
          Bu bilgiler yeni üyelik kaydı basılırsa kartın arkasına yazılacaktır.
        </h6>
      </div>
    ),
  },
  {
    target: ".card_price",
    disableBeacon: true,
    content: (
      <div>
        <h4>Kart Ücreti</h4>
        <h6 style={{ opacity: "0.9" }}>
          Her yeni üye kaydında ve her kart numarası değişiminde yeni bir kart
          oluşturulduğu varsayıldığı için burada belirlenmiş olan miktar{" "}
          <strong>Ödemeler</strong> tablosunda kart ücreti olarak
          gösterilecektir.
        </h6>
      </div>
    ),
  },
  {
    target: ".ticket_price",
    disableBeacon: true,
    content: (
      <div>
        <h4>Bilet Ücreti</h4>
        <h6 style={{ opacity: "0.9" }}>
          Tek kullanımlık bilet için kesilecek ücret buradan belirlenir.
        </h6>
      </div>
    ),
  },
  {
    target: ".season_capacity",
    disableBeacon: true,
    content: (
      <div>
        <h4>Seans Kapasitesi</h4>
        <h6 style={{ opacity: "0.9" }}>
          Eğer tesiste seans tanımlandıysa ve tesis içerisinde burada belirtilen
          sayı kadar insan varsa bir sonraki üyenin tesise girişi engellenir.
        </h6>
      </div>
    ),
  },
  {
    target: ".reset_credits",
    disableBeacon: true,
    content: (
      <div>
        <h4>Kredi Sıfırlama</h4>
        <h6 style={{ opacity: "0.9" }}>
          Kredi sıfırlama açılırsa, 1 sene boyunca tesise giriş yapmamış
          kullanıcıların kredileri sıfırlanır.
        </h6>
      </div>
    ),
  },
  {
    target: ".season_based",
    disableBeacon: true,
    content: (
      <div>
        <h4>Seans Dışı Girişleri Engelleme</h4>
        <h6 style={{ opacity: "0.9" }}>
          Seans dışı girişler engellenecekse <strong>Seanslar</strong>{" "}
          menüsünden seans eklenmesi gerekir.
        </h6>
        <h6 style={{ opacity: "0.9" }}>
          Seans dışı girişler engellenmiyorsa <strong>Seanslar</strong>{" "}
          menüsünden seans eklenemez.
        </h6>
      </div>
    ),
  },
  {
    target: ".appointment_based",
    disableBeacon: true,
    content: (
      <div>
        <h4>Seans Dışı Girişleri Engelleme</h4>
        <h6 style={{ opacity: "0.9" }}>
          Randevu dışı girişler engellenirse randevusu olmayan kullanıcı tesise
          giriş yapamaz.
        </h6>
      </div>
    ),
  },
  {
    target: ".facility_general_info",
    disableBeacon: true,
    content: (
      <div>
        <h4>Çalışma Saatleri ve Kapasite</h4>
        <h6 style={{ opacity: "0.9" }}>
          Buradaki bilgiler üyelerin kullandığı mobil ve web uygulamalarda
          üyelere gösterilecektir.
        </h6>
      </div>
    ),
  },
];
export default steps;
