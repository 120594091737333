import React from "react";
import maleFront from "../assets/card-templates/male/male_front.png";
import maleBack from "../assets/card-templates/male/male_back.png";
import femaleFront from "../assets/card-templates/female/female_front.png";
import femaleBack from "../assets/card-templates/female/female_back.png";
import clubFront from "../assets/card-templates/club/club_front.png";
import clubBack from "../assets/card-templates/club/club_back.png";
import { Typography } from "antd";
import { API_URL } from "../config";
import { responseControl } from "../assets/scripts";

const { Text, Title, Paragraph, Link } = Typography;

const CardTemplate = React.forwardRef((props, ref) => {
  const [whereIs, setWhereIs] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/whereis`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        return setWhereIs(result);
      })

      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/photos/${props?.data?.card_id}?url=true`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {
        setPhoto(result?.photo);
      })
      .catch((error) => console.log(error));
  }, [props]);

  return (
    <div ref={ref}>
      <div
        style={{
          minWidth: "100%",
          minHeight: "100%",
          backgroundImage: `url(${
            props?.data?.clubs[0] &&
            props?.data?.clubs[0]?.club_name?.length > 0
              ? clubFront
              : props?.data?.gender === "male"
              ? maleFront
              : femaleFront
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontFamily: "'Rubik', sans-serif",
        }}
      >
        <div
          style={{
            paddingTop: "3%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10%",
            }}
          >
            <Text
              style={{
                fontSize: 8,
                marginLeft: "24%",
                fontWeight: "bold",
                color: "red",
              }}
            >
              T.C. GENÇLİK VE SPOR BAKANLIĞI
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "90%",
            }}
          >
            <div
              style={{
                width: 260,
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={API_URL + "/" + photo}
                style={{
                  marginLeft: "43%",
                  marginBottom: "6%",
                  borderRadius: "300%",
                  width: 140,
                  height: 140,
                }}
              />
            </div>
            <div
              style={{
                width: 240,
                height: 200,
                paddingTop: "10%",

                display: "flex",
                flexDirection: "column",
                textAlign: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "9%",
                  textAlign: "start",
                  width: "75%",
                  height: "80%",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                  }}
                >
                  {props?.data?.tc_no}
                  <br></br>
                  <span
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {props?.data?.first_name.toUpperCase()}{" "}
                    {props?.data?.last_name.toUpperCase()}
                  </span>
                  <br></br>
                  <span
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {new Date(props?.data?.birthdate).toLocaleDateString(
                      "fr-FR"
                    )}
                  </span>
                  <br></br>
                  <span
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {props?.data?.clubs?.club_name?.length > 0
                      ? props?.data?.clubs?.club_name?.toUpperCase()
                      : ""}
                  </span>
                </Text>
              </div>
              <div
                style={{
                  height: "20%",

                  textAlign: "end",
                  paddingRight: "26%",
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: "5px",
                  }}
                >
                  {whereIs[0]?.province} Olimpik Yüzme Havuzu
                </Text>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",

              textAlign: "start",
              paddingLeft: "38%",
            }}
          >
            <Text
              style={{
                fontSize: 8,
                padding: "5px",
              }}
            >
              ÜYE ID: {props?.data?.id}
            </Text>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "100%",
          minHeight: "100%",
          backgroundImage: `url(${
            props?.data?.clubs?.club_name?.length > 0
              ? clubBack
              : props?.data?.gender === "male"
              ? maleBack
              : femaleBack
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          fontFamily: "'Rubik', sans-serif",
          paddingTop: "30%",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            marginBottom: "10%",
            paddingLeft: "42%",
          }}
        >
          <Text
            style={{
              fontSize: 8,
              color: "red",
              textAlign: "start",
            }}
          >
            T.C. GENÇLİK VE SPOR BAKANLIĞI
          </Text>
        </div>
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingRight: "5%",
          }}
        >
          <ul
            style={{
              fontSize: 8,
            }}
          >
            <li>
              Bu kart T.C. Gençlik ve Spor Bakanlığı tesis girişi için
              kullanılır.
            </li>
            <li>Kart misafir dışında başkası tarafından kullanılamaz.</li>
            <li>
              Bu kartın bulunması halinde {whereIs[0]?.phone} numaralı telefona
              haber verilmesi veya {whereIs[0]?.address} {whereIs[0]?.district}/
              {whereIs[0]?.province} adresine ulaştırılması rica olunur.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default CardTemplate;
