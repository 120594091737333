import { API_URL } from "../config";
import React from "react";
import CTable from "../components/CTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const initialColumns = [
  {
    title: "Kart Sahibi Adı",
    dataIndex: ["member", "first_name"],
    key: "card_owner_name",
    responsive: ["xs", "md", "lg"],

    defaultSelected: true,
  },
  {
    title: "Kart Sahibi Soyadı",
    dataIndex: ["member", "last_name"],
    key: "card_owner_last_name",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "Kimlik Numarası",
    dataIndex: "member",
    key: "national_id",
    render: (item, row, index) => {
      if (item) {
        if (item?.length === 0) {
          return null;
        } else {
          return `${parseInt(item?.tc_no)}`;
        }
      }
    },
    defaultSelected: true,
  },
  {
    title: "Kart No",
    dataIndex: "card_number",
    key: "card_number",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Bakiye",
    dataIndex: "credits",
    key: "credits",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: true,
  },
  {
    title: "Talep Tarihi",
    dataIndex: "createdAt",
    key: "createdAt",
    defaultSelected: true,
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}{" "}
          {new Date(item)
            .toLocaleTimeString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "Son Değişiklik Tarihi",
    dataIndex: "updatedAt",
    key: "updatedAt",
    defaultSelected: true,
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}{" "}
          {new Date(item)
            .toLocaleTimeString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
];

const Cards = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/cards?include=member,facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="KARTLAR"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="card"
        isLoading={isLoading}
      />
      ;
    </ErrorBoundary>
  );
};

export default Cards;
