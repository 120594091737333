import { API_URL } from "../config";
import React from "react";
import { Button, Tag } from "antd";
import CTable from "../components/CTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import ListMembersCell from "../components/ListMembersCell";



const Rules = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [all, setAll] = React.useState(false);
  const [modalMemberListInfo, setModalMemberListInfo] = React.useState({ data: null, open: false });

  React.useEffect(() => {

    const initialColumns = [
      {
        title: "Başlık",
        dataIndex: "name",
        key: "name",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        width: 150,
      },

      {
        title: "Mesaj",
        width: 150,
        dataIndex: "rule",
        key: "rule",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Üyeler",
        width: 150,
        dataIndex: "members",
        key: "members",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        render: (members) => {
          return members?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setAll(true)
                  setModalMemberListInfo({
                    open: true,
                    data: members,
                  });
                }}
              >
                Üyeleri görüntüle
              </Button>
            </div>
          )

        },
      },

    ];

    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/rules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.ok && response?.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="TESİS KURALLARI"
        excel={false}
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        isLoading={isLoading}
        type={"rule"}

      />
      <ListMembersCell
        datasource={modalMemberListInfo.data}
        all={all}
        modalInfo={modalMemberListInfo}
        setModalInfo={setModalMemberListInfo}
        setAll={setAll}
      />

    </ErrorBoundary>
  );
};

export default Rules;
