import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Radio,
  Upload,
  Tooltip,
  InputNumber,
  DatePicker,
  TimePicker,
} from "antd";
import { API_URL } from "../config";
import { responseControl } from "../assets/scripts";
import { UploadOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player/lazy";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import moment from "moment";

const Terminal = () => {
  const [systemData, setSystemData] = React.useState({});
  const [currentSystem, setCurrentSystem] = React.useState({});
  const [systemID, setSystemID] = React.useState("");
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  const [isVideoChanged, setVideoChanged] = React.useState(false);
  const [videoWillSend, setVideoWillSend] = React.useState("");
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/system_datas`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {
        setSystemID(result[0]?.id);
        const { working_hours } = result[0];
        if (
          Object.prototype.toString.call(
            new Date(working_hours?.split(" ")[0])
          ) === "[object Date]"
        ) {
          const momentWorkingHours = working_hours && [
            moment(working_hours?.split(" ")[0], "HH:mm:ss"),
            moment(working_hours?.split(" ")[1], "HH:mm:ss"),
          ];
          delete result[0].working_hours;
          result[0].working_hours = momentWorkingHours;
        } else {
          delete result[0].working_hours;
          result[0].working_hours = null;
        }
        setCurrentSystem(result[0]);
        if (result[0]?.id === undefined) {
          setIsUpdate(false);
        } else {
          setIsUpdate(true);
        }
        form.setFieldsValue(result[0]);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (event) =>
    setSystemData({ ...systemData, [event.target.name]: event.target.value });

  const handleClick = () => {
    return fetch(`${API_URL}/api/v1/system_datas`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
          where: { id: systemID },
          body: systemData,
        })
        : JSON.stringify({
          ...systemData,
        }),
    })
      .then((response) => responseControl(response))
      .then(() => window.location.reload())
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleVideoChange = (info) => {
    setVideoChanged(true);
    getBase64(info.file.originFileObj, (imageUrl) =>
      setVideoWillSend(imageUrl)
    );
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "video/mp4";
    if (!isJpgOrPng) {
      message.error("Sadece MP4 formatında dosyaları yükleyebilirsiniz!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 150;
    if (!isLt2M) {
      message.error("Video boyutu en fazla 150MB olabilir!");
      return Upload.LIST_IGNORE;
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <h2 style={{ margin: "1em", fontWeight: "bold" }}>TESİS BİLGİLERİ</h2>
      <Card style={{ margin: "1%" }}>
        <Row gutter={24}>
          <Col md={12}>
            <Form
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 14,
              }}
              form={form}
              footer={[
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {isUpdate ? (
                    <Button
                      type="primary"
                      onClick={handleClick}
                    >
                      Güncelle
                    </Button>

                  ) :
                    <Button
                      type="primary"
                      onClick={handleClick}
                    >
                      Kaydet
                    </Button>
                  }
                </div>
              ]}
            >
              {/*               <Form.Item label="Uygulama Anahtarı" name="app_id">
                <Input name="app_id" onChange={handleChange} />
              </Form.Item> */}
              <div className="facility_address">
                <Form.Item label="Tesis Adı" name="facility_name">
                  <Input name="facility_name" onChange={handleChange} style={{ textTransform: "uppercase" }} />
                </Form.Item>
                <Form.Item label="İl" name="province">
                  <Input name="province" onChange={handleChange} />
                </Form.Item>
                <Form.Item label="İlçe" name="district">
                  <Input name="district" onChange={handleChange} />
                </Form.Item>
                <Form.Item label="Adres" name="address">
                  <Input name="address" onChange={handleChange} />
                </Form.Item>
                <Form.Item label="Telefon" name="phone">
                  <Input name="phone" onChange={handleChange} />
                </Form.Item>
              </div>

              <Form.Item
                label="Kredi Sıfırlama"
                name="reset_credits"
                className="reset_credits"
              >
                <Radio.Group name="reset_credits" onChange={handleChange}>
                  <Radio value={true}>Açık</Radio>
                  <Radio value={false}>Kapalı</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Randevu dışı girişler engellensin"
                name="appointment_based"
                className="appointment_based"
              >
                <Radio.Group name="appointment_based" onChange={handleChange}>
                  <Radio value={true}>Evet</Radio>
                  <Radio value={false}>Hayır</Radio>
                </Radio.Group>
              </Form.Item>
              <div className="facility_general_info">
                <Form.Item label="Çalışma Saatleri" name="working_hours">
                  <TimePicker.RangePicker
                    format="HH:mm:ss"
                    onChange={(date, dateIndex) =>
                      handleChange({
                        target: {
                          name: "working_hours",
                          value: dateIndex.join(" "),
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Kapasite (Kişi)" name="capacity">
                  {/*prettier-ignore*/}
                  <InputNumber style={{ width: "53.5%" }} name="capacity" onChange={(value) => handleChange({ target: { name: "capacity", value } })} addonAfter="Kişi" />
                </Form.Item>
              </div>

              <Form.Item wrapperCol={{
                offset: 8,
                span: 16,
              }}>

                {isUpdate ? (
                  <Button
                    type="primary"
                    onClick={handleClick}
                  >
                    Güncelle
                  </Button>

                ) :
                  <Button
                    type="primary"
                    onClick={handleClick}
                  >
                    Kaydet
                  </Button>
                }
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </ErrorBoundary>
  );
};

export default Terminal;
