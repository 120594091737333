import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const initialColumns = [
  {
    title: "Ad Soyad",
    dataIndex: "full_name",
    key: "full_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },

  {
    title: "TCKN",
    dataIndex: "tc_no",
    key: "tc_no",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "E-posta",
    dataIndex: "email",
    key: "email",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "Adres",
    dataIndex: "address",
    key: "address",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
  },
  {
    title: "Oluşturma Tarihi",
    dataIndex: "createdAt",
    key: "createdAt",
    defaultSelected: true,
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "Son Değişiklik Tarihi",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    render: (item, row, idx) => (
      <TableActions
        type="trainer"
        onClick={(event) => deleteRecord(`id=${row.id}`, "trainers")}
      />
    ),
    fixed: "right",
  },
];

const Trainers = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/trainers?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="ANTRENÖRLER"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="trainer"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Trainers;
