import { API_URL } from "../config";
import React from "react";
import { Button, Form, Input, Modal, Select, notification, Col } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const DevicesModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [schedules, setSchedules] = React.useState([]);

  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
    }
    getSchedules()
      .then((result) => setSchedules(result))
      .catch((error) => console.log(error));
  }, [props]);

  const getSchedules = async () => {
    return await fetch(`${API_URL}/api/v1/schedules`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    if (fields.device_id && fields.ip) {
      return await fetch(`${API_URL}/api/v1/devices`, {
        method: isUpdate ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: isUpdate
          ? JSON.stringify({
              body: { ...fields, schedule_id: fields.schedule_id || null },
              where: { id: props.record.id },
            })
          : JSON.stringify({
              ...fields,
              schedule_id: fields.schedule_id || null,
            }),
      })
        .then((response) => responseControl(response))
        .then(() => window.location.reload())
        .catch((error) => console.log(error));
    } else {
      return notification.warning({
        message: "Cihaz ID ve IP gerekli alanlardır",
      });
    }
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={null}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item
          label="Cihaz ID"
          name="device_id"
          rules={[
            {
              required: true,
              warningOnly: false,
              type: "string",
              whitespace: true,
            },
            () => ({
              async validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error("Lütfen alanı doldurunuz!"));
                }
              },
            }),
          ]}
          validateTrigger="onBlur"
        >
          <Input name="device_id" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Cihaz Adı" name="device_name">
          <Input name="device_name" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Lokasyon" name="location">
          <Input name="location" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="IP"
          name="ip"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              warningOnly: false,
              type: "string",
              whitespace: true,
            },
            () => ({
              async validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error("Lütfen alanı doldurunuz!"));
                }
              },
            }),
          ]}
        >
          <Input name="ip" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Türü" name="type">
          <Input name="type" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Yönü" name="direction">
          <Select
            showArrow
            onSelect={(value) =>
              handleChange({ target: { name: "direction", value } })
            }
          >
            <Option value="enterance">Giriş</Option>
            <Option value="exit">Çıkış</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tarife" name="schedule_id">
          <Select
            allowClear
            showArrow
            onSelect={(value) =>
              value && handleChange({ target: { name: "schedule_id", value } })
            }
          >
            {typeof schedules?.map === "function"
              ? schedules?.map((schedule) => {
                  const schedulename =
                    schedule.schedule_name.charAt(0).toUpperCase() +
                    schedule.schedule_name.slice(1);
                  return (
                    <>
                      <Option value={schedule.id}>{schedulename}</Option>
                    </>
                  );
                })
              : null}
          </Select>
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-start",
              gap: 20,
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
            >
              Kaydet
            </Button>

            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => props.setVisible(false)}
            >
              {props.type === "request" ? "Reddet" : "İptal"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DevicesModal;
