import React from 'react'
import ReportSelection from '../components/ReportSelection'
import ReportTimeOptions from '../components/ReportTimeOptions'
import { Form } from 'antd';
import { API_URL } from '../config';
import moment from "moment";

export default function Reports() {
    const [form] = Form.useForm();
    const [reports, setReports] = React.useState([]);
    const [range, setRange] = React.useState({ start: null, end: null });

    const getReports = () => {
        const formData = form.getFieldsValue();
        console.log({ formData });
        let reportRange = null;
        let start = null;
        let end = null;
        if (formData.type_picker === "period") {
            const currentDate = moment();
            end = moment().format("YYYY-MM-DD");

            switch (formData.period) {
                case "weekly":
                    start = moment(currentDate).subtract(1, 'week').format('YYYY-MM-DD');
                    break;
                case "monthly":
                    start = moment(currentDate).subtract(1, 'month').format('YYYY-MM-DD');
                    break;
                case "yearly":
                    start = moment(currentDate).subtract(1, 'year').format('YYYY-MM-DD');
                    break;
                default:
                    start = end;
                    break;
            }

        } else {
            start = formData.start_date.format("YYYY-MM-DD");
            end = formData.end_date.format("YYYY-MM-DD");
        }

        reportRange = "?start=" + start + "&end=" + end;
        setRange({ start, end })

        return fetch(`${API_URL}/api/v1/reports` + reportRange, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => {
                if (response.ok) return response.json();
            })
            .then((res) => {
                setReports(res)
            })
            .catch(e => console.log("ERROR ", e))

    }


    return (
        <div className='flex' style={{ marginTop: "40px", marginLeft: "20px", justifyContent: "space-between", alignItems: "flex-start" }}>
            <ReportTimeOptions form={form} getReports={getReports} />

            <ReportSelection form={form} reports={reports} start={range.start} end={range.end} />


        </div>
    )
}
