import { Form, Select, DatePicker, Radio, Button } from 'antd';
import React from 'react';

export default function ReportTimeOptions({ form, getReports }) {
    const [type, setType] = React.useState("period")


    return (
        <Form form={form} layout='vertical' name="report_time_options"
            initialValues={{
                period: "daily",
                type_picker: "period"
            }}
        >
            <h2 style={{ fontWeight: "bold", }}>GENEL RAPORLAR</h2>
            <Form.Item name="type_picker">
                <Radio.Group value={type} buttonStyle="solid" onChange={(v) => {
                    setType(v.target.value)

                }}
                    style={{ marginBottom: "20px" }}
                >
                    <Radio.Button value="period">Dönem</Radio.Button>
                    <Radio.Button value="range">Tarih Aralığı</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {
                type === "period" ? <><Form.Item
                    label="Frekans"
                    name="period"
                >
                    <Select >
                        {
                            [{ value: "daily", label: "Günlük" }, { value: "weekly", label: "Haftalık" }, { value: "monthly", label: "Aylık" }, { value: "yearly", label: "Senelik" }].map(d => {
                                return (<Select.Option value={d.value}>
                                    {d.label}
                                </Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item></>
                    :

                    <> <Form.Item
                        name="start_date"
                        label="Başlangıç Tarihi"
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>

                        <Form.Item
                            name="end_date"
                            label="Bitiş Tarihi"
                        >
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </>
            }

            <Form.Item
            >
                <Button type='primary' onClick={getReports}>Raporları oluştur</Button>
            </Form.Item>
        </Form >
    )
}
