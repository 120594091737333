import { API_URL } from "../../config";
import React from "react";
import { Pie } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";
import moment from "moment";

const NewMembersChart = (props) => {
  var config = {
    appendPadding: 10,

    angleField: "count",
    colorField: "gender",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content(_ref) {
        var percent = _ref.percent;
        return "".concat((percent * 100).toFixed(0), "%");
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };

  const [members, setMembers] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/members`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((logs) => setMembers(logs))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let memberByGenders = {};
    if (members?.length > 0) {
      members.map((member) => {
        //prettier-ignore
        const createdAt = moment(new Date(member?.createdAt)).format("YYYY-MM")
        if (member?.gender && createdAt === props.date) {
          const gender = member.gender;

          if (memberByGenders.hasOwnProperty(gender)) {
            memberByGenders[gender] = memberByGenders[gender] + 1;
          } else {
            memberByGenders[gender] = 1;
          }
        }
        const arr = [];
        Object.keys(memberByGenders).map((key) => {
          arr.push({
            gender:
              key === "male"
                ? "Erkek"
                : key === "female"
                ? "Kadın"
                : "Belirtilmemiş",
            count: memberByGenders[key],
          });
        });

        setChartData(arr);
        setLoading(false);
      });
    }
  }, [members]);

  return <Pie data={chartData} {...config} loading={loading} />;
};

export default NewMembersChart;
