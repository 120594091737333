import { API_URL } from "../../config";
import React from "react";
import { Bar, Column, Line } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";
import moment from "moment";

const DailyActivities = (props) => {
  const config = {
    appendPadding: [30, 0],
    xField: "day",
    yField: "value",
    seriesField: "value",
    label: {
      position: "top",
      style: {
        fontSize: 20,
        fill: "#00000",
        opacity: 10,
        fontWeight: "bolder",
      },
    },
    point: {
      size: 5,
      shape: "diamond | circule",
    },
  };

  const [logs, setLogs] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((result) => setLogs(result))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let dailyLogs = {};
    const activities = [];
    const daysOfMonth = new Date(
      moment(props.date).format("YYYY"),
      moment(props.date).format("MM"),
      0
    ).getDate();
    for (let index = 1; index <= daysOfMonth; index++) {
      dailyLogs[index] = 0;
    }

    if (logs?.length > 0) {
      logs.reverse().map((log) => {
        //prettier-ignore
        const logDate = moment(new Date(log.login)).format("YYYY-MM");
        if (logDate === props.date) {
          const logDay = parseInt(moment(log.login).format("DD"));

          dailyLogs[`${logDay}`] += 1;
        }
      });
      Object.keys(dailyLogs).map((key) =>
        //prettier-ignore
        activities.push( { day: key === "male" ? "Erkek" : key === "female" ? "Kadın" : key, value: dailyLogs[key] })
      );
      setChartData(activities);
    }
  }, [logs]);

  return (
    <div style={{ marginTop: "5%" }}>
      <Column data={chartData} {...config} />
    </div>
  );
};

export default DailyActivities;
