import { API_URL } from "../config";
import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
} from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const ClubPayment = (props) => {
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.resetFields();
    props.paymentModal?.item &&
      form.setFieldsValue({ amount: props.paymentModal.item.cost });
  }, [props.paymentModal, form]);

  const handleClick = async () => {
    const fields = form.getFieldsValue();

    let { pan, year, month, cvv, amount } = fields;
    pan = pan.split(" ").join("");
    if (pan && year && month && cvv && amount) {
      console.log("?? ", props.paymentModal.item.id);
      return await fetch(`${API_URL}/api/v1/pay-pool-usage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...fields,
          pan,
          request_id: props.paymentModal.item.id,
        }),
      })
        .then((response) => response.text())
        .then((res) => {
          var frog = window.open("", "_blank");
          frog.document.open();
          frog.document.write(res);
          frog.document.close();
        })
        .catch((error) => console.log(error));
    } else {
      notification.error({ message: "Kırmızı yıldızlı alalnları doldurunuz!" });
    }
  };

  React.useEffect(() => {
    if (props.paymentModal.open) {
      document.getElementById("iban").addEventListener("input", function (e) {
        e.target.value = e.target.value
          .replace(/[^\dA-Z]/g, "")
          .replace(/(.{4})/g, "$1 ")
          .trim();

        form.setFieldsValue({ pan: e?.target?.value });
      });
    }
  }, [props.paymentModal, form]);

  return (
    <Modal
      width={690}
      title={
        <h3 style={{ fontWeight: "bold", padding: "10px" }}>
          Spor kulübü havız kullanım talepi ödeme formu
        </h3>
      }
      visible={props.paymentModal.open}
      onCancel={() => props.setPaymentModal({ open: false, item: null })}
      onOk={() => props.setPaymentModal({ open: false, item: null })}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: " 1%" }} {...layout} form={form}>
        <Form.Item
          name="amount"
          label="Ödenecek tutar ( TL )"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            placeholder="örnek: 500"
            style={{ width: 100 }}
          />
        </Form.Item>

        <Form.Item
          name="pan"
          label="Kart numarası"
          rules={[{ required: true }]}
        >
          <Input
            id="iban"
            maxLength={16}
            placeholder="örnek: 0000000000000000"
            style={{ width: 220 }}
          />
        </Form.Item>
        <Form.Item name="month" label="Ay" rules={[{ required: true }]}>
          <Input maxLength={2} placeholder="örnek: 02" style={{ width: 100 }} />
        </Form.Item>

        <Form.Item
          name="year"
          label="Yıl ( son 2 hanesini giriniz )"
          rules={[{ required: true }]}
        >
          <Input
            maxLength={2}
            placeholder={
              "örnek: " + new Date().getFullYear().toString()?.substring(2)
            }
            style={{ width: 100 }}
          />
        </Form.Item>

        <Form.Item name="cvv" label="CVC" rules={[{ required: true }]}>
          <Input
            maxLength={3}
            placeholder={"örnek: 000"}
            style={{ width: 100 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClubPayment;
