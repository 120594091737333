import { API_URL } from "../config";
import {
  SearchOutlined,
  DownloadOutlined,
  CloseOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Select,
  Button,
  Typography,
  DatePicker,
  notification,
} from "antd";
import React from "react";
import CTable from "../components/CTable";
import path from "path";
import { deleteRecord, responseControl } from "../assets/scripts";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

const { Option } = Select;
const Files = () => {
  const [fileURL, setFileURL] = React.useState({});
  const [dataSource, setDataSource] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [selectedTimeout, setSelectedTimeout] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const columns = [
    {
      title: "Ad",
      dataIndex: "first_name",
      key: "first_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Soyad",
      dataIndex: "last_name",
      key: "last_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Tesis",
      dataIndex: ["facility", "name"],
      key: "facility",
      defaultSelected: true,
    },
    {
      title: "Dosyalar",
      dataIndex: "file",
      key: "file",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],

      render: (item, row) => {
        if (item?.length === 0 || item === null) {
          return "Kayıtlı dosya yok";
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Select
                onSelect={(value) => handleSelect(value, row)}
                style={{ width: 200 }}
              >
                {row?.files?.length > 0
                  ? row.files.map((file) => (
                    <Option value={file.file_path}>{file.file_name}</Option>
                  ))
                  : item.split(",").map((file) => {
                    if (file !== "null") {
                      return (
                        <Option value={file}>{path.basename(file)}</Option>
                      );
                    }
                  })}
              </Select>

              <Button
                icon={<SearchOutlined />}
                type="primary"
                onClick={() => setVisible(true)}
                style={{ marginLeft: 5 }}
              >
                Önizle
              </Button>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                onClick={() => getFile(selectedFile, "download")}
                style={{ marginLeft: 5 }}
              >
                İndir
              </Button>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() =>
                  deleteRecord(
                    `file_path=${selectedFile}&owner_id=${row.id}`,
                    "file"
                  )
                }
                style={{ marginLeft: 5 }}
                danger
              >
                Sil
              </Button>
            </div>
          );
        }
      },
    },

    {
      title: "Dosya Zamanaşımı",
      dataIndex: "file_timeout",
      key: "file_timeout",
      responsive: ["xs", "md", "lg"],

      render: (item, row) => {
        return (
          <div>
            <DatePicker
              key={row.id}
              //prettier-ignore
              value={row.id === selectedRow.id ? selectedTimeout ? moment(selectedTimeout) : selectedRow.timeout ? moment(selectedRow.timeout) : null : null}
              //prettier-ignore
              style={{ marginLeft: 5 }}
              format="DD-MM-YYYY"
              //prettier-ignore
              onChange={(date, dateIndex) => setSelectedTimeout(dateIndex.split("-").reverse().join("-"))}
            />
            <Button
              icon={<ClockCircleOutlined />}
              type="primary"
              //prettier-ignore
              onClick={() => handleSetTimeout({ body: { timeout: selectedTimeout }, where: { file_path: selectedFile } })}
              style={{ marginLeft: 5 }}
            >
              Zamanaşımı tarihi ayarla
            </Button>
          </div>
        );
      },
      defaultSelected: true,
    },
  ];

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/members?include=file,facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        let arr = [];
        result.map((data) => {
          if (data?.file?.length > 0) {
            arr.push(data);
          }
        });
        setDataSource(arr);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleSelect = (value, record) => {
    getFile(value, "preview");
    const selectedFileObject = record.files.filter(
      (object) => object.file_path === value
    );
    setSelectedTimeout(selectedFileObject[0]?.timeout);
    setSelectedFile(value);
    //prettier-ignore
    setSelectedRow({ file: value, id: record.id, timeout: record.files.filter(obj => obj.file_path === value)[0]?.timeout })
  };

  const handleSetTimeout = async (params) => {
    return await fetch(`${API_URL}/api/v1/files`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => responseControl(response))
      .then((result) => {
        if (result === 0) {
          return notification.error({
            message: "Güncelleme yapılamadı",

            style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
          });
        } else {
          return notification.success({
            message: `Zamanaşımı tarihi ${selectedTimeout} olarak güncellendi.`,
            style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
          });
        }
      })
      .then(() => window.location.reload())
      .catch((error) =>
        error instanceof Error
          ? console.log(error.message)
          : typeof error === "string"
            ? console.log(error)
            : console.log(error)
      );
  };

  const getFile = async (fileName, action) => {
    return await fetch(`${API_URL}/api/v1/files?file_name=${fileName}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (action === "preview") {
          setFileURL(result);
        } else if (action === "download") {
          const linkSource = `data:application/pdf;base64,${result?.contents}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = path.basename(fileName);
          downloadLink.click();
        }
      })
      .catch((error) => console.log({ getFileError: error }));
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <>
        <CTable
          title="DOSYALAR"
          isLoading={isLoading}
          dataSource={dataSource}
          columns={columns}
          type="file"
          newRecords={false}
          excel={false}
          scrollX={3500}
        />
        <Modal
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
          style={{ marginBottom: 100 }}
          closeIcon={
            <div
              style={{
                borderRadius: "100%",
                backgroundColor: "red",
                marginTop: 10,
                marginLeft: 5,
                width: 30,
                height: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#ffff",
              }}
            >
              <CloseOutlined />
            </div>
          }
        >
          {fileURL?.extName === ".pdf" ? (
            <object
              data={"data:application/pdf;base64," + fileURL?.contents}
              type="application/pdf"
              style={{ minHeight: "100vh", width: "100%", marginTop: 25 }}
            ></object>
          ) : fileURL?.extName === ".plain" ? (
            <Typography style={{ marginTop: 25 }}>
              <Typography.Paragraph>
                {b64_to_utf8(fileURL?.contents)}
              </Typography.Paragraph>
            </Typography>
          ) : (
            ""
          )}
        </Modal>
      </>
    </ErrorBoundary>
  );
};

export default Files;
