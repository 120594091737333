import { API_URL } from "../config";
import React from "react";
import CTable from "../components/CTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import moment from "moment";
const initialColumns = [
  {
    title: "TC Kimlik No",
    dataIndex: "tc_no",
    key: "tc_no",
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Ad Soyad",
    dataIndex: "name_ticket",
    key: "name_ticket",
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Kullanıldı",
    dataIndex: "is_used",
    key: "is_used",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item) => (item ? "Evet" : "Hayır"),
    filters: [
      {
        text: "Kullanılanlar",
        value: true,
      },
      {
        text: "Kullanılmayanlar",
        value: false,
      },
    ],
    onFilter: (value, record) => record.is_used === value,
  },
  {
    title: "Ücret",
    dataIndex: "amount",
    key: "amount",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Kullanılma Zamanı",
    dataIndex: "used_time",
    key: "used_time",
    responsive: ["xs", "md", "lg"],

    render: (item) =>
      item ? moment(new Date(item)).format("DD/MM/YYYY HH:mm:ss") : null,
    defaultSelected: true,
  },
  {
    title: "Bileti Kesen",
    dataIndex: ["user", "username"],
    key: "user",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tipi",
    dataIndex: "is_direct",
    key: "is_direct",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item) => (item ? "Direk Geçiş" : "TC No İle"),
    filters: [
      {
        text: "Direk Geçişler",
        value: true,
      },
      {
        text: "TC Kimlik İle Geçişler",
        value: false,
      },
      {
        text: "Ad Soyad İle Geçişler",
        value: false,
      },
    ],
    onFilter: (value, record) => record.is_direct === value,
  },
  {
    title: "Alınma Tarihi",
    dataIndex: "createdAt",
    key: "createdAt",
    responsive: ["xs", "md", "lg"],

    render: (item) =>
      item ? moment(new Date(item)).format("DD/MM/YYYY HH:mm:ss") : null,
    defaultSelected: true,
  },
];

const Tickets = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/tickets`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        const setIsInside = async () =>
          Promise.all(
            result.map((record) => {
              if (record.logout === null) {
                record.isInside = true;
              } else {
                record.isInside = false;
              }
            })
          );
        setIsInside().then(() => setDataSource(result));
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="BİLETLER"
        scrollX={3500}
        isLoading={isLoading}
        dataSource={dataSource}
        columns={columns}
        timeFilter={true}
        type="ticket"
      />
    </ErrorBoundary>
  );
};

export default Tickets;
