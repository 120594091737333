import { API_URL } from "../../config";
import React from "react";
import { Pie } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";

const PieChart = (props) => {
  var config = {
    appendPadding: [10, 40],
    angleField: "count",
    colorField: "hour",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content(_ref) {
        var percent = _ref.percent;
        return "".concat((percent * 100).toFixed(0), "%");
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
  };

  const [logs, setLogs] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((logs) => setLogs(logs))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let hourlyVisit = {};
    if (logs?.length > 0) {
      logs.map((log) => {
        const currentYear = new Date().getFullYear();

        const logYear = new Date(log.login).getFullYear();
        const logHour = new Date(log.login)
          .toLocaleTimeString("tr-TR")
          .split(":")[0];

        if (currentYear === logYear) {
          if (hourlyVisit.hasOwnProperty(logHour)) {
            hourlyVisit[logHour] = hourlyVisit[logHour] + 1;
          } else {
            hourlyVisit[logHour] = 1;
          }
        }
        const arr = [];

        Object.keys(hourlyVisit).map((key) => {
          arr.push({ hour: key + ":00", count: hourlyVisit[key] });
        });

        setChartData(arr);
      });
    }
  }, [logs]);

  return <Pie data={chartData} {...config} />;
};

export default PieChart;
