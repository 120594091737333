import { API_URL } from "../config";
import React from "react";
import { Form, Input, message, Modal } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16, offset: 2 },
};
const FacilitiesModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
      setIsUpdate(false);
    }
  }, [props]);

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    return await fetch(`${API_URL}/api/v1/facilitys`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
            body: fields,
            where: { id: props.record.id },
          })
        : JSON.stringify(fields),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result && !result.errors) {
          return window.location.reload();
        }
      })
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
          ? message.error(error)
          : console.log(error)
      );
  };

  return (
    <Modal
      okButtonProps={{
        form: "user-form",
        key: "submit",
        htmlType: "submit",
        icon: <SaveOutlined />,
        onClick: handleClick,
      }}
      cancelButtonProps={{
        form: "user-form",
        key: "cancel",
        danger: true,
        type: "primary",
        icon: <CloseCircleOutlined />,
      }}
      okText="Kaydet"
      cancelText={props.type === "request" ? "Reddet" : "İptal"}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
    >
      <Form
        style={{ margin: "5% 1%", padding: "5%" }}
        {...layout}
        form={form}
        id="user-form"
      >
        <Form.Item
          label="Tesis Adı"
          name="name"
          rules={[
            {
              required: !isUpdate,
              warningOnly: false,
              type: "string",
              whitespace: true,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input name="name" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Tesis id"
          name="gsb_facility_id"
          rules={[
            {
              required: true,
              warningOnly: false,
              type: "string",
              whitespace: true,
              message: "Lütfen tesis id giriniz",
            },
          ]}
        >
          <Input name="gsb_facility_id" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Adres" name="address">
          <Input.TextArea name="address" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="IP adresi" name="ip">
          <Input.TextArea name="ip" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="E-posta"
          name="email"
          rules={[
            {
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: "e-Posta formatı yanlış",
            },
          ]}
        >
          <Input type={"email"} name="email" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Telefon"
          name="phone"
          rules={[
            {
              pattern:
                /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/,
              message: "Telefon formatı yanlış veya sonuna boşluk var",
            },
          ]}
        >
          <Input name="phone" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FacilitiesModal;
