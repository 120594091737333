import { API_URL } from "../config";
import { Tag, Typography, message } from "antd";
import React from "react";
import verifyMember from "../assets/scripts/verifyMember";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import moment from "moment";


const Verifications = () => {

  const refuse = async (member_id, schedule, schedule_id, schedules) => {
    if (schedule === undefined || schedule === null) {
      return message.error("Tarifesi olmayan üyenin kaydı onaylanamaz.");
    }
    const filtredSchedules = schedules.filter(s => s !== schedule_id);

    return await fetch(`${API_URL}/api/v1/schedules?schedule_name=${schedule}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (scheduleData) => {
        await fetch(`${API_URL}/api/v1/verifications`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            where: { member_id, schedule_id, },
            body: { approved: false },
          }),
        })
        await fetch(`${API_URL}/api/v1/members`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            where: { id: member_id, },
            body: { schedule: filtredSchedules },
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then(() => window.location.reload()).catch(e => console.log(e))

      })
  };

  const initialColumns = [
    {
      title: "Adı",
      dataIndex: "first_name",
      key: "first_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Soyad",
      dataIndex: "last_name",
      key: "last_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Tarife",
      dataIndex: ["schedules", 0, "schedule_name"],
      responsive: ["xs", "md", "lg"],
      key: "schedule_name",
      render: (item, row, index) => {
        if (!item)
          return (
            <Typography.Text type="danger">Tarife girilmeli.</Typography.Text>
          );
        return item;
      },
      defaultSelected: true,
    },
    {
      title: "Tesis",
      dataIndex: ["facility", "name"],
      key: "facility",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Onay verme",
      responsive: ["xs", "md", "lg"],

      dataIndex: "registered",
      key: "registered",
      defaultSelected: true,

      render: (item) =>
        item === true ? (
          <Tag color="success">Kayıtlı</Tag>
        ) : (
          <Tag color="error">Kayıtlı değil</Tag>
        ),
    },
    {
      title: "İşlemler",
      dataIndex: "actions",
      key: "actions",
      defaultSelected: true,

      render: (item, row, idx) => {
        return (
          <TableActions
            disabled={row.schedule === null || row.schedule === undefined}
            disabledDate={(current) => {
              return current.isAfter(moment().add(1, "y"))
            }}
            type="verification"
            onClick={(expiryDate) =>
              verifyMember(row.id, row?.schedules[0]?.schedule_name, row.schedule, expiryDate)
            }
            onRefuse={
              () => refuse(row.id, row?.schedules[0]?.schedule_name, row.schedule, row?.member_schedules)
            }
            deleteButton={false}
          />
        )
      },
      fixed: "right",
    },
  ];

  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(
      `${API_URL}/api/v1/verifications`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="TARİFE ONAYLAMALARI"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="verification"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Verifications;
