import React from 'react';
import { TimePicker, Form, Input, Space, Button, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


export default function Sessions() {
  const days = [{ en: "Monday", tr: "Pazartesi" }, { en: "Tuesday", tr: "Salı" }, { en: "Wednesday", tr: "Çarşamba" }, { en: "Thursday", tr: "Perşembe" }, { en: "Friday", tr: "Cuma" }, { en: "Saturday", tr: "Cumartesi", }, { en: "Sunday", tr: "Pazar" }];


  return (
    <>
      <Form.Item label="Seanslar"  >

        <Form.List name="sessions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'day']}

                    >
                      <Select placeholder="Gün seçiniz..." style={{ width: 150 }}  >
                        {days.map(d => (<Select.Option value={d.en}>{d.tr}</Select.Option>))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'start_time']}
                    >
                      <TimePicker placeholder="Başlangıç saat" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'finish_time']}
                    >
                      <TimePicker placeholder="Bitiş saat" />
                    </Form.Item>


                    {<MinusCircleOutlined onClick={() => remove(name)} />}
                  </Space>
                )
              })}
              {<Form.Item>
                <Button type="primary" style={{ width: "120px", paddingLeft: "5px" }} onClick={() => {

                  return add()
                }} block icon={<PlusOutlined />}>
                  Seans ekle
                </Button>
              </Form.Item>}
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  )
}
