import { API_URL } from "../config";
import React from "react";
import { Button, Form, Input, Modal } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const TrainersModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
    }
  }, [props]);

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    return await fetch(`${API_URL}/api/v1/trainers`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
          body: { ...fields },
          where: { id: props.record.id },
        })
        : JSON.stringify({ ...fields }),
    })
      .then((response) => responseControl(response))
      .then((d) => {
        return typeof d === "object" && window.location.reload()
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Ad Soyad" name="full_name">
          <Input name="full_name" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="TCKN" name="tc_no">
          <Input name="tc_no" onChange={handleChange} />
        </Form.Item>

        <Form.Item label="Telefon" name="phone">
          <Input name="phone" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="E-posta" name="email">
          <Input name="email" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Adres" name="address">
          <Input name="address" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TrainersModal;
