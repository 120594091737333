import {
  DeleteOutlined,
  MinusCircleFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  ConfigProvider,
  notification,
  Row,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import responseControl from "../assets/scripts/responseControl";
import SeasonModal from "../components/SeasonModal";
import { API_URL } from "../config";
import ErrorFallback from "../helpers/ErrorFallback";

const Seasons = () => {
  const [visible, setVisible] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(null);
  const [currentSeasons, setCurrentSeasons] = React.useState([]);
  const [facilityId, setFacilityId] = React.useState(null);
  const [isSeasonBased, setIsSeasonBased] = React.useState(false);
  const [iconSize, setIconSize] = React.useState(20);
  const weekdays = [
    { en: "Monday", tr: "Pazartesi" },
    { en: "Tuesday", tr: "Salı" },
    { en: "Wednesday", tr: "Çarşamba" },
    { en: "Thursday", tr: "Perşembe" },
    { en: "Friday", tr: "Cuma" },
    { en: "Saturday", tr: "Cumartesi" },
    { en: "Sunday", tr: "Pazar" },
  ];

  React.useEffect(() => {
    return getSeasons();
  }, []);

  const getSeasons = () =>
    fetch(`${API_URL}/api/v1/system_datas`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => responseControl(response))
      .then((result) => {
        console.log(result[0]);
        setIsSeasonBased(result[0].season_based);
        setFacilityId(result[0].facilityId);
        return setCurrentSeasons(result[0].seasons);
      })
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );

  const handleModal = (dayName) => {
    setSelectedDay(dayName);
    setVisible(true);
  };

  const deleteSeason = async (idx) => {
    try {
      const seasons = [...currentSeasons];
      seasons.splice(idx, 1);
      const response = await fetch(`${API_URL}/api/v1/system_datas`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: { seasons },
          where: { facility_id: facilityId },
        }),
      });
      await response.json();
      return getSeasons();
    } catch (error) {
      notification.error({ message: error?.message || error });
    }
  };

  const makeSeasonBased = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/system_datas`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: { season_based: true },
          where: { facility_id: facilityId },
        }),
      });
      await response.json();
      return getSeasons();
    } catch (error) {
      notification.error({ message: error?.message || error });
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        return window.location.reload();
      }}
    >
      {!isSeasonBased ? (
        <Alert
          style={{ margin: "1%" }}
          message="Tesis Seans Bağımlı Değil"
          description={
            <div>
              <p>
                Bu tesis seans bağımlı değil. Dolayısıyla eğer seçilmiş seans ve
                seanslar varsa bunlar kart okumalara etki etmeyecektir.
              </p>
              <Button onClick={makeSeasonBased}>
                Tesisi Seans Bağımlı Yap
              </Button>
            </div>
          }
          type="error"
        />
      ) : (
        <>
          <Row style={{ margin: "1%" }}>
            {weekdays.map((weekday) => {
              return (
                <Col style={{ margin: 5 }} className="season-col">
                  <Card
                    title={<h1>{weekday.tr}</h1>}
                    bordered={false}
                    className="season-card"
                    style={{ width: 850, height: 800 }}
                    hoverable
                    onClick={() => handleModal(weekday.en)}
                  >
                    <ol style={{ fontSize: 18 }}>
                      {currentSeasons instanceof Array &&
                      currentSeasons.length > 0
                        ? currentSeasons.sort().map((season, idx) => {
                            if (
                              typeof season.split === "function" &&
                              season.split(" ")[0] === weekday.en
                            ) {
                              return (
                                <li>
                                  <Tag color="blue" style={{ fontSize: 18 }}>
                                    Başlangıç: {season.split(" ")[1]}
                                  </Tag>
                                  <Tag color="error" style={{ fontSize: 18 }}>
                                    Bitiş: {season.split(" ")[2]}
                                  </Tag>
                                  <Tooltip title="Seansı sil" trigger={"hover"}>
                                    <DeleteOutlined
                                      onMouseEnter={() => setIconSize(30)}
                                      onMouseLeave={() => setIconSize(20)}
                                      style={{ fontSize: iconSize }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        return deleteSeason(idx);
                                      }}
                                    />
                                  </Tooltip>
                                </li>
                              );
                            }
                          })
                        : null}
                    </ol>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <SeasonModal
            currentSeasons={currentSeasons}
            visible={visible}
            setVisible={setVisible}
            day={selectedDay}
            title={
              weekdays.filter((weekday) => weekday.en === selectedDay)[0]?.tr
            }
          />
        </>
      )}
    </ErrorBoundary>
  );
};

export default Seasons;
