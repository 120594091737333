import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import CTable from './CTable'
import ErrorFallback from '../helpers/ErrorFallback'
import { Button } from 'antd';
import * as XLSX from "xlsx";
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const exportExcel = (name, data, columns, start, end) => {

    const fileBuffer = data.file;
    const buffer = new Uint8Array(atob(fileBuffer).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([buffer]);
    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = name?.split(".x")?.[0] + data.type + ".xlsx";
    downloadLink.textContent = name?.split(".x")?.[0] + data.type + ".xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();

    URL.revokeObjectURL(blobUrl);

}

const exportPdf = (name, data) => {
    return pdfMake.createPdf(data.pdfDoc).download(name?.split(".x")?.[0] + data.type);
}

export default function ReportSelection(props) {


    const dataSource = props.reports;

    const columns = [{
        title: "Rapor Adı",
        dataIndex: "report_name",
        key: "report_name",
        defaultSelected: true,
    },
    {
        title: "İşlemler",
        dataIndex: "actions",
        key: "actions",
        defaultSelected: true,
        width: 250,
        render: (item, row, idx) => {
            if (row.report_name.includes("Zaman")) {
                return (<Button type='primary' onClick={() => exportExcel(row.report_name, row, columns, props.start, props.end)}>
                    Excel'e aktar
                </Button >)
            } else {
                return (<><Button type='primary' onClick={() => exportExcel(row.report_name, row, columns,)}>
                    Excel'e aktar
                </Button >
                    <Button style={{ marginLeft: "1em" }} type='primary' onClick={() => exportPdf(row.report_name, row, columns,)}>
                        PDF İndir
                    </Button >
                </>)
            }
        },
        fixed: "right",
    },
    ];

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <div style={{ width: "1400px" }} >

                <CTable

                    excel={false}
                    scrollX={3500}
                    dataSource={dataSource}
                    columns={columns}
                    type="reports"
                />
            </div>
        </ErrorBoundary>
    )
}
