import { API_URL } from "../config";
import React from "react";
import { Button, Divider, Form, Input, Modal, Typography, Space, Select } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const ClubsModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
      console.log(props.record, 'props.record');
    }

    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
    }
  }, [props]);

  const [districtLoading, setDistrictLoading] = React.useState(false);
  const [districts, setDistricts] = React.useState([]);
  const [district, setDistrict] = React.useState(null)
  React.useEffect(() => {

    setDistrictLoading(true);
    fetch(`${API_URL}/api/v1/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDistricts(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setDistrictLoading(false));
  }, [district]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [gsbClubs, setGsbClubs] = React.useState([]);
  React.useEffect(() => {

    setIsLoading(true);
    fetch(`${API_URL}/api/v1/gsbClubs/?district=${district}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setGsbClubs(result.clubs);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, [district]);

  const handleClick = async () => {
    const fields = { ...(form.getFieldsValue()) };
    const trainersList = form.getFieldsValue().users;
    delete fields.users;

    return await fetch(`${API_URL}/api/v1/clubs`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
          body: { ...fields, trainers: trainersList?.map(trainer => JSON.stringify(trainer)) },
          where: { id: props.record.id },
        })
        : JSON.stringify({ ...fields, trainers: trainersList?.map(trainer => JSON.stringify(trainer)) }),
    })
      .then((response) => responseControl(response))
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };
  const [searchedClub, setSearchedClub] = React.useState(null);
  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Typography.Title level={4}>Kulüp Bilgileri</Typography.Title>
        <Form.Item label="Kulüp İlcesi" name="district">
          <Select
            loading={districtLoading}
            onChange={(e) => setDistrict(e)}
            placeholder="İl seçiniz..."
            name="district"
            allowClear
            showSearch
            filterOption={false}

          >
            {districts
              ?.sort((a, b) => a.IlceAdi.localeCompare(b.IlceAdi, "tr"))
              ?.map((f) => (
                <Select.Option value={f.IlceId}>{f.IlceAdi}</Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Kulüp Adı"
          rules={[{ required: true }]}
          name="club_name"
        >
          <Select loading={isLoading}
            allowClear
            showSearch
            filterOption={false}
            onSearch={(val) => {
              setSearchedClub(val);
            }}

          >
            {gsbClubs?.filter((a) =>
              searchedClub
                ? a.club_name.toLocaleUpperCase("tr-TR").includes(
                  searchedClub?.toLocaleUpperCase("tr-TR")
                )
                : a
            )
              ?.sort((a, b) => a.club_name.localeCompare(b.club_name, "tr"))
              ?.map((i => {
                return <Select.Option key={i.club_name}>{i.club_name}</Select.Option>
              }))}
          </Select>
        </Form.Item>

        <Form.Item label="Kulüp Adresi" name="club_address">
          <Input name="club_address" />
        </Form.Item>
        <Form.Item label="Kulüp Telefonu" name="club_phone">
          <Input name="club_phone" />
        </Form.Item>

        <Divider />
        <Typography.Title level={4}>Antrenör Bilgileri</Typography.Title>
        {/* <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"> */}
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'trainer']}
                    rules={[
                      {
                        required: true,
                        message: 'İsim Giriniz.',
                      },
                    ]}
                  >
                    <Input placeholder="İsim Soyisim" name="trainer" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'trainer_phone']}
                    rules={[
                      {
                        required: true,
                        message: 'Telefon Numarası Giriniz',
                      },
                    ]}
                  >
                    <Input placeholder="Telefon" name="trainer_phone" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'trainer_email']}
                    rules={[
                      {
                        required: true,
                        message: 'E-Mail Giriniz',
                      },
                    ]}
                  >
                    <Input placeholder="E-Mail" name="trainer_email" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {/* </Form> */}
      </Form>
    </Modal>
  );
};

export default ClubsModal;
