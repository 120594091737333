import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Typography,
  Row,
  Select,
  TimePicker,
  Checkbox,
  Upload,
  message,
  Image,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  UploadOutlined,
  DeleteOutlined, InboxOutlined
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord, responseControl } from "../assets/scripts";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const NotificationModal = (props) => {

  const [form] = Form.useForm();

  const [photo, setPhoto] = React.useState(null);

  React.useEffect(() => {
    if (props.record.id) {
      const data = { ...props.record };
      if (props.record.photo) { setPhoto(props.record.photo) };

      if (props.record.expiry_date) {
        const expiryDate = moment(props.record.expiry_date);
        data.expiry_date = expiryDate;
      }
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue({ publish: true });
    }
  }, [props.record]);


  const handleClick = async () => {
    const data = form.getFieldsValue();

    return await fetch(`${API_URL}/api/v1/notifications`, {
      method: props.record.id ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: props.record.id ? JSON.stringify({ body: { ...data, photo, from: "terminal" }, where: { id: props.record.id } }) : JSON.stringify({ ...data, photo, from: "terminal" }),
    })
      .then(async (response) => {
        if (response.ok) {
          const contentType = response.headers.get('content-type');

          if (contentType && contentType.includes('application/json')) {
            return response.json()
          } else {
            const result = await response.text();
            throw new Error(result)
          }
        }
      })
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );
  };

  const onClose = () => {
    if (props.record.id) {
      return fetch(`${API_URL}/api/v1/notifications/?id=` + props.record.id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      })
        .then(() => window.location.reload())
        .catch((error) =>
          notification.error({ message: error?.message || error })
        );
    } else {
      form.resetFields();
      props.setVisible(false);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handlePhotoChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setPhoto(imageUrl);
    });
  };
  function disabledDate(current) {
    const today = new Date();

    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    return current && current > nextYear;
  }

  function checkFile(file) {
    const maxSize = 250 * 1024; // 250KB

    if (file.size > maxSize) {
      message.error('En büyük resim boyutu "250kb" dır.');
      return false; // Prevent upload
    }

    return true; // Allow upload
  }

  return (
    <Modal
      visible={props.visible}
      onCancel={() => { form.resetFields(); props.setVisible(false) }}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >

            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={onClose}
            >
              {props.record.id ? "SİL" : "İptal"}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
            >
              Kaydet
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Typography.Title level={4}>{props.record.id ? "Bildirimi Güncelle" : "Yeni Bildirim"}</Typography.Title>

        <Form.Item label="Başlıkı" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Mesaj" name="message">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Yayınla" name="publish">
          <Select options={[{ value: true, label: "EVET" }, { value: false, label: "HAYIR" }]} />
        </Form.Item>
        <Form.Item label="Bitiş Tarihi" name="expiry_date">
          <DatePicker
            style={{ width: "100%" }}
            locale={{ ...locale }}
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
          />
        </Form.Item>
        <Form.Item label="Resim" name="photo">
          {photo ? <div>
            <Image src={photo} />

            <Upload.Dragger beforeUpload={checkFile} style={{ marginTop: "2em" }} onChange={handlePhotoChange} multiple={false} progress showUploadList={false} accept=".png, .jpeg, .jpg, .jfif">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Yüklemek için bu alana tıklayın veya dosyayı sürükleyin</p>

            </Upload.Dragger>
          </div>
            : <Upload.Dragger onChange={handlePhotoChange} multiple={false} progress showUploadList={false} accept=".png, .jpeg, .jpg, .jfif">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Yüklemek için bu alana tıklayın veya dosyayı sürükleyin</p>

            </Upload.Dragger>}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
