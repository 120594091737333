import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { Button } from "antd";
import SchedulesModal from "../components/SchedulesModal";


const Schedules = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [modal, setModal] = React.useState(false);
  const [record, setRecord] = React.useState({});

  const columns = [
    {
      title: "Tarife Adı",
      dataIndex: "schedule_name",
      key: "schedule_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Ücret",
      dataIndex: "price",
      key: "price",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Birim",
      dataIndex: "currency",
      key: "currency",
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Kapasite",
      dataIndex: "capacity",
      key: "capacity",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Üye Sayısı",
      dataIndex: "count",
      key: "capacity",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },

    {
      title: "Tesis",
      dataIndex: ["facility", "name"],
      key: "facility",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Onay gerekiyor",
      dataIndex: "verification_required",
      key: "verification_required",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (required, row) => (required === true ? <p>Evet</p> : <p>Hayır</p>),
      filters: [
        {
          text: "Evet",
          value: true,
        },
        {
          text: "Hayır",
          value: false,
        },
      ],
      //prettier-ignore
      onFilter: (value, record) => record?.verification_required === value,
    },
    {
      title: "İşlemler",
      dataIndex: "actions",
      key: "actions",
      defaultSelected: true,

      render: (item, row, idx) => (

        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          <TableActions
            type="schedule"
            onClick={() => deleteRecord(`id=${row.id}`, "schedules")}
          />
          <Button type="primary" onClick={() => { setModal(true); setRecord(row) }}>GÜNCELLE</Button>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/schedules?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="TARİFELER"
        scrollX={3500}
        columns={columns}
        dataSource={dataSource}
        type="schedule"
        isLoading={isLoading}
      />

      <SchedulesModal
        setVisible={setModal}
        visible={modal}
        record={record}
        type={"schedule"}
      />
    </ErrorBoundary>
  );
};

export default Schedules;
