import { API_URL } from "../../config";
import React from "react";
import { Bar, Column, Line } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";
import moment from "moment";

const DailyPayments = (props) => {
  const config = {
    xField: "day",
    yField: "value",
    seriesField: "value",
    label: {
      position: "top",
      style: {
        fontSize: 20,
        fill: "#00000",
        opacity: 10,
        fontWeight: "bolder",
      },
    },
    point: {
      size: 5,
      shape: "diamond | circule",
    },
  };

  const [payments, setPayments] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("payments") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/payments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((result) => setPayments(result))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.payment(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let dailyPayments = {};
    const activities = [];
    const daysOfMonth = new Date(
      moment(props.date).format("YYYY"),
      moment(props.date).format("MM"),
      0
    ).getDate();
    for (let index = 1; index <= daysOfMonth; index++) {
      dailyPayments[index] = 0;
    }
    if (payments?.length > 0) {
      payments.reverse().map((payment) => {
        //prettier-ignore
        const paymentDate = moment(new Date(payment.createdAt)).format("YYYY-MM");
        if (paymentDate === props.date) {
          const paymentDay = parseInt(moment(payment.createdAt).format("DD"));
          dailyPayments[paymentDay] += parseFloat(payment?.amount);
        }
      });
      Object.keys(dailyPayments).map((key) =>
        //prettier-ignore
        activities.push( { day: key === "male" ? "Erkek" : key === "female" ? "Kadın" : key, value: dailyPayments[key] })
      );
      setChartData(activities);
    }
  }, [payments]);

  return (
    <div style={{ marginTop: "5%" }}>
      <Column data={chartData} {...config} />
    </div>
  );
};

export default DailyPayments;
