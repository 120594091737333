import { API_URL } from "../../config";
import { message } from "antd";

const verifyMember = async (member_id, schedule, schedule_id, expiryDate) => {
  if (schedule === undefined || schedule === null) {
    return message.error("Tarifesi olmayan üyenin kaydı onaylanamaz.");
  }

  return await fetch(`${API_URL}/api/v1/schedules?schedule_name=${schedule}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then(async (scheduleData) => {
      await fetch(`${API_URL}/api/v1/verifications`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          where: { member_id, schedule_id, },
          body: { approved: true, expiry_date: expiryDate.toISOString() },
        }),
      }).catch(e => console.log(e))

      return await fetch(`${API_URL}/api/v1/members`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          where: { id: member_id, },
          body: { registered: true },
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then(() => window.location.reload())
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
              ? message.error(error)
              : console.log(error)
        );
    });
};

export default verifyMember;
