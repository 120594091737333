import React from "react";
import Joyride from "react-joyride";
import handleJoyrideCallback from "../assets/scripts/handleJoyrideCallback ";
import { useHistory } from "react-router-dom";

const CJoyride = (props) => {
  const [stepIndex, setStepIndex] = React.useState(0);
  const [run, setRun] = React.useState(true);
  let history = useHistory();

  if (window.localStorage.getItem("toured") === "yes") {
    return null;
  }
  return (
    <Joyride
      callback={(data) => {
        handleJoyrideCallback(data, setRun, setStepIndex, history);
      }}
      run={run}
      stepIndex={stepIndex}
      steps={props.steps}
      continuous
      showProgress
      showSkipButton
      locale={{
        back: "Geri",
        close: "Kapat",
        last: "Bitir",
        next: "Bir sonraki",
        skip: "Tanıtımı geç",
        reset: "Yeniden başla",
        info: "Detay",
      }}
    />
  );
};

export default CJoyride;
