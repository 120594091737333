import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
const columns = [
  {
    title: "Ad Soyad",
    dataIndex: "full_name",
    key: "full_name",
    defaultSelected: true,
  },
  {
    title: "Kullanıcı Adı",
    dataIndex: "username",
    key: "username",
    defaultSelected: true,
  },
  {
    title: "E-posta",
    dataIndex: "email",
    key: "email",
    defaultSelected: true,
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone",
    defaultSelected: true,
  },
  { title: "Rol", dataIndex: "role", key: "role" },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: true,
  },
  {
    title: "Kullanıcı Tipi",
    dataIndex: "is_device",
    key: "is_device",
    render: (item) => (item ? "Cihaz" : "Sistem Kullanıcısı"),
    filters: [
      {
        text: "Cihazlar",
        value: true,
      },
      {
        text: "Sistem Kullanıcıları",
        value: false,
      },
    ],
    onFilter: (value, record) => record.is_device === value,
    defaultSelected: true,
  },
  {
    title: "Çıkış verisi kaydediliyor (cihaz tipleri için) ",
    dataIndex: "is_logout",
    key: "is_logout",
    render: (bool) => {
      return bool === false ? "Hayır" : "Evet";
    },
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    defaultSelected: true,

    render: (item, row, idx) => (
      <TableActions
        type="user"
        onClick={(event) => deleteRecord(`user_id=${row.user_id}`, "users")}
      />
    ),
    fixed: "right",
  },
];
const Users = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/users?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="KULLANICILAR"
        columns={columns}
        dataSource={dataSource}
        type="user"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Users;
