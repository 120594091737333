import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React from "react";
import { getBase64, uploadFile } from "../assets/scripts";
import { API_URL } from "../config";

const UploadExcel = () => {
  const [excelWillSend, setExcelWillSend] = React.useState(null);
  const uploadOnChange = (info) => {
    getBase64(info.file.originFileObj, (excelUrl) =>
      setExcelWillSend(excelUrl)
    );
  };

  const handleUpload = () => {
    uploadFile(
      excelWillSend,
      API_URL + "/api/v1/members-excel",
      false,
      null,
      true
    );
  };

  if (excelWillSend === null) {
    return (
      <Upload onChange={uploadOnChange} accept=".xlsx,.xls">
        <Button
          className="upload-excel-btn"
          icon={<UploadOutlined />}
          type="primary"
          size="large"
          style={{
            backgroundColor: "#fa8c16",
            borderColor: "#fa8c16",
            marginTop: 16,
            marginLeft: 20,
          }}
        >
          Excel Yükle
        </Button>
      </Upload>
    );
  } else {
    return (
      <Button
        onClick={handleUpload}
        icon={<SendOutlined />}
        type="primary"
        size="large"
        style={{
          backgroundColor: "#fa8c16",
          borderColor: "#fa8c16",
          marginTop: 16,
          marginLeft: 20,
        }}
      >
        Excel Gönder
      </Button>
    );
  }
};

export default UploadExcel;
