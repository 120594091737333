import { API_URL } from "../config";
import React from "react";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { Button, Image, Modal, Select, Tag, Typography, notification } from "antd";
import path from "path";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";

const Refunds = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const approveRecord = (row, decision) => {


    const body = {
      body: {
        ...row,
        approve: decision,
      },
      where: { id: row.id },
    };
    console.log("BODY ", body);

    return fetch(`${API_URL}/api/v1/refunds`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async r => {
        if (r.ok) {
          window.location.reload()
        } else {
          throw new Error((await r.text()))
        }
      })
      .catch((error) => {
        console.log("err", error)
        notification.error({ message: error?.message || error })
      })
      .finally(() => setIsLoading(false));
  };

  const [fileURL, setFileURL] = React.useState({});
  const [selectedFile, setSelectedFile] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const handleSelect = (value, record) => {
    getFile(value, "preview");

    setSelectedFile(value);
  };

  const getFile = async (fileName, action) => {
    return await fetch(`${API_URL}/api/v1/files?file_name=${fileName}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (action === "preview") {
          setFileURL(result);
        } else if (action === "download") {
          const linkSource = `data:application/pdf;base64,${result?.contents}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = path.basename(fileName);
          downloadLink.click();
        }
      })
      .catch((error) => console.log({ getFileError: error }));
  };

  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "fullname",
      key: "fullname",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "IBAN sahibin adı soyadı",
      dataIndex: "iban_full_name",
      key: "iban_full_name",
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      key: "iban",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Miktar (TL)",
      dataIndex: "amount",
      key: "amount",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (m) => Number(m) >= 0 && Number(m).toFixed(2) + " TL"
    },
    {
      title: "Mesaj",
      dataIndex: "message",
      key: "message",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Onaylayan",
      dataIndex: "approver",
      key: "approver",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    // {
    //   defaultSelected: true,
    //   title: "Belgeler",
    //   dataIndex: "file_paths",
    //   key: "file_paths",
    //   render: (item, row) => {
    //     console.log(row);
    //     const files = item.map((i) => {
    //       const parts = i.split("/");
    //       return parts[parts.length - 1];
    //     });

    //     if (item?.length === 0 || item === null) {
    //       return "Kayıtlı dosya yok";
    //     } else {
    //       return (
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //           }}
    //         >
    //           <Select
    //             onSelect={(value) => handleSelect(value, row)}
    //             style={{ width: 200 }}
    //           >
    //             {files?.length > 0
    //               ? files.map((file, index) => (
    //                   <Select.Option value={item[index]}>{file}</Select.Option>
    //                 ))
    //               : item.split(",").map((file) => {
    //                   if (file !== "null") {
    //                     return (
    //                       <Select.Option value={file}>
    //                         {path.basename(file)}
    //                       </Select.Option>
    //                     );
    //                   }
    //                 })}
    //           </Select>

    //           <Button
    //             icon={<SearchOutlined />}
    //             type="primary"
    //             onClick={() => setVisible(true)}
    //             style={{ marginLeft: 5 }}
    //           >
    //             Önizle
    //           </Button>
    //           <Button
    //             icon={<DownloadOutlined />}
    //             type="primary"
    //             onClick={() => getFile(selectedFile, "download")}
    //             style={{ marginLeft: 5 }}
    //           >
    //             İndir
    //           </Button>
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      title: "Tesis onayı",
      dataIndex: "approve",
      defaultSelected: true,
      key: "approve",
      render: (bool) => {
        switch (bool) {
          case true:
            return <Tag color="green">Onaylandı</Tag>;

          case false:
            return <Tag color="red">Reddedildi</Tag>;

          default:
            return <Tag color="gray">İşlem yapılmadı</Tag>;
        }
      },
    },
    {
      title: "Merkez onayı",
      dataIndex: "central_approve",
      defaultSelected: true,
      key: "central_approve",
      render: (bool) => {
        switch (bool) {
          case true:
            return <Tag color="green">Onaylandı</Tag>;

          case false:
            return <Tag color="red">Reddedildi</Tag>;

          default:
            return <Tag color="gray">İşlem yapılmadı</Tag>;
        }
      },
    },
    {
      title: "İşlemler",
      dataIndex: "actions",
      key: "actions",
      defaultSelected: true,
      width: 250,
      render: (item, row, idx) => {
        return (
          row.approve === null && (
            <TableActions
              type="refunds"
              update={true}
              onClick={() => approveRecord(row, true)}
              onRefuse={() => approveRecord(row, false)}
            />
          )
        );
      },
    },
  ];

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/refunds`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="İADELER"
        scrollX={3500}
        columns={columns}
        dataSource={dataSource}
        type="refunds"
        excel={false}
        isLoading={isLoading}
      />
      <Modal
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        style={{ marginBottom: 100 }}
        closeIcon={
          <div
            style={{
              borderRadius: "100%",
              backgroundColor: "red",
              marginTop: 10,
              marginLeft: 5,
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "#ffff",
            }}
          >
            <CloseOutlined />
          </div>
        }
      >
        {fileURL?.extName === ".pdf" ? (
          <object
            data={"data:application/pdf;base64," + fileURL?.contents}
            type="application/pdf"
            style={{ minHeight: "100vh", width: "100%", marginTop: 25 }}
          ></object>
        ) : fileURL?.extName === ".plain" ? (
          <Typography style={{ marginTop: 25 }}>
            <Typography.Paragraph>
              {b64_to_utf8(fileURL?.contents)}
            </Typography.Paragraph>
          </Typography>
        ) : (
          <Image
            src={
              "data:application/" +
              fileURL?.extName +
              ";base64," +
              fileURL?.contents
            }
          />
        )}
      </Modal>
    </ErrorBoundary>
  );
};

export default Refunds;
