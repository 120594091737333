import { API_URL } from "../../config";
import React from "react";
import { Line } from "@ant-design/charts";
import { responseControl } from "../../assets/scripts";
import { message } from "antd";

const LineChart = (props) => {
  const config = {
    appendPadding: [10, 40],

    xField: "month",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond | circule",
    },
  };

  const [logs, setLogs] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("logs") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => responseControl(response))
        .then((result) => setLogs(result))
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  React.useEffect(() => {
    let monthlyLogs = {};
    if (logs?.length > 0) {
      logs.reverse().map((log) => {
        const logYear = new Date(log.login).getFullYear();
        //prettier-ignore
        const logMonth = new Date(log.login).toLocaleString("tr", {month: "long"});

        //prettier-ignore
        if (monthlyLogs.hasOwnProperty(`${logMonth} ${logYear}`)) {
          //prettier-ignore
          monthlyLogs[`${logMonth} ${logYear}`] = monthlyLogs[`${logMonth} ${logYear}`] + 1;
        } else {
          monthlyLogs[`${logMonth} ${logYear}`] = 0;
        }
      });
      Object.keys(monthlyLogs).map((key) =>
        setChartData((prevState) => [
          ...prevState,
          { month: key, value: monthlyLogs[key] },
        ])
      );
    }
  }, [logs]);

  return <Line data={chartData} {...config} />;
};

export default LineChart;
