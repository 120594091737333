import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { Button, Tag } from "antd";
import ListMembersCell from "../components/ListMembersCell";
import SessionsDisplay from "../components/SessionsDisplay";

const colors = [
  "purple",
  "magenta",
  "gold",
  "geekblue",
  "orange",
  "volcano",
  "lime",
  "red",
  "green",
  "cyan",
  "blue",
];

const weekdays = [
  { en: "Monday", tr: "Pazartesi" },
  { en: "Tuesday", tr: "Salı" },
  { en: "Wednesday", tr: "Çarşamba" },
  { en: "Thursday", tr: "Perşembe" },
  { en: "Friday", tr: "Cuma" },
  { en: "Saturday", tr: "Cumartesi" },
  { en: "Sunday", tr: "Pazar" },
];

const Programs = () => {
  const [modalMemberListInfo, setModalMemberListInfo] = React.useState({
    open: false,
    data: [],
  });
  const [openSessions, setOpenSessions] = React.useState({ open: false, data: null });
  const [all, setAll] = React.useState(false)

  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [members, setMembers] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/schedules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(r => {
        setSchedules(r)
      })
      .catch(e => console.log(e));


    fetch(`${API_URL}/api/v1/members`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(r => setMembers(r))
      .catch(e => console.log(e))
    fetch(`${API_URL}/api/v1/programs?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        const rawAppointments = await fetch(
          `${API_URL}/api/v1/appointments?include=member,system_data`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const appointments = await rawAppointments.json();

        const programWithAppointments = result.map((program) => {
          const programAppointments = appointments.filter(
            (app) => app.program_id === program.id
          );

          return { ...program, appointments: programAppointments };
        });

        setDataSource(programWithAppointments);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));


  }, []);
  React.useEffect(() => {
    const initialColumns = [
      {
        title: "Başlık",
        dataIndex: "title",
        key: "title",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Açıklama",
        dataIndex: "description",
        responsive: ["xs", "md", "lg"],
        key: "description",
        defaultSelected: true,
      },

      {
        title: "Başlama Tarihi",
        dataIndex: "startline",
        key: "startline",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],

        render: (item) => new Date(item).toLocaleDateString("tr-TR"),
      },
      {
        title: "Bitiş Tarihi",
        dataIndex: "deadline",
        key: "deadline",
        responsive: ["xs", "md", "lg"],

        defaultSelected: true,
        render: (item) => new Date(item).toLocaleDateString("tr-TR"),
      },
      {
        title: "Erken/Geç Giriş",
        dataIndex: "entry_time_range",
        key: "entry_time_range",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        render: (text) => {
          return text + " dk.";
        },
      },
      {
        title: "Kaç gün içinde randevu alınır?",
        dataIndex: "max_days",
        key: "max_days",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        width: 200
      },

      {
        title: "Seanslar",
        dataIndex: "sessions",
        key: "sessions",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        render: data => <Button onClick={(e) => {
          e.stopPropagation();
          setOpenSessions({ open: true, data })
        }}>Seansları göster</Button>
      },
      {
        title: "Kapasitesi",
        dataIndex: "capacity",
        key: "capacity",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
      },
      {
        title: "Üyeler",
        dataIndex: "members",
        key: "members",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        render: (_, row) => {
          return (
            row.members && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>Programa katılabilen üye sayısı: {row.members?.length}</p>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAll(true)
                    setModalMemberListInfo({
                      open: true,
                      data: row.members,
                    });
                  }}
                >
                  Üyeleri görüntüle
                </Button>
              </div>
            )
          );
        },
      },
      {
        title: "Randevu Alan Üyeler",
        dataIndex: "appointments",
        key: "appointments",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        render: (_, row) => {
          return (
            row.appointments && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>Toplam randevu alan üye sayısı: {row.appointments?.length}</p>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();

                    setModalMemberListInfo({
                      open: true,
                      data: row.appointments,
                    });
                  }}
                >
                  Üyeleri görüntüle
                </Button>
              </div>
            )
          );
        },
      },
      {
        title: "Oluşturma Tarihi",
        dataIndex: "createdAt",
        key: "createdAt",
        defaultSelected: true,

        render: (item, row, idx) => {
          if (item === null) return null;

          return new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-");
        },
      },

      {
        title: "Son Değişiklik Tarihi",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (item, row, idx) => {
          if (item === null) return null;

          return new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-");
        },
      },
      {
        title: "Filtre",
        dataIndex: "filter",
        key: "filter",
        defaultSelected: false,
        render: (data) => {
          const filters = JSON.parse(data);
          if (!filters?.in && !filters.between) {
            return [];
          };
          const ageRange = filters.between;
          const options = filters.in;
          if (ageRange?.[0]?.birthdate) {
            return "Doğum yılı aralığı: " + ageRange[0].birthdate.join(", ")
          }

          if (options) {
            const schedulesIds = (options.find(o => Object.keys(o).includes("schedule")))?.schedule?.flat();
            const gender = (options.find(o => Object.keys(o).includes("gender")))?.gender?.flat();
            const birthdate = (options.find(o => Object.keys(o).includes("birthdate")))?.birthdate?.flat();
            const cards = (options.find(o => Object.keys(o).includes("card_id")))?.card?.flat();
            let text = "";
            if (schedulesIds?.length) {
              text += "Tarifeler: " + schedulesIds.map(s => schedules.find(d => d.id === s)?.schedule_name).join(", ");
            }
            if (gender?.length) {
              text += "\nCinsiyet: " + gender[0]?.female ? "Kadın" : "Erkek"
            }

            if (birthdate?.length) {
              text += "\nDoğum yılı bu seneden büyük: " + birthdate[0]
            }

            if (cards?.length) {
              text += "\nÜyeler: " + members.map(m => m.first_name + " " + m.last_name).join(", ")
            }
            return text
          }
        }
      },
      {
        title: "Kategori",
        dataIndex: "category",
        key: "category",
        defaultSelected: false,
        render: (item) => {
          const categories = {
            A: "Spor Kulübü",
            B: "Kurs",
            C: "Genel/Halk seans",
            D: "Aboneler seansı"
          };
          return item && categories[item];
        }
      },
      {
        title: "İşlemler",
        dataIndex: "actions",
        key: "actions",
        render: (item, row, idx) => (
          <TableActions
            type="program"
            onClick={(event) => deleteRecord(`id=${row.id}`, "programs")}
          />
        ),
        fixed: "right",
      },
    ];
    setColumns(initialColumns);

  }, [schedules, members]);
  const deleteAppointment = (id) => {
    deleteRecord("id=" + id, "appointments");
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >

      <CTable
        title="PROGRAMLAR"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="program"
        isLoading={isLoading}
        weekdays={weekdays}
      />
      {modalMemberListInfo.open && (
        <ListMembersCell
          datasource={modalMemberListInfo.data}
          type={"programs"}
          all={all}
          modalInfo={modalMemberListInfo}
          setModalInfo={setModalMemberListInfo}
          deleteAppointment={deleteAppointment}
          setAll={setAll}
        />
      )}

      <SessionsDisplay setOpenSessions={setOpenSessions} openSessions={openSessions} />
    </ErrorBoundary>
  );
};

export default Programs;
