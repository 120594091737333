import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const columns = [
  {
    title: "Tesis ID",
    dataIndex: "id",
    key: "id",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis Adı",
    dataIndex: "name",
    key: "name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Adres",
    dataIndex: "address",
    key: "address",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "İletişim",
    children: [
      {
        title: "Telefon",
        dataIndex: "phone",
        key: "phone",
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Eposta",
        dataIndex: "email",
        key: "email",
        responsive: ["xs", "md", "lg"],
      },
    ],
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    defaultSelected: true,

    render: (item, row, idx) => (
      <TableActions
        type="facility"
        onClick={() => deleteRecord(`id=${row.id}`, "facilitys")}
      />
    ),
  },
];

const Facilities = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/facilitys`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="TESİSLER"
        scrollX={3500}
        columns={columns}
        dataSource={dataSource}
        type="facility"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Facilities;
