import { DeleteOutlined, DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Input, Space } from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import React from "react";
import "../assets/styles/components/table.css";
import * as XLSX from "xlsx";

export default function ListMembersCell({
  datasource,
  type,
  modalInfo,
  setModalInfo,
  deleteAppointment,
  all,
  setAll
}) {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm()
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Temizle
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "birthdate") {
        return record?.[dataIndex] && record[dataIndex]?.includes(value)
      }
      return record?.[dataIndex] && record[dataIndex]?.toString()?.toLocaleLowerCase("tr-TR")?.includes(value.toLocaleLowerCase("tr-TR"))
    },

  });
  const cols = all && type === "subscription" ? [{
    title: "Ad",
    dataIndex: "first_name",
    key: "first_name",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("first_name")

  }, {
    title: "Soyad",
    dataIndex: "last_name",
    key: "last_name",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("last_name")

  }, {
    title: "TC Numarası",
    dataIndex: "tc_no",
    key: "tc_no",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("tc_no")

  }, {
    title: "Telefon Numarası",
    dataIndex: "phone_number",
    key: "phone_number",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("phone_number")

  }, {
    title: "Doğum Tarihı",
    dataIndex: "birthdate",
    key: "birthdate",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("birthdate"),
    render: data => {
      return data && new Date(data).toLocaleDateString("tr-TR")
    },
  },
  {
    title: "Kalan Hakkı",
    dataIndex: "ticket_count",
    key: "ticket_count",
    responsive: ["xs", "md", "lg"],

  }
  ] : all ? [{
    title: "Ad",
    dataIndex: "first_name",
    key: "first_name",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("first_name")

  }, {
    title: "Soyad",
    dataIndex: "last_name",
    key: "last_name",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("last_name")

  }, {
    title: "TC Numarası",
    dataIndex: "tc_no",
    key: "tc_no",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("tc_no")

  }, {
    title: "Telefon Numarası",
    dataIndex: "phone_number",
    key: "phone_number",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("phone_number")

  }, {
    title: "Doğum Tarihı",
    dataIndex: "birthdate",
    key: "birthdate",
    responsive: ["xs", "md", "lg"],
    ...getColumnSearchProps("birthdate"),
    render: data => {
      return data && new Date(data).toLocaleDateString("tr-TR")
    }

  }] : [{
    title: "Ad",
    dataIndex: "first_name",
    key: "first_name",
    responsive: ["xs", "md", "lg"],
    render: (_, row) => {
      return row.member.first_name
    }
  }, {
    title: "Soyad",
    dataIndex: "last_name",
    key: "last_name",
    responsive: ["xs", "md", "lg"],
    render: (_, row) => {
      return row.member.last_name
    }
  },
  {
    title: "İşlemler",
    dataIndex: "ops",
    key: "ops",
    responsive: ["xs", "md", "lg"],
    render: (_, data) => {
      return <DeleteOutlined
        onClick={() => deleteAppointment(data.id)}
        className="ml-2"
        style={{ color: "red" }}
      />
    }
  }
  ];

  const exportExcel = () => {
    const fileName = `${all ? "üye-listesi" : "randevulu-üye-"}-${new Date()
      .toLocaleDateString("tr-TR")
      .split(".")
      .reverse()
      .join("-")}.xlsx`;
    const data = all ? datasource : datasource.member;


    /* add to workbook */
    var wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(data.map(d => {
      const data = { Ad: d.first_name, Soyad: d.last_name, "Telefon Numarası": d.phone_number, "Doğum Tarihi": d.birthdate };
      if (type === "subscription") {
        data["Kalan Hak"] = d.ticket_count
      }
      if (d.tc_no) {
        data["Kimlik Numarası"] = d.tc_no
      }
      return data
    }));

    XLSX.utils.book_append_sheet(wb, ws, "üyeler");

    /* generate an XLSX file */
    XLSX.writeFile(wb, fileName);
  }
  return (
    modalInfo.open && (
      <ErrorBoundary>
        <Modal
          width={800}
          title={all ? "Üyeler" : "Hücreye ait randevu biligileri"}
          visible={modalInfo.open}
          onCancel={() => {
            setAll(false)
            setModalInfo({ open: false, data: null, type: null })
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setAll(false)
                setModalInfo({ open: false, data: null, type: null })
              }
              }
              className="btn-full-cancel mr-3  mb-3"
            >
              İptal
            </Button>,
          ]}
          className="modal"
        >

          <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

              <p style={{ fontWeight: "900", marginBottom: "1.25rem" }}>
                {all ? "Üye listesi" : "Randevusu olan üyeler"}
              </p>
              <Button onClick={exportExcel}>
                <DownloadOutlined style={{ marginRight: ".3em" }} />
                İndir
              </Button>
            </div>
            <div className="table-appointment-programs flex items-center flex-wrap">

              <Table columns={cols} dataSource={datasource} />

            </div>
          </div>
        </Modal>
      </ErrorBoundary>
    )
  );
}
