import { API_URL } from "../config";
import React from "react";
import CTable from "../components/CTable";
import { Button, Card, Form, Input, notification, Radio, Table } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import notFoundSound from "../assets/sounds/not-found.mp3";

const deleteZeroFromCardNum = (card_num) => {
  console.log(typeof card_num);
  let patt = /^0*/;
  let result = patt.test(card_num);
  console.log(card_num);
  if (result === true) {
    var cardnum = card_num?.replace(/^0*/g, "");
    return cardnum;
  }
  console.log(result);
};

const initialColumns = [
  {
    title: "Adı",
    dataIndex: "first_name",
    key: "first_name",
    defaultSelected: true,
  },
  {
    title: "Soyadı",
    dataIndex: "last_name",
    key: "last_name",
    defaultSelected: true,
  },
  {
    title: "Kart No",
    dataIndex: "cards",
    key: "cards",
    ellipsis: true,
    render: (item, row, index) => {
      if (item) {
        if (item?.length === 0) {
          return null;
        } else {
          return item[0]?.card_number;
        }
      }
    },
    defaultSelected: true,
  },
];

const billsColumns = [
  {
    title: "Tür",
    key: "type",
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => {
      if (row?.hasOwnProperty("payment_method")) {
        return <p>Bakiye yükleme</p>;
      } else {
        return <p>Harcama</p>;
      }
    },
    defaultSelected: true,
  },
  {
    title: "Miktar",
    dataIndex: "amount",
    key: "amount",
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => {
      if (row?.hasOwnProperty("payment_method")) {
        return (
          <p
            style={{
              color: "green",
              fontWeight: "600",
              fontSize: 16,
              padding: "1%",
            }}
          >
            {item}
          </p>
        );
      } else {
        return (
          <p
            style={{
              color: "red",
              fontWeight: "600",
              fontSize: 16,
              padding: "1%",
            }}
          >
            {item}
          </p>
        );
      }
    },
    defaultSelected: true,
  },
  {
    title: "Ödeme Yöntemi",
    dataIndex: "payment_method",
    key: "payment_method",
    responsive: ["xs", "md", "lg"],

    render: (item, row, index) => {
      if (row?.hasOwnProperty("payment_method")) {
        if (item === "cash") {
          return <p>Nakit</p>;
        } else if (item === "credit_card") {
          return <p>Kredi Kartı</p>;
        } else if (item) {
          return <p>{item}</p>;
        }
        return <p>Elle Yükleme</p>;
      } else {
        return null;
      }
    },
    defaultSelected: true,
    filters: [
      {
        text: "Nakit",
        value: "cash",
      },
      {
        text: "Kredi Kartı",
        value: "credit_card",
      },
      {
        text: "Elle yükeleme",
        value: null,
      },
    ],
    onFilter: (value, record) => record.payment_method === value,
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: true,
  },
  {
    title: "Tarih",
    dataIndex: "createdAt",
    key: "createdAt",
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => {
      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}{" "}
          {new Date(item)
            .toLocaleTimeString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
    defaultSelected: true,
  },
];

const Bills = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [queryObject, setQueryObject] = React.useState([]);
  const [queryString, setQueryString] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [bills, setBills] = React.useState(["initial"]);
  const [searchOption, setSearchOption] = React.useState("personal");
  React.useEffect(() => {
    setColumns(initialColumns);
  }, []);

  React.useEffect(() => {
    if (bills instanceof Array && bills.length === 0) {
      const audio = new Audio(notFoundSound);
      audio.play();
      notification.warning({
        message: "Seçilen kullanıcıya ait harcama detayı bulunamadı.",
        placement: "topRight",
      });
    }
  }, [bills]);

  React.useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      let qStr =
        "&" +
        Object.keys(queryObject)
          .map((key) => {
            if (queryObject[key]?.toString()?.length > 0) {
              return `${key}=${queryObject[key]}`;
            }
          })
          .filter((el) => el)
          .join("&");

      setQueryString(qStr);
    } else {
      setQueryString("");
    }
  }, [queryObject]);

  console.log("quer obj", queryObject);

  const handleChange = (event) => {
    //prettier-ignore
    let value = typeof event.target.value === "string" ? event.target.value.toLocaleUpperCase("TR") : event.target.value

    console.log("value", value);
    setQueryObject({
      ...queryObject,
      [event.target.name]: deleteZeroFromCardNum(value),
    });
  };

  const handleQuery = async () => {
    setLoading(true);

    if (queryObject?.hasOwnProperty("card_number")) {
      return await fetch(
        `${API_URL}/api/v1/cards?include=member${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result instanceof Array && result[0]?.member) {
            setDataSource([
              {
                ...result[0]?.member,
                cards: [{ card_number: result[0]?.card_number }],
              },
            ]);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          return setTimeout(() => setLoading(false), 500);
        });
    } else {
      return await fetch(
        `${API_URL}/api/v1/members?include=card${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setDataSource(result);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          return setTimeout(() => setLoading(false), 500);
        });
    }
  };

  const handleBills = async (id, facility_id) => {
    return await fetch(`${API_URL}/api/v1/bills`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, facility_id }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result instanceof Array && result.length > 0) {
          setBills(result);
        } else {
          setBills([]);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSearchOption = (event) => {
    setQueryObject([]);
    setQueryString("");
    setSearchOption(event.target.value);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <h2 style={{ fontWeight: "bold", margin: "1em" }}>HARCAMALAR</h2>
        <Card
          style={{
            margin: "0 1%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Radio.Group
            onChange={handleSearchOption}
            defaultValue="personal"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginBottom: "3%",
            }}
          >
            <Radio value="personal">Kimlik Bilgilerine Göre</Radio>
            <Radio value="card">Kart Numarasına Göre</Radio>
          </Radio.Group>
          <Form
            acceptCharset="UTF-8"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            layout="inline"
          >
            {searchOption === "personal" ? (
              <>
                <Form.Item label="Adı">
                  <Input
                    lang="tr"
                    style={{ textTransform: "uppercase" }}
                    name="first_name"
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item label="Soyadı">
                  <Input
                    lang="tr"
                    style={{ textTransform: "uppercase" }}
                    name="last_name"
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item label="Kimlik Numarası">
                  <Input name="tc_no" onChange={handleChange} />
                </Form.Item>
              </>
            ) : (
              <Form.Item label="Kart Numarası">
                <Input name="card_number" onChange={handleChange} />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                style={{ minWidth: 100 }}
                onClick={handleQuery}
              >
                Sorgula
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Table
          dataSource={dataSource}
          columns={columns}
          style={{ margin: "3% 1%" }}
          onRow={(record, idx) => {
            return {
              onClick: (event) => handleBills(record.id, record.facility_id),
            };
          }}
        />
        {bills.length === 1 && bills.includes("initial") ? null : bills.length >
          0 ? (
          <CTable scrollX={3500} dataSource={bills} columns={billsColumns} />
        ) : null}
      </div>
    </ErrorBoundary>
  );
};

export default Bills;
