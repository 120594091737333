import { notification } from "antd";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";

const menus = [
  "files",
  "verifications",
  "cards",
  "logs",
  "payments",
  "tickets",
  "bills",
  "seasons",
  "appointments",
  "schedules",
  "clubs",
  "trainers",
  "programs",
  "calendars",
  "devices",
  "roles",
  "users",
  "facility_bank_accounts",
  "terminal",
];

const filterPrms = () =>
  new Promise((resolve) => {
    document.getElementsByClassName("filters")[0].click();

    resolve(true);
  });

const handleJoyrideCallback = async (data, setRun, setStepIndex, history) => {
  const { action, index, status, type } = data;

  if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    if (data.action === "next") {
      if (menus.indexOf(data.step.target.split(".")[1]) !== -1) {
        if (
          menus[menus.indexOf(data.step.target.split(".")[1]) + 1] !== undefined
        ) {
          history.push(
            `/${menus[menus.indexOf(data.step.target.split(".")[1]) + 1]}`
          );
        }
      }
      if (data.index === 13) {
        history.push("/members");
      }
      if (data.index === 26) {
        history.push("/files");
      }
      if (data.index === 15) {
        filterPrms().then(() => {
          /*prettier-ignore */
          document.getElementsByClassName("ant-menu-vertical")[0]?.scrollBy(0, 1000)

          if (!document.getElementById("actions")?.checked) {
            document.getElementById("actions")?.click();
          }
        });
      }
      if (data.index === 16) {
        filterPrms().then(() => document.getElementById("filter-btn")?.click());
      }
    }
    if (data.action === "prev") {
      if (menus.indexOf(data.step.target.split(".")[1]) !== -1) {
        if (
          menus[menus.indexOf(data.step.target.split(".")[1]) - 1] !== undefined
        ) {
          history.push(
            `/${menus[menus.indexOf(data.step.target.split(".")[1]) - 1]}`
          );
        }
      }
      if (data.index === 14) {
        history.push("/panel");
      }
      if (data.step.target === ".ant-table-selection-column") {
        history.push("/members");
      }
    }

    if (data.action === "close") {
      window.localStorage.setItem("toured", "yes");
      notification.info({ message: "Tanıtım modundan çıktınız" });
      setRun(false);
    }
    if (data.step.target === ".bulk-selection-btn") {
      document.getElementsByClassName("bulk-selection-btn")[0]?.click();
    }

    setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
  } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    window.localStorage.setItem("toured", "yes");
    setRun(false);
  }
};
export default handleJoyrideCallback;
