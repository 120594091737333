import { API_URL } from "../../config";
import { message } from "antd";
import responseControl from "./responseControl";

const deleteRecord = async (query, url) => {
  return await fetch(`${API_URL}/api/v1/${url}?${query}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(async (response) => {

      const contentType = response.headers.get("content-type");
      if (contentType.includes("text")) { throw new Error(await response.text()) } else { return response.json() }
    })
    .then(() => window.location.reload())
    .catch((error) => {
      if (error instanceof Error) {
        return message.error({
          content: (
            <div
              dangerouslySetInnerHTML={{ __html: error.message }}
              style={{ display: "inline-block" }}
            ></div>
          ),
          style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
        });
      }
      if (typeof error === "string") {
        return message.error({
          content: (
            <div
              dangerouslySetInnerHTML={{ __html: error }}
              style={{ display: "inline-block" }}
            ></div>
          ),
          style: { marginTop: "20vh", fontSize: 18, fontWeight: 400 },
        });
      } else {
        console.log(error);
      }
    });
};

export default deleteRecord;
