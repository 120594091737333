import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Typography,
  Row,
  Select,
  TimePicker,
  Checkbox,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord, responseControl } from "../assets/scripts";





const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const CoursesModal = (props) => {
  const [trainers, setTrainers] = React.useState([]);
  React.useEffect(() => {

    const getTrainers = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/trainers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const result = await responseControl(response);
        setTrainers(result);
      } catch (error) {
        return notification.error({ message: error?.message || error });
      }
    };

    getTrainers();
  }, []);
  const [form] = Form.useForm();
  const weekdays = [
    { en: "Monday", tr: "Pazartesi" },
    { en: "Tuesday", tr: "Salı" },
    { en: "Wednesday", tr: "Çarşamba" },
    { en: "Thursday", tr: "Perşembe" },
    { en: "Friday", tr: "Cuma" },
    { en: "Saturday", tr: "Cumartesi" },
    { en: "Sunday", tr: "Pazar" },
  ];


  const [isUpdate, setIsUpdate] = React.useState(false);
  React.useEffect(() => {
    if (Object.keys(props.record).length > 0) {

      setIsUpdate(true);
      const filter = JSON.parse(props.record.filter);
      form.setFieldsValue({ ...props.record, start_age: filter.ages.start_age, finish_age: filter.ages.finish_age, gender: filter.gender, cost: parseFloat(props.record.cost), finish_time: moment(props.record.finish_time, "HH:mm:ss"), start_time: moment(props.record.start_time, "HH:mm:ss"), start_date: moment(props.record.start_date,), end_date: moment(props.record.end_date) });

    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
    }
  }, [props]);
  const [loading, setLoading] = React.useState(false)
  const handleClick = async () => {
    setLoading(true)
    let fieldsWillSend = {};
    const data = form.getFieldsValue();
    if (!props.record.id && [data.start_age, data.gender, data.name, data.days, data.start_date, data.end_date, data.start_time, data.finish_time, data.repetition, data.total_students, data.trainer].some(d => Boolean(d) === false) && data.cost >= 0) {
      return notification.error({ message: "Gerekli alanları doldurunuz" })
    }
    if (!props.record.id) {
      fieldsWillSend = { ...data };
      const ages = { start_age: data.start_age, finish_age: data.finish_age };

      const filter = JSON.stringify({ ages, gender: data.gender });
      fieldsWillSend.filter = filter;
      delete fieldsWillSend.start_age;
      delete fieldsWillSend.finish_age;
      delete fieldsWillSend.gender;

      fieldsWillSend.start_date = data.start_date.format('YYYY-MM-DD');
      fieldsWillSend.end_date = data.end_date.format('YYYY-MM-DD');

      fieldsWillSend.start_time = data.start_time.format('HH:mm:ss');
      fieldsWillSend.finish_time = data.finish_time.format('HH:mm:ss');
      if (fieldsWillSend.required_trainer === undefined || fieldsWillSend.required_trainer === null) {
        fieldsWillSend.required_trainer = false
      }
    }

    return await fetch(`${API_URL}/api/v1/courses`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
          body: { course_id: data.course_id, name: data.name, required_trainer: data.required_trainer, trainer: data.trainer, total_students: data.total_students },
          where: { id: props.record.id },
        })
        : JSON.stringify(fieldsWillSend),
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      ).finally(() => setLoading(false))
  };


  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {isUpdate ? (
            <Popconfirm
              title="Kaydı silmek istediğinize emin misiniz?"
              onConfirm={() =>
                deleteRecord(`id=${props.record.id}`, "courses")
              }
              onCancel={() => { }}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Etkinliği Sil
              </Button>
            </Popconfirm>
          ) : (
            <div></div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
              loading={loading}
            >
              Kaydet
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => props.setVisible(false)}
            >
              {props.type === "request" ? "Reddet" : "İptal"}
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Typography.Title level={4}>Kurs Bilgileri</Typography.Title>
        <Form.Item label="Kurs Ad" name="name" rules={[
          {
            required: true,
            message: "Gerekli alan"
          }
        ]}
          validateTrigger="onBlur">
          <Input name="name" />
        </Form.Item>
        <Form.Item label="Kurs ID" name="course_id"
          validateTrigger="onBlur">
          <Input name="course_id" />
        </Form.Item>
        <Form.Item label="Antrenör girişi gerekli" name="required_trainer" valuePropName="checked">
          <Checkbox>
            Evet
          </Checkbox>
        </Form.Item>

        <Form.Item label="Antrenör" name="trainer" rules={[
          {
            required: true,
            message: "Gerekli alan"
          }
        ]}>
          <Select
            allowClear
            showArrow
          >
            {trainers?.map((tr) => (
              <Option value={tr.id}>{tr.full_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Kursiyer kontenjanı" name="total_students" rules={[
          {
            required: true,
            message: "Gerekli alan"
          }
        ]}
          validateTrigger="onBlur">
          <InputNumber min={0} style={{ width: 110 }} name="total_students" />
        </Form.Item>
        {
          !props.record.id && <>


            <Form.Item label="Gün" name="days" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <Select
                allowClear
                showArrow
                mode="multiple"
              >
                {weekdays.map((day) => (
                  <Option value={day.en}>{day.tr}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Başlangıç Tarihi" name="start_date" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <DatePicker
                style={{ width: "100%" }}
                locale={{ ...locale }}
                format="YYYY-MM-DD"
              />
            </Form.Item>
            <Form.Item label="Bitiş Tarihi" name="end_date" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <DatePicker
                style={{ width: "100%" }}
                locale={{ ...locale }}
                format="YYYY-MM-DD"
              />
            </Form.Item>

            <Form.Item label="Zaman aralığı" style={{ marginBottom: 0 }}  >
              <Row gutter={24}>
                <Col md={12}>
                  <Form.Item name="start_time" rules={[
                    {
                      required: true,
                      message: "Gerekli alan"
                    }
                  ]}
                    validateTrigger="onBlur">
                    <TimePicker
                      placeholder="Başlangıç Saatı"
                      locale={{ ...locale }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item name="finish_time" rules={[
                    {
                      required: true,

                      message: "Gerekli alan"
                    }
                  ]}
                    validateTrigger="onBlur">
                    <TimePicker
                      placeholder="Bitiş Saatı"
                      locale={{ ...locale }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Kurs Tekrar Sayısı" name="repetition" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <InputNumber min={0} style={{ width: 110 }} name="repetition" />
            </Form.Item>


            <Form.Item label="Ücret" name="cost" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <InputNumber style={{ width: 110 }} min={0} name="cost" />
            </Form.Item>

            <Form.Item label="Cinsiyet" name="gender" rules={[
              {
                required: true,
                message: "Gerekli alan"
              }
            ]}
              validateTrigger="onBlur">
              <Select
                allowClear
                showArrow
                mode="multiple"
              >
                {[{ tr: " Kadın", en: "female" }, { "tr": "Erkek", en: "male" }].map((day) => (
                  <Option value={day.en}>{day.tr}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Yaş aralığı" style={{ marginBottom: 0 }}>
              <Row gutter={24}>
                <Col md={12}>
                  <Form.Item name="start_age" rules={[
                    {
                      required: true,
                      message: "Gerekli alan"
                    }
                  ]}
                    validateTrigger="onBlur">
                    <InputNumber
                      style={{ width: 110 }}
                      placeholder="En Kuçuk Yaş"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item name="finish_age">
                    <InputNumber
                      style={{ width: 110 }}
                      max={100}
                      placeholder="En Büyük Yaş"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </>
        }

      </Form>
    </Modal>
  );
};

export default CoursesModal;
