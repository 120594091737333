import React from "react";
import { API_URL } from "../config";
import { Card, Checkbox, Divider, Form, Input } from "antd";
import logo from "../assets/images/logo512.png";
import { responseControl } from "../assets/scripts";

const PDFTemplate = React.forwardRef((props, ref) => {
  const [facility_name, setFacility_name] = React.useState([]);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.data !== undefined && Object.keys(props?.data).length > 0) {
      const dataWillRender = {
        ...props.data,
        full_name: props?.data?.first_name + " " + props?.data?.last_name,
      };

      Object.keys(dataWillRender).map((key) => {
        if (dataWillRender[key] === null || dataWillRender[key] === undefined) {
          form.setFieldsValue({ [key]: "" });
        }

        form.setFieldsValue({ [key]: dataWillRender[key] });
      });
    }

  }, [props]);

  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/system_datas`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {setFacility_name(result[0].facility_name);})
      .catch((error) => console.log(error));
  }, []) 

  return (
    <div ref={ref}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={logo}
            style={{ width: 75, height: 75, textAlign: "center" }}
          />
          <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
            T.C. GENÇLİK VE
          </h5>
          <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
            SPOR BAKANLIĞI
          </h5>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 style={{ textAlign: "center", fontWeight: "bold", textTransform: "uppercase" }}>
            {facility_name}
          </h3>
          <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
            ÜYE KAYIT FORMU
          </h3>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={logo}
            style={{ width: 75, height: 75, textAlign: "center" }}
          />
          <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
            T.C. GENÇLİK VE
          </h5>
          <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
            SPOR BAKANLIĞI
          </h5>
        </div>
      </div>
      <Divider style={{ backgroundColor: "black" }} />
      <Form
        style={{ fontSize: 8, margin: "0 auto" }}
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "row",
              }}
            >
              <Form.Item name="createdAt">
                <Input
                  value={
                    new Date(props?.data?.createdAt).toLocaleDateString(
                      "fr-BE"
                    ) +
                    " " +
                    new Date(props?.data?.createdAt).toLocaleTimeString("tr-TR")
                  }
                  addonBefore={
                    <div
                      style={{
                        marginLeft: 0,
                        padding: 5,
                        border: "1px solid",
                        boxShadow: "2px 3px",
                      }}
                    >
                      Kayıt Tarihi
                    </div>
                  }
                />
              </Form.Item>
              <Form.Item name="card_id">
                <Input
                  addonBefore={
                    <div
                      style={{
                        marginLeft: 0,
                        padding: 5,
                        border: "1px solid",
                        boxShadow: "2px 3px",
                      }}
                    >
                      Kayıt No
                    </div>
                  }
                />
              </Form.Item>
            </div>
            <Form.Item name="tc_no">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    TC Kimlik No
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="full_name">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Ad Soyad
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="birthplace">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Doğum Yeri
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="birthdate">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Doğum
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="father_name">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Baba Adı
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="mother_name">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Anne Adı
                  </div>
                }
              />
            </Form.Item>
            <div
              style={{
                zIndex: 1,
                padding: 5,
                position: "absolute",
                border: "1px solid",
                boxShadow: "2px 3px",
              }}
            >
              Cinsiyet
            </div>
            <Form.Item name="gender" style={{ marginLeft: 100 }}>
              <Checkbox.Group
                value={
                  props?.data?.gender === undefined ? null : props?.data?.gender
                }
              >
                <Checkbox value="male">Erkek</Checkbox>
                <Checkbox value="female">Kadın</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="profession">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Mesleği
                  </div>
                }
              />
            </Form.Item>
          </div>
          <Card
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              border: "1px solid black",
              width: 300,
              height: 350,
              marginLeft: 20,
              marginTop: 20,
            }}
          >
            <h1>FOTOĞRAF</h1>
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: 10 }}>
            <div
              style={{
                zIndex: 1,
                padding: 5,
                position: "absolute",
                border: "1px solid",
                boxShadow: "2px 3px",
              }}
            >
              Ev Adresi
            </div>
            <Form.Item name="address">
              <Input.TextArea
                size="large"
                style={{ paddingTop: 30 }}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item name="phone_number">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Telefon No
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="hes_code">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    HES Kodu
                  </div>
                }
              />
            </Form.Item>
            <Form.Item name="blood_type">
              <Input
                addonBefore={
                  <div
                    style={{
                      marginLeft: 0,
                      padding: 5,
                      border: "1px solid",
                      boxShadow: "2px 3px",
                    }}
                  >
                    Kan Grubu
                  </div>
                }
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
              width: 300,
            }}
          >
            <h4
              style={{
                textAlign: "center",
                border: "1px solid black",
                padding: 1,
              }}
            >
              İSTENİLEN BELGELER
            </h4>
            <div
              style={{
                border: "1px solid black",
                padding: 5,
                wordBreak: "break-word",
                height: 100,
              }}
            >
              <p>- KİMLİK FOTOKOPİSİ</p>
              <p>- 1 ADET VERİSAKLIK FOTOĞRAF</p>
              <p>
                - DURUM BİLDİRİR BELGESİ (KAMU PERSONEL BELGESİ, ŞEHİT YAKINI,
                GAZİ VE YAKINI, ÖĞRENCİ DURUM BELGESİ VS.)
              </p>
            </div>
            <div
              style={{
                border: "1px solid black",
                padding: 5,
                marginTop: 5,
                wordBreak: "break-word",
                height: 150,
              }}
            >
              <p style={{ textDecoration: "underline" }}>
                18 YAŞ ALTI KIZ/ERKEK ÇOCUK İÇİN VELİ MUVAFAKATNAMESİ
              </p>
              <p>
                GSB 24606 sayılı resmi gazetede yayınlanan spor aktivitelerine
                katılan üyelerin sağlık raporu ile ilgili 17. Maddesi gereğince
                yukarıda açık kimliği yazılı bulunan oğlum veya kızımın yüzme
                havuzuna üye olmasında herhangi bir sakınca (sağlık problemi)
                olmadığını beyan eder, yüzme sporunu yapmasına muvafakat
                (müsaade) ettiğimi bildiririm.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 20,
                }}
              >
                <p>VELİ ADI SOYADI:</p>
                <p>VELİ İMZA:</p>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div
        style={{
          border: "1px dotted black",
          width: "100%",
          padding: 5,
          height: 150,
          margin: "0 auto",
        }}
      >
        <p>
          Kırşehir Gençli ve Spor İl Müdürlüğüne ait yarı olimpik kapalı yüzme
          havuzuna üye olmak istiyorum. Kayıt formunu okudum. Havuz kullanım
          kurallarına uymayı,{" "}
          <span style={{ textDecoration: "undeline" }}>
            üyeliğim boyunca oluşabilecek her türlü sağlık problemimin
            sorumluluğunu kabul ediyorum.
          </span>
        </p>
        <p style={{ fontWeight: "bold" }}>...../...../20.....</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontWeight: "bold" }}>VELİ ADI SOYADI:</p>
          <p style={{ fontWeight: "bold" }}>VELİ İMZA:</p>
        </div>
      </div>
    </div>
  );
});

export default PDFTemplate;
