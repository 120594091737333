import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import "../assets/styles/pages/members.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { Button, Tag } from "antd";

const Members = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const initialColumns = [
      {
        title: "Adı",
        dataIndex: "first_name",
        key: "first_name",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Soyadı",
        dataIndex: "last_name",
        key: "last_name",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
      },
      {
        title: "Telefon",
        dataIndex: "phone_number",
        key: "phone_number",
        defaultSelected: true,
      },
      {
        title: "Kart No",
        dataIndex: "cards",
        key: "cards",
        defaultSelected: true,
        responsive: ["xs", "md", "lg"],
        render: (cards) => {
          return cards[0].card_number;
        },
      },
      {
        title: "Tarife",
        dataIndex: "schedules",
        key: "schedule_name",
        defaultSelected: true,
        render: (items, row) => {
          return items?.map(i => i.schedule_name)?.join(", ")
        }
      },
      {
        title: "Spor Klübü",
        dataIndex: ["clubs", 0, "club_name"],
        key: "club_name",
      },
      {
        title: "Sporcu",
        dataIndex: "membership",
        key: "membership",
        defaultSelected: false,
        responsive: ["xs", "md", "lg"],
        render: (data) => {
          const membership = typeof data === "string" ? JSON.parse(data) : data;
          if (membership) {
            return <Tag color="green">EVET</Tag>

          } return <Tag >Sistemde kayıtlı değil</Tag>
        },
      },
      {
        title: "Antrenörler",
        dataIndex: ["trainers", 0, "full_name"],
        key: "trainer_name",
        defaultSelected: false
      },
      {
        title: "Kurslar",
        dataIndex: "courses",
        key: "courses",
        render: (items) => {
          return items?.map(c => {
            return <p>{c.course_name} | Tekrar sayısı {c.repetition}</p>
          })
        }
      },
      {
        title: "Tesis",
        dataIndex: ["facility", "name"],
        key: "facility",
        defaultSelected: false,
      },
      {
        title: "TCKN",
        dataIndex: "tc_no",
        key: "tc_no",
      },
      {
        title: "Doğum Yeri",
        dataIndex: "birthplace",
        key: "birthplace",
      },
      {
        title: "Doğum Tarihi",
        dataIndex: "birthdate",
        key: "birthdate",
        render: (item, row, idx) => {
          if (item === null) return null;
          return (
            <p>
              {new Date(item)
                .toLocaleDateString("tr-TR")
                .split(".")
                .reverse()
                .join("-")}
            </p>
          );
        },
      },
      {
        title: "Baba Adı",
        dataIndex: "father_name",
        key: "father_name",
      },
      {
        title: "Ana Adı",
        dataIndex: "mother_name",
        key: "mother_name",
      },
      {
        title: "Cinsiyet",
        dataIndex: "gender",
        key: "gender",
        render: (item) =>
          item === "male"
            ? "Erkek"
            : item === "female"
              ? "Kadın"
              : "Belirtilmemiş",
        filters: [
          {
            text: "Erkek",
            value: "male",
          },
          {
            text: "Kadın",
            value: "female",
          },
        ],
        onFilter: (value, record) => record?.gender?.indexOf(value) === 0,
      },
      {
        title: "Meslek",
        dataIndex: "profession",
        key: "profession",
      },
      {
        title: "Adres",
        dataIndex: "address",
        key: "address",
      },

      {
        title: "EV/İŞ Telefon",
        dataIndex: "home_phone",
        key: "home_phone",
      },
      {
        title: "Acıl Telefon",
        dataIndex: "urgent_phone",
        key: "urgent_phone",
      },
      {
        title: "e-Mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "HES Kodu",
        dataIndex: "hes_code",
        key: "hes_code",
      },
      {
        title: "Kan Grubu",
        dataIndex: "blood_type",
        key: "blood_type",
      },
      {
        title: "Uyruk",
        dataIndex: "nationality",
        key: "nationality",
      },

      {
        title: "Kayıt Tarihi",
        dataIndex: "createdAt",
        key: "createdtAt",
        render: (item, row, idx) => {
          if (item === null) return null;
          return (
            <p>
              {new Date(item)
                .toLocaleDateString("tr-TR")
                .split(".")
                .reverse()
                .join("-")}{" "}
              {new Date(item)
                .toLocaleTimeString("tr-TR")
                .split(".")
                .reverse()
                .join("-")}
            </p>
          );
        },
        defaultSelected: false,
      },
      {
        title: "Bakiye",
        dataIndex: ["cards", 0, "credits"],
        key: "credits",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
      },
      {
        title: "Personel",
        dataIndex: "is_employee",
        key: "cris_employee",
        responsive: ["xs", "md", "lg"],
        defaultSelected: true,
        render: (bool) => {
          const isEmployee = bool ? "Evet" : "Hayır"
          return isEmployee;
        },
      },
      {
        title: "Onaylandı",
        dataIndex: "registered",
        key: "registered",
        render: (item) =>
          item === true ? <p>Kayıtlı</p> : <p>Onay bekliyor</p>,
        filters: [
          {
            text: "Kayıtlı",
            value: true,
          },
          {
            text: "Onay bekliyor",
            value: false,
          },
        ],
        onFilter: (value, record) => record.registered === value,
      },
      {
        title: "İşlemler",
        dataIndex: "actions",
        key: "actions",
        render: (item, row, idx) => (
          <TableActions
            record={row}
            getPDF={true}
            card_id={row.card_id}
            type="member"
            tcNo={row.tc_no}
            fullName={(row.first_name + " " + row.last_name)?.toLocaleUpperCase("tr-TR")}
            create_card={true}
            onClick={() => deleteRecord(`id=${row.id}`, "members")}
          />
        ),
        width: 750,
        fixed: "right",
      },
    ];
    setColumns(initialColumns);

    fetch(
      `${API_URL}/api/v1/members?include=card,club,schedule,trainer,facilities`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        return setDataSource(result);
      })

      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="ÜYELER"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        uploadExcel
        type="member"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Members;
