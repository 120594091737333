import { Typography } from "antd";
import React from "react";

const Now = () => {
  const [now, setNow] = React.useState("");

  React.useEffect(() => {
    const nowInterval = setInterval(() => {
      const nowDateTime =
        new Date().toLocaleDateString("tr-TR") +
        " " +
        new Date().toLocaleTimeString("tr-TR");
      setNow(nowDateTime);
    }, 1000);

    return () => clearInterval(nowInterval);
  }, []);
  return (
    <Typography.Title
      className="exit-button"
      style={{ marginTop: 18, fontSize: 20 }}
      level={1}
    >
      {now}
    </Typography.Title>
  );
};

export default Now;
