import { API_URL } from "../config";
import React from "react";
import "../assets/styles/components/memberModal.css";
import {
  Button,
  Form,
  Input,
  Modal,
  InputNumber,
  Select,
  message,
  DatePicker,
  Upload,
  Spin,
  Divider,
  Image,
  Radio,
  Row,
  Col,
  notification,
} from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  SkinOutlined,
  DashboardOutlined,
  LoadingOutlined,
  UploadOutlined,
  CameraOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { cities, responseControl } from "../assets/scripts";
import moment from "moment";
import Takephoto from "./TakePhoto";
import nations from "../assets/lists/nations";
import jsonwebtoken from "jsonwebtoken";

const { Option } = Select;

const layout = {
  labelCol: { span: 24, offset: 4 },
  wrapperCol: { span: 24, offset: 4 },
};

const radioStyle = {
  color: "red",
};

const memberships = {
  club: { type: "Kulüp Üyesi", icon: <SkinOutlined /> },
  trainer: { type: "Performans Sporcusu", icon: <DashboardOutlined /> },
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const blood_types = ["A+", "A-", "B-", "O+", "O-", "AB+", "AB-", "B+"];
const MembersModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [currentCredit, setCurrentCredit] = React.useState(0);
  const [amount, setAmount] = React.useState(null);
  const [selectedSchedule, setSelectedSchedule] = React.useState({});
  const [membership, setMembership] = React.useState("");
  const [schedules, setSchedules] = React.useState([]);
  const [clubs, setClubs] = React.useState([]);
  const [trainers, setTrainers] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);
  const [isPhoto, setIsPhoto] = React.useState(null);
  const [photoWillSend, setPhotoWillSend] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPhotoChanged, setPhotoChanged] = React.useState(false);
  const [memberWillChange, setMemberWillChange] = React.useState(false);
  const [form] = Form.useForm();
  const [isEmployee, setIsEmployee] = React.useState(false);

  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      const { birthdate, ...rest } = props.record;

      getPhotos(props?.record?.card_id).then((result) => {
        setPhotoWillSend(result?.photo);
      });
      if (props.record?.clubs?.length > 0) {
        setMembership("club");
      } else if (props.record?.trainers?.length > 0) {
        setMembership("trainer");
      }
      form.setFieldsValue({
        birthdate: birthdate === null ? null : moment(birthdate),
        ...rest,
        schedule: rest.schedule?.filter(Boolean),
        currentCredit: 0,
        card_number: rest.cards[0].card_number,

      });
    }

    if (Object.keys(props.record).length > 0) {
      const { birthdate, ...rest } = props.record;
      setIsEmployee(rest.is_employee);
      if (props.record?.clubs?.length > 0) {
        setMembership("club");
      } else if (props.record?.trainers?.length > 0) {
        setMembership("trainer");
      }

      setFields({
        birthdate: birthdate === null ? null : moment(birthdate),
        card_number: rest.cards[0].card_number,

        ...rest,
      });
      setCurrentCredit(props?.record?.cards[0]?.credits || 0);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();

      setFields({});
      setPhotoWillSend("");
      setIsUpdate(false);
    }
    getSchedules()
      .then((result) => {
        setSchedules(result);
      })
      .catch((error) => console.log(error));
  }, [props, form]);

  React.useEffect(() => {
    if (membership === "club") {
      getClubs()
        .then((result) => {
          setClubs(result);
        })
        .catch((error) => message.error(error))
        .finally(() => setIsFetching(false));
    } else if (membership === "trainer") {
      getTrainers()
        .then((result) => setTrainers(result))
        .catch((error) => message.error(error))
        .finally(() => setIsFetching(false));
    }
  }, [membership]);

  const getSchedules = async () => {
    return await fetch(`${API_URL}/api/v1/schedules`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const deleteZeroFromCardNum = (card_number) => {
    console.log(typeof card_number);
    let patt = /^0/;
    let result = patt.test(card_number);

    if (result === true) {
      var cardnum = card_number.replace(/^0*/g, "");

      return cardnum;
    }
    return card_number;
  };

  const getClubs = async () => {
    return await fetch(`${API_URL}/api/v1/clubs`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const getTrainers = async () => {
    return await fetch(`${API_URL}/api/v1/trainers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => console.log(error));
  };

  const getPhotos = async (card_id) =>
    await fetch(`${API_URL}/api/v1/photos/${card_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {
        return result;
      })
      .catch((error) => console.log(error));

  const handleMembership = (value) => setMembership(value);

  const handleSelectedSchedule = (value) => {
    schedules.map((schedule) => {
      if (value === schedule.id) {
        setSelectedSchedule(schedule);
      }
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (!["credits", "payment_method"].includes(name)) {
      setMemberWillChange(true);
    }
    if (["first_name", "last_name"].includes(name)) {
      return setFields({ ...fields, [name]: value?.toUpperCase() });
    }
    return setFields({ ...fields, [name]: value });
  };

  const handleClick = async () => {
    const { card_number, credits, schedule, cards, ...rest } =
      fields;
    if (fields?.password?.length === 0) {
      delete fields.password;
    }
    rest.schedule = schedule;
    if (isUpdate) {
      delete rest.first_name;
      delete rest.last_name;
      delete rest.nationality;
      delete rest.tc_no;
      delete rest.birthdate;
      delete rest.birthplace;
      delete rest.father_name;
      delete rest.mother_name;
      delete rest.gender;
    }
    try {
      let memberResult = [];
      if (memberWillChange) {
        memberResult = await fetch(`${API_URL}/api/v1/members`, {
          method: isUpdate ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: isUpdate
            ? JSON.stringify({
              body: { ...rest, credits },
              where: { id: props.record.id },
            })
            : JSON.stringify({ ...rest, credits }),
        })
          .then((response) => responseControl(response))
          .then(async (result) => result);
      }
      if (photoWillSend?.length > 0) {
        uploadFile(memberResult);
      }
      if (memberResult?.errors?.length > 0) {
        //message.error(memberResult?.errors[0]?.message);
      } else {
        await fetch(`${API_URL}/api/v1/cards`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            body: {
              credits,
              card_number: deleteZeroFromCardNum(card_number),

            },
            where: {
              card_id: isUpdate ? cards[0]?.card_id : memberResult?.card_id,
            },
          }),
        })
          .then((response) => responseControl(response))
          .then((result) => result);

        if (amount) {
          await fetch(`${API_URL}/api/v1/payments`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              card_id: fields?.cards[0]?.card_id || fields?.card_id,
              //prettier-ignore
              card_number: isUpdate && fields?.card_number ? fields?.card_number : fields?.cards[0]?.card_number,
              amount,
              payment_method:
                amount < 0 ? "credit_cutting" : rest.payment_method,
            }),
          })
            .then((response) => responseControl(response))
            .then((result) => result);
        }
      }
      if (
        fields.first_name &&
        fields.last_name &&
        fields.card_number &&
        fields.tc_no &&
        fields.birthdate
      ) {
        window.location.reload();
      } else {
        notification.error({
          message: "Kırmızı ile İşaretli Alanları Doldurunuz",
        });
      }
    } catch (error) {
      notification.error({ message: error?.message || error });
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handlePhotoChange = (info) => {
    // setPhotoChanged(true);
    setMemberWillChange(true);
    getBase64(info.file.originFileObj, (imageUrl) => {
      setPhotoWillSend(imageUrl);
      console.log(imageUrl);
    });
  };

  const uploadFile = (result) => {
    const data = {
      base64: photoWillSend,
      card_id:
        fields?.card_id === undefined ? result?.card_id : fields?.card_id,
      date: new Date().getTime(),
    };

    fetch(`${API_URL}/api/v1/photos`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => responseControl(response))
      .then((result) => console.log(result))
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
  };

  const decodedToken = jsonwebtoken.verify(
    localStorage.getItem("access_token"),
    process.env.REACT_APP_SECRET_KEY,
    (error, decoded) => {
      if (decoded) {
        return decoded;
      }
    }
  );

  const [authType, setAuthType] = React.useState("tc_no");

  return (
    <Modal
      visible={props.visible}
      wrapClassName="members-modal"
      onCancel={() => {
        props.setVisible(false);
        setIsUpdate(false);
        form.resetFields();
      }}
      destroyOnClose={true}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={null}
    >
      <Form
        id="member-form"
        key={props?.record?.id === undefined ? 0 : props.record.id}
        style={{
          margin: "5% 1%",
          padding: "5%",
        }}
        {...layout}
        form={form}
      >
        {/* <Form.Item
          name="profil-photo"
          style={
            isPhoto === true
              ? {}
              : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }
          }
        >
          <div
            style={{
              display: isPhoto === null ? "flex" : "none",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            {isUpdate ? (
              photoWillSend === "" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    border: "6px solid black",
                    width: 150,
                    height: 150,
                  }}
                >
                  <UserOutlined
                    style={{
                      fontSize: 100,
                    }}
                  />
                </div>
              ) : (
                <Image
                  style={{ width: 350, height: "auto" }}
                  src={photoWillSend}
                  alt="profil_photo"
                />
              )
            ) : null}
            <div
              style={{
                flexDirection: "row",
                marginBottom: 10,
                marginLeft: "-150px",
                paddingRight: "-250px",
                width: "100%",
                marginTop: 10,
                display: "flex",
              }}
            >
              <Button
                type="primary"
                icon={<CameraOutlined />}
                onClick={() => {
                  setIsPhoto(true);
                }}
              >
                Fotoğraf Çek
              </Button>
              <Divider onClick={() => setIsPhoto(true)} type="vertical" />
              <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={() => setIsPhoto(false)}
              >
                Karşıdan Yükle
              </Button>
            </div>
          </div>
          {isPhoto === true ? (
            <Takephoto
              getDataURL={setPhotoWillSend}
              backFunction={() => setIsPhoto(false)}
            />
          ) : isPhoto === false ? (
            photoWillSend?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Upload
                  onChange={handlePhotoChange}
                  maxCount={1}
                  multiple={false}
                  progress
                  listType="picture-card"
                  showUploadList={true}
                  accept=".png,.jpg,.jpeg,.jfif"
                >
                  <UploadOutlined />
                </Upload>
                <Button onClick={() => setIsPhoto(true)}>Fotoğraf Çek</Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                }}
              >
                <Image
                  style={{ width: 350, height: "auto", marginBottom: 10 }}
                  src={photoWillSend}
                  alt="profil_photo"
                />
                <Button
                  danger
                  type="primary"
                  htmlType="button"
                  style={{ width: 100 }}
                  onClick={() => {
                    setPhotoWillSend("");
                    setIsPhoto(null);
                  }}
                >
                  Resmi Sil
                </Button>
              </div>
            )
          ) : null}
        </Form.Item> */}
        <Row>
          <Col>
            <Form.Item
              label="Adı"
              name="first_name"
              rules={[
                {
                  required: true,
                  warningOnly: false,
                  type: "string",
                  whitespace: true,
                  message: "Lütfen üye adını giriniz",
                },
              ]}
            >
              <Input
                name="first_name"
                disabled={isUpdate}
                onChange={handleChange}
                style={{ textTransform: "uppercase" }}
              />
            </Form.Item>
            <Form.Item
              label="Soyadı"
              name="last_name"
              rules={[
                {
                  required: true,
                  warningOnly: false,
                  type: "string",
                  whitespace: true,
                  message: "Lütfen üye soyadını giriniz",
                },
              ]}
            >
              <Input
                name="last_name"
                disabled={isUpdate}
                style={{ textTransform: "uppercase" }}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Personel mi?"
              name="is_employee"
              rules={[
                {
                  required: true,
                  warningOnly: false,
                  type: "string",
                  whitespace: true,
                  message: "Lütfen alanı seçiniz",
                },
              ]}
            >
              <Select
                defaultValue={false}
                onChange={(value) => {
                  setIsEmployee(value);
                  handleChange({ target: { name: "is_employee", value } });
                }}
              >
                <Select.Option value={false}>Hayır</Select.Option>
                <Select.Option value={true}>Evet</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Kart No"
              name={["cards", 0, "card_number"]}
              rules={[
                {
                  required: true,
                  warningOnly: false,
                  type: "string",
                  whitespace: true,
                },
                () => ({
                  async validator(_, value) {
                    if (value === "") {
                      return Promise.reject(
                        new Error("Lütfen üye Kart numarasını giriniz.")
                      );
                    }

                    await fetch(
                      `${API_URL}/api/v1/cards?card_number=${value}`,
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                          )}`,
                        },
                      }
                    )
                      .then((response) => responseControl(response))
                      .then((result) => {
                        if (result?.length > 0) {
                          if (isUpdate) {
                            if (
                              fields.cards[0].card_number ===
                              result[0].card_number
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Bu kart numarası zaten var.")
                            );
                          }
                          return Promise.reject(
                            new Error("Bu kart numarası zaten var.")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      });
                  },
                }),
              ]}
              validateTrigger="onBlur"
            >
              <Input name="card_number" disabled={isUpdate} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Uyruk" name="nationality">
              <Select
                disabled={isUpdate}
                allowClear
                showSearch
                onSelect={(value) =>
                  handleChange({ target: { name: "nationality", value } })
                }
              >
                {nations.map((nation) => (
                  <Option value={nation}>{nation}</Option>
                ))}
              </Select>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <Radio.Group
                options={[
                  {
                    label: "TC Kimlik No.",
                    value: "tc_no",
                    style: radioStyle,
                  },
                  {
                    label: "Pasaport",
                    value: "passport",
                    style: radioStyle,
                  },
                ]}
                onChange={({ target: { value } }) => {
                  setAuthType(value);
                }}
                value={authType}
                optionType="button"
              />
            </div>
            {authType === "tc_no" ? (
              <Form.Item
                // label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TCKN"
                name="tc_no"
                rules={[
                  {
                    required: true,
                    warningOnly: false,
                    type: "string",
                    whitespace: true,
                  },
                  {
                    pattern: /^[1-9]{1}[0-9]{9}[02468]{1}$/,
                    message: "Lütfen kimlik numarasını düzgün yazınız",
                  },
                  () => ({
                    async validator(_, value) {
                      if (value === "") {
                        return Promise.reject(
                          new Error("Lütfen üye TC kimlik numarasını giriniz.")
                        );
                      } else if (isNaN(parseInt(value))) {
                        return Promise.reject(
                          new Error("Kimlik numarası sayılardan oluşmalı.")
                        );
                      }
                      if (!isNaN(parseInt(value))) {
                        await fetch(
                          `${API_URL}/api/v1/members?tc_no=${value}`,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                              )}`,
                            },
                          }
                        )
                          .then((response) => responseControl(response))
                          .then((result) => {
                            if (result?.length > 0) {
                              if (isUpdate) {
                                if (fields.tc_no === result[0].tc_no) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Bu kimlik numarası zaten var.")
                                );
                              }
                              return Promise.reject(
                                new Error("Bu kimlik numarası zaten var.")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          });
                      } else {
                        return Promise.reject(new Error("Harf yazmayın."));
                      }
                    },
                  }),
                ]}
                validateTrigger="onBlur"
              >
                <Input disabled={isUpdate} maxLength={11} name="tc_no" onChange={handleChange} />
              </Form.Item>
            ) : (
              <Form.Item
                name="tc_no"
                rules={[
                  {
                    required: true,
                    warningOnly: false,
                    type: "string",
                    whitespace: true,
                  },

                  () => ({
                    async validator(_, value) {
                      if (value === "") {
                        return Promise.reject(
                          new Error("Lütfen üye Pasaport No. giriniz.")
                        );
                      } else if (isNaN(parseInt(value))) {
                        return Promise.reject(
                          new Error("Pasaport No. sayılardan oluşmalı.")
                        );
                      }

                      await fetch(`${API_URL}/api/v1/members?tc_no=${value}`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                          )}`,
                        },
                      })
                        .then((response) => responseControl(response))
                        .then((result) => {
                          if (result?.length > 0) {
                            if (isUpdate) {
                              if (fields.tc_no === result[0].tc_no) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Bu kimlik numarası zaten var.")
                              );
                            }
                            return Promise.reject(
                              new Error("Bu kimlik numarası zaten var.")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        });
                    },
                  }),
                ]}
                validateTrigger="onBlur"
              >
                <Input disabled={isUpdate} name="tc_no" onChange={handleChange} />
              </Form.Item>
            )}

            <Form.Item label="Kan Grubu" name="blood_type">
              <Select
                allowClear
                showArrow
                onSelect={(value) => {
                  handleSelectedSchedule(value);
                  handleChange({ target: { name: "blood_type", value } });
                }}
              >
                {blood_types.map((blood_type) => (
                  <Option value={blood_type}>{blood_type}</Option>
                ))}
              </Select>
            </Form.Item>

          </Col>
          <Col>
            <Form.Item label="Doğum Yeri" name="birthplace">
              <Select
                disabled={isUpdate}
                allowClear
                showSearch
                onSelect={(value) =>
                  handleChange({ target: { name: "birthplace", value } })
                }
              >
                {cities.map((city) => (
                  <Option value={city}>{city}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Doğum Tarihi"

              name="birthdate"
              rules={[
                {
                  required: true,
                  warningOnly: false,
                  type: "date",
                  whitespace: true,
                  message: "Lütfen doğum tarhihi giriniz",
                },
              ]}
            >
              <DatePicker
                disabled={isUpdate}
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                onChange={(date, dateIndex) =>
                  handleChange({
                    target: {
                      name: "birthdate",
                      value: dateIndex.split("-").reverse().join("-"),
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Baba Adı" name="father_name">
              <Input name="father_name" disabled={isUpdate} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Ana Adı" name="mother_name">
              <Input name="mother_name" disabled={isUpdate} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Cinsiyet" name="gender">
              <Select
                showArrow
                disabled={isUpdate}
                onSelect={(value) =>
                  handleChange({ target: { name: "gender", value } })
                }
              >
                <Option value="male">Erkek</Option>
                <Option value="female">Kadın</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Meslek" name="profession">
              <Input name="profession" onChange={handleChange} />
            </Form.Item>

          </Col>
          <Col>
            <Form.Item label="Adres" name="address">
              <Input name="address" onChange={handleChange} />
            </Form.Item>
            <Form.Item label="e-Mail" name="email">
              <Input name="email" onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Ev/İş Telefon Numarası" name="home_phone">
              <Input name="home_phone" onChange={handleChange} />
            </Form.Item>
            <Form.Item
              label="Telefon Numarası"
              rules={[
                {
                  pattern:
                    /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/,
                  message: "Telefon formatı yanlış veya sonuna boşluk var",
                },
              ]}
              name="phone_number"
            >
              <Input name="phone_number" onChange={handleChange} />
            </Form.Item>

            <Form.Item
              label="Acil Durum - Telefon Numarası"
              name="urgent_phone"
            >
              <Input name="urgent_phone" onChange={handleChange} />
            </Form.Item>

            {/* <Form.Item
              label="HES Kodu"
              name="hes_code"
              rules={[
                {
                  warningOnly: false,
                  type: "string",
                  whitespace: true,
                },
                () => ({
                  validator(_, value) {
                    var regex = new RegExp("[^A-Z|^a-z|^0-9]");
                    if (regex.test(value) === true) {
                      return Promise.reject(
                        new Error("HES Kodu özel karakter içermemeli.")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              validateTrigger="onBlur"
            >
              <Input name="hes_code" onChange={handleChange} />
            </Form.Item> */}

            {isEmployee === false && (
              <>
                <Form.Item label="Tarife" name="schedule">
                  <Select
                    showSearch
                    mode='multiple'
                    onChange={(value) => {
                      handleSelectedSchedule(value);
                      handleChange({ target: { name: "schedule", value } });
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {typeof schedules?.map === "function"
                      ? schedules?.map((schedule) => {
                        const schedulename =
                          schedule.schedule_name.charAt(0).toUpperCase() +
                          schedule.schedule_name.slice(1);

                        const disable = schedule.count >= schedule.capacity;

                        return disable ? (
                          <>
                            <Option value={schedule.id} disabled>
                              {schedulename}
                            </Option>
                          </>
                        ) : (
                          <>
                            <Option value={schedule.id}>
                              {schedulename}
                            </Option>
                          </>
                        );
                      })
                      : null}
                  </Select>
                </Form.Item>


                {selectedSchedule.verification_required ? (
                  <Form.Item label="Onayla" name="registered">
                    <Select
                      showArrow
                      onSelect={(value) =>
                        handleChange({ target: { name: "registered", value } })
                      }
                    >
                      <Option value={true}>Evet</Option>
                      <Option value={false}>Hayır</Option>
                    </Select>
                  </Form.Item>
                ) : null}


              </>
            )}
          </Col>
          <Col
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "4.5%",
            }}
          >
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ marginRight: "1%" }}
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={() => props.setVisible(false)}
                >
                  {props.type === "request" ? "Reddet" : "İptal"}
                </Button>
                <Button
                  type="primary"
                  loading={isLoading}
                  icon={<SaveOutlined />}
                  onClick={async (event) => {
                    if (
                      !fields.payment_method &&
                      fields?.credits > currentCredit
                    ) {
                      return notification.error({
                        message:
                          "Bakiye girdikten, sonra ödeme yöntemini seçin",
                      });
                    } else {
                      setIsLoading(true);
                      await handleClick();
                      setIsLoading(false);
                    }
                  }}
                  htmlType="submit"
                >
                  Kaydet
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MembersModal;
