import { API_URL } from "../../config";
import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { message } from "antd";

const ColumnChart = (props) => {
  var config = {
    xField: "month",
    yField: "payments",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  const [payments, setPayments] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (
      props?.allowedEndpoints?.includes("payments") ||
      props?.allowedEndpoints?.includes("*")
    ) {
      fetch(`${API_URL}/api/v1/payments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data?.original?.severity === "ERROR") {
                message.error(data?.original?.detail);
              } else {
                setPayments(data);
              }
            });
          } else {
            return response.text().then((text) => {
              console.log(text);
              message.error("Hata!");
            });
          }
        })
        .catch((error) =>
          error instanceof Error
            ? message.error(error.message)
            : typeof error === "string"
            ? message.error(error)
            : console.log(error)
        );
    }
  }, [props]);

  useEffect(() => {
    if (payments.length > 0) {
      let monthlyPayments = {};
      let monthlyPaymentsArr = [];
      payments.reverse().map((payment) => {
        const paymentYear = new Date(payment.createdAt).getFullYear();
        //prettier-ignore
        const paymentMonth = new Date(payment.createdAt).toLocaleString("tr", {month: "long"});

        monthlyPayments[`${paymentMonth} ${paymentYear}`] =
          monthlyPayments[`${paymentMonth} ${paymentYear}`] === undefined
            ? parseInt(payment.amount)
            : parseInt(monthlyPayments[`${paymentMonth} ${paymentYear}`]) +
              parseInt(payment.amount);
      });
      const getMontlyPayments = async () =>
        Promise.all(
          Object.keys(monthlyPayments).map((key) => {
            monthlyPaymentsArr.push({
              month: key,
              payments: monthlyPayments[key],
            });
          })
        );
      getMontlyPayments().then(() => setChartData(monthlyPaymentsArr));
    }
  }, [payments]);

  return <Column data={chartData} {...config} />;
};

export default ColumnChart;
