import React from "react";
import { Button, Card, Form, Input, Select, notification } from "antd";
import { API_URL } from "../config";
import { responseControl } from "../assets/scripts";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const { Option } = Select;

const BankAccount = () => {
  const [systemData, setSystemData] = React.useState({});

  const endpoints = [
    {
      keyword: "test",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "asseco",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "isbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "akbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "finansbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "denizbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "kuveytturk",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "halkbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "anadolubank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "hsbc",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "ziraatbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "ingbank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "citibank",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
    {
      keyword: "teb",
      getBankName: function () {
        return this.keyword.toUpperCase();
      },
    },
  ];

  const handleChange = (event) =>
    setSystemData({ ...systemData, [event.target.name]: event.target.value });

  const handleClick = () => {
    return fetch(`${API_URL}/api/v1/facility_bank_accounts`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...systemData,
      }),
    })
      .then((response) => responseControl(response))
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Card style={{ margin: "1%" }}>
        <Form
          style={{ width: "50%", float: "left" }}
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 6,
          }}
        >
          <Form.Item label="Banka Adı" name="bank">
            <Select
              allowClear
              showSearch
              onSelect={(value) =>
                handleChange({ target: { name: "bank", value } })
              }
            >
              {endpoints.map((endpoint) => (
                <Option value={endpoint.keyword}>
                  {endpoint.getBankName()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="3D Kullanıcı Adı" name="name">
            <Input
              autoComplete="off"
              spellCheck={false}
              autoCorrect="off"
              name="name"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="3D Parola" name="password">
            <Input
              autoComplete="off"
              spellCheck={false}
              autoCorrect="off"
              name="password"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Mağaza Numarası" name="client_id">
            <Input
              className="store-id"
              autoComplete="off"
              spellCheck={false}
              autoCorrect="off"
              type={"number"}
              name="client_id"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Mağaza Anahatarı" name="storekey">
            <Input
              autoComplete="off"
              spellCheck={false}
              autoCorrect="off"
              name="storekey"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Mağaza Tipi" name="storetype">
            <Select
              allowClear
              showSearch
              onSelect={(value) =>
                handleChange({ target: { name: "storetype", value } })
              }
            >
              {["3d", "3d_pay_hosting"].map((type) => (
                <Option value={type}>{type}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 20, offset: 12 }}>
            <Button htmlType="submit" onClick={handleClick} type="primary">
              Değişiklikleri Kaydet
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ErrorBoundary>
  );
};

export default BankAccount;
