import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord, responseControl } from "../assets/scripts";
import Sessions from "./Sessions";;

const getClubs = async () => {
  try {
    const response = await fetch(`${API_URL}/api/v1/pool_usages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    const result = await responseControl(response);
    return result;
  } catch (error) {
    return notification.error({ message: error?.message || error });
  }
};

const getSchedules = async () => {
  try {
    const response = await fetch(`${API_URL}/api/v1/schedules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    const result = await responseControl(response);
    return result;
  } catch (error) {
    return notification.error({ message: error?.message || error });
  }
};

const getTrainers = async () => {
  try {
    const response = await fetch(`${API_URL}/api/v1/trainers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    const result = await responseControl(response);
    return result;
  } catch (error) {
    return notification.error({ message: error?.message || error });
  }
};

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16, offset: 1 },
};

const ProgramModal = (props) => {

  const [entryRange, setEntryRange] = React.useState(null);
  const [schedules, setSchedules] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [clubs, setClubs] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [openInner, setOpenInner] = React.useState(false);
  const [openRanger, setOpenRanger] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [form] = Form.useForm();
  const [hasCourse, setHasCourse] = React.useState(false);
  const [courses, setCourses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasClub, setHasClub] = React.useState(false);
  const [clubLoading, setClubLoading] = React.useState(false);
  const [hasSub, setHasSub] = React.useState(false);
  const [subLoading, setSubLoading] = React.useState(false);
  const [subs, setSubs] = React.useState([]);

  React.useEffect(() => {
    setIsUpdate(false)
    form.resetFields();
    setOpenInner(false);
    setOpenRanger(false);
    setShowFilters(false);
  }, []);

  const [appointmentBased, setAppointmentBased] = React.useState(false)

  React.useEffect(() => {

    if (props?.record && Object.keys(props?.record).length > 0) {
      setIsUpdate(true);
      const {
        startline,
        deadline,
        filter,
        ...rest
      } = props.record;
      const filters = JSON.parse(filter);
      const inFilter = filters.in;
      const betweenFilter = filters.between;

      if (inFilter || betweenFilter) {
        setShowFilters(true)
      }
      let birthdate_range = null;
      let ranger_filter = null;
      if (betweenFilter) {
        ranger_filter = true;
        setOpenRanger(true);

        birthdate_range = betweenFilter[0].birthdate.map(b => moment(b))
      }
      let gender, schedule, card_id, birthdate, inner_filter;
      if (inFilter) {
        inner_filter = true;
        setOpenInner(true);
        inFilter.forEach(d => {
          const data = Object.entries(d);
          const [k, v] = data[0];
          switch (k) {
            case "gender":
              gender = v
              break;
            case "schedule":
              schedule = v
              break;
            case "card_id":
              card_id = v
              break;
            case "birthdate":
              birthdate = moment(v)
              break;
            default:
              break;
          }
        })
      }

      form.setFieldsValue({
        filters: Object.values(filters)?.length > 0,
        birthdate_range,
        startline: moment(startline),
        deadline: moment(deadline),
        filter: JSON.parse(filter),
        birthdate,
        ...rest,
        ranger_filter,
        inner_filter,
        gender,
        schedule,
        card_id,
        entry_time_range: Number(rest.entry_time_range),
        sessions: rest?.sessions?.map(s => ({ ...s, start_time: moment(s.start_time, "HH:mm:ss"), finish_time: moment(s.finish_time, "HH:mm:ss") }))
      });
      if (rest.category === "B") {
        setHasCourse(true);
      }
      if (rest.category === "A") {
        setHasClub(true);
      }
      if (rest.category === "D") {
        setHasSub(true);
      }
      setEntryRange(parseInt(rest.entry_time_range));
      if (rest.appointment_based) setAppointmentBased(true)
    }

  }, [props, form]);



  React.useEffect(() => {
    setIsLoading(true);
    setSubLoading(true);
    fetch(`${API_URL}/api/v1/courses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCourses(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    fetch(`${API_URL}/api/v1/subscriptions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setSubs(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setSubLoading(false));


    fetch(`${API_URL}/api/v1/members`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setMembers(result);
      })
      .catch((error) => console.log(error))
    getSchedules()
      .then((result) => {
        if (result instanceof Array) {
          return setSchedules(result);
        }
        setSchedules(["unauthorized"]);
        return notification.error({
          message:
            "Tarifeleri görüntüleme yetkiniz yok. Dilerseniz amirinizden bu yetkiyi talep edebilirsiniz.",
          duration: 0,
        });
      })
      .catch((error) => console.log(error));

    setClubLoading(true)
    getClubs().then((result) => {
      if (result instanceof Array) {
        return setClubs(result);
      }
    }).finally(() => setClubLoading(false));

  }, [])

  const weekdays = { "Monday": "Pazartesi", "Tuesday": "Salı", "Wednesday": "Çarşamba", "Thursday": "Perşembe", "Friday": "Cuma", "Saturday": "Cumartesi", "Sunday": "Pazar" };






  const [loading, setLoading] = React.useState(false);
  const handleClick = async () => {
    setLoading(true)
    let fieldsWillSend = {};
    const {
      createdAt,
      updatedAt,
      gender,
      birthdate,
      birthdate_range,
      card_id,
      schedule,
      ...rest
    } = form.getFieldsValue();
    const filter = {};
    if (birthdate_range) {
      filter.between = [{ birthdate: birthdate_range.map(b => b.format("YYYY-MM-DD")) }]
    }
    if (gender || schedule?.[0] || card_id || birthdate) {
      filter.in = [{ gender }, { schedule }, { card_id }, { birthdate }].filter(d => Object.values(d).some(d => ![null, undefined].includes(d))).map(d => {
        const data = Object.entries(d);
        const [k, v] = data[0];
        return { [k]: k === "birthdate" ? [v.format("YYYY-MM-DD")] : [v].flat(Infinity) }
      })
    }

    fieldsWillSend = {
      ...rest,
      filter
    };

    if (fieldsWillSend.filters) delete fieldsWillSend.filters;
    if (fieldsWillSend.inner_filter) delete fieldsWillSend.inner_filter;
    if (fieldsWillSend.ranger_filter) delete fieldsWillSend.ranger_filter;
    fieldsWillSend.deadline = fieldsWillSend.deadline.toISOString();
    fieldsWillSend.startline = fieldsWillSend.startline.toISOString();


    // delete null and undefined values;
    Object.entries(fieldsWillSend).forEach((field) => {
      if (
        Boolean(Array.isArray(field[1]) ? field[1].length : field[1]) === false
      ) {
        delete fieldsWillSend[field[0]];
      }
      if (field[0] === "filter") {
        const filters = field[1]
        // delete empty params in filter
        filters.in = filters?.in?.filter((param) => {
          return Boolean(Object.values(param).flat(Infinity)?.length) === true;
        });

        if (!openInner) {
          delete filters?.in;
        }
        if (!openRanger) {
          delete filters.between;
        }

        fieldsWillSend[field[0]] = JSON.stringify(filters);

        if (fieldsWillSend[field[0]]?.length <= 0) {
          delete fieldsWillSend[field[0]];
        }
      }
    });

    const sessions = form.getFieldsValue()?.sessions?.map(s => {
      return ({ ...s, start_time: s.start_time.format("HH:mm:ss"), finish_time: s.finish_time.format("HH:mm:ss") });
    });


    console.log({ appointment_based: rest.appointment_based, });

    if (!sessions?.length) {
      notification.error({ message: "Seans eksik" })
    } else {
      return await fetch(`${API_URL}/api/v1/programs`, {
        method: isUpdate ? "PUT" : "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: isUpdate
          ? JSON.stringify({
            body: { ...fieldsWillSend, appointment_based: rest.appointment_based, sessions },
            where: { id: props.record.id },
          })
          : JSON.stringify({ ...fieldsWillSend, sessions }),
      })
        .then((response) => response.json())
        .then(() => window.location.reload())
        .catch((error) =>
          notification.error({ message: error?.message || error })
        ).finally(() => { setLoading(false) })
    }
  };

  const innerColumns = [
    {
      label: "Doğum yılı",
      value: "birthdate",
      component: (
        <DatePicker
          picker="year"
          allowClear
          style={{ width: "100%" }}
          locale={{ ...locale }}
          format="YYYY"

        />
      ),
    },
    {
      label: "Cinsiyet",
      value: "gender",
      component: (
        <Select
          allowClear
          options={[{ value: "male", label: "Erkek", }, { value: "female", label: "Kadın" }]}
        />
      ),
    },

    {
      label: "Tarifeler",
      value: "schedule",
      component: (
        <Select
          allowClear
          mode="multiple"
          removeIcon
          showArrow
          showSearch
          disabled={
            schedules.length === 1 && schedules.includes("unauthorized")
          }

          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.children.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {schedules.map((schedule) => (
            <Option value={schedule.id}>{schedule.schedule_name}</Option>
          ))}
        </Select>
      ),
    },

    {
      label: "Üyeler",
      value: "card_id",
      component: (
        <Select

          disabled={members.length === 1 && members.includes("unauthorized")}
          allowClear
          mode="multiple"
          removeIcon
          showArrow
          showSearch

          filterOption={(input, option) =>
            option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
          }

        >
          {members.length > 0
            ? members.map((member) => {
              return member.card_id ? (
                <Option
                  key={
                    member.first_name +
                    " " +
                    member.last_name +
                    "-" +
                    member.tc_no
                  }
                  value={member.card_id}
                >
                  {member.first_name +
                    " " +
                    member.last_name +
                    "-" +
                    member.tc_no}
                </Option>
              ) : undefined;
            })
            : undefined}
        </Select>
      ),
    },
  ];

  const rangerColumns = [
    {
      label: "Doğum yılı",
      value: "birthdate_range",
      component: (
        <DatePicker.RangePicker
          allowClear
          style={{ width: "100%" }}
          locale={{ ...locale }}
          format="YYYY-MM-DD"

        />
      ),
    },
  ];



  return (
    <Modal
      visible={props.visible}
      onCancel={() => {
        setIsUpdate(false)
        form.resetFields();
        setOpenInner(false);
        setOpenRanger(false);
        setShowFilters(false);
        props.setVisible(false);
        props.setRecord(null);
      }}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {isUpdate ? (
            <Popconfirm
              title="Kaydı silmek istediğinize emin misiniz?"
              onConfirm={() =>
                deleteRecord(`id=${props.record.id}`, "programs")
              }
              okText="Evet"
              cancelText="Hayır"
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Etkinliği Sil
              </Button>
            </Popconfirm>
          ) : (
            <div></div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => {
                setIsUpdate(false)
                form.resetFields();
                setOpenInner(false);
                setOpenRanger(false);
                setShowFilters(false);
                props.setVisible(false);
                props.setRecord(null);
              }}
            >
              {props.type === "request" ? "Reddet" : "İptal"}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
              loading={loading}
            >
              Kaydet
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%", }} {...layout} form={form} >
        <Form.Item label="Kategori" name="category">
          <Select
            allowClear
            showArrow
            onChange={(value) => {
              if (value === "B") { setHasCourse(true); } else { setHasCourse(false) }
              if (value === "A") { setHasClub(true); } else { setHasClub(false) }
              if (value === "D") { setHasSub(true); } else { setHasSub(false) }
            }
            }
          >
            {[{
              name: "Spor Kulübü",
              key: "A"
            }, {
              name: "Kurs",
              key: "B"
            },
            {
              name: "Abonelik seansı",
              key: "D"
            },
            {
              name: "Genel / Halk seans",
              key: "C"
            },].map((day) => (
              <Option value={day.key}>{day.name}</Option>
            ))}
          </Select>
        </Form.Item>
        {
          hasCourse && <Form.Item label="Kurs" name="course_id">
            <Select
              allowClear
              showArrow
              loading={isLoading}
              onChange={(value) => {
                const course = courses.find(c => c.id === value);
                setEntryRange(60)
                form.setFieldsValue({
                  startline: moment(course.start_date),
                  deadline: moment(course.end_date),
                  title: course.name,
                  capacity: course.total_students,
                  entry_time_range: 60,
                  sessions: course.days.map(d => ({ day: weekdays[d], start_time: moment(course.start_time, "HH:mm:ss"), finish_time: moment(course.finish_time, "HH:mm:ss") }))
                });
              }
              }
            >
              {courses?.map((c) => (
                <Option value={c.id}>{c.name}</Option>
              ))}
            </Select>
          </Form.Item>
        }
        {
          hasClub && <Form.Item label="Kulüp" name="club_id">
            <Select
              allowClear
              showArrow
              loading={clubLoading}
              onChange={(value) => {
                const club = clubs.find(c => c.id === value);
                const invertedtWeek = Object.entries(weekdays).reduce((a, [k, v]) => {
                  a[v] = k;
                  return a
                }, {})
                setEntryRange(60)
                form.setFieldsValue({
                  startline: moment(club.time_range[0]),
                  deadline: moment(club.time_range[1]),
                  title: club.club_name,
                  capacity: club.members_number,
                  entry_time_range: 60,
                  sessions: club.days.map(d => ({ day: invertedtWeek[d], start_time: moment(club.hour_range?.[0], "HH:mm:ss"), finish_time: moment(club.hour_range?.[1], "HH:mm:ss") }))
                });

              }
              }
            >
              {clubs?.map((c) => (
                <Option value={c.id}>{c.club_name}</Option>
              ))}
            </Select>
          </Form.Item>
        }
        {
          hasSub && <Form.Item label="Abonelikler" name="sub_id">
            <Select
              allowClear
              showArrow
              loading={subLoading}
              onChange={(value) => {
                const sub = subs.find(c => c.id === value);
                const startline = moment(sub.createdAt);
                const deadline = moment(sub.createdAt).add(sub.duration * 2, "month")
                setEntryRange(60)
                form.setFieldsValue({
                  startline,
                  deadline,
                  title: sub.name,
                  capacity: sub.capacity,
                  entry_time_range: 60,
                });


              }
              }
            >
              {subs?.map((c) => (
                <Option value={c.id}>{c.name}</Option>
              ))}
            </Select>
          </Form.Item>
        }

        <Form.Item label="Ad" name="title">
          <Input name="title" />
        </Form.Item>
        <Form.Item label="Açıklama" name="description">
          <Input.TextArea
            name="description"
            allowClear
            showCount
          />
        </Form.Item>


        <Form.Item label="Başlangıç Tarihi" name="startline">
          <DatePicker
            style={{ width: "100%" }}
            locale={{ ...locale }}
            format="YYYY-MM-DD"

          />
        </Form.Item>
        <Form.Item label="Bitiş Tarihi" name="deadline">
          <DatePicker
            style={{ width: "100%" }}
            locale={{ ...locale }}
            format="YYYY-MM-DD"

          />
        </Form.Item>

        <Form.Item
          label="Erken/Geç giriş"
          name="entry_time_range"
        >
          <Radio.Group

          >
            <Radio value={5}>5 dk.</Radio>
            <Radio value={15}>15 dk.</Radio>
            <Radio value={20}>20 dk.</Radio>
            <Radio value={30}>30 dk.</Radio>
            <Radio value={60}>Süre Kısıtlaması Olmasın</Radio>
          </Radio.Group>
        </Form.Item>

        <Sessions />


        <Form.Item
          label="Randevu ile giriş"
          name="appointment_based"
          valuePropName="checked"
        >
          <Checkbox
            onChange={e => setAppointmentBased(e.target.checked)}
          >
            Evet
          </Checkbox>
        </Form.Item>

        <Form.Item label="Program Capasitesi" name="capacity">
          <Input
            type={"number"}
            style={{ width: "100%" }}

          />
        </Form.Item>
        {
          appointmentBased &&
          <Form.Item
            label="Kaç gün içinde randevu alınır"
            name="max_days"
          >
            <InputNumber min={1} max={7} />
          </Form.Item>}

        <Form.Item name="filters" valuePropName="checked" >
          <Checkbox onChange={() => setShowFilters(s => !s)}>
            Filtreleri Göster
          </Checkbox>
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            width: "100%",
          }}
        >

          <Form.Item style={{ width: "100%", marginLeft: 10 }} name="inner_filter" valuePropName="checked" >
            {showFilters && <Checkbox
              style={{ marginBottom: 10 }}
              onChange={() => setOpenInner(s => !s)}
            >
              İçindedir
            </Checkbox>}
          </Form.Item>

          {openInner && showFilters ? (
            <>
              {innerColumns.map((column) => (
                <Form.Item
                  name={column?.value}
                  label={column.label}
                  style={{ width: "100%" }}
                  labelCol={{ span: 5, offset: 0 }}
                >
                  {column.component || <Input />}
                </Form.Item>
              ))}
            </>
          ) : null}



          <Form.Item style={{ width: "100%", marginLeft: 10 }} name="ranger_filter" valuePropName="checked" >
            {showFilters && <Checkbox
              onChange={() => setOpenRanger(s => !s)}
              style={{ marginBottom: 10 }}
            >
              Arasındadır
            </Checkbox>}
          </Form.Item>


          {openRanger && showFilters ? (
            <>
              {rangerColumns.map((column) => (
                <Form.Item
                  name={column?.value}
                  label={column.label}
                  style={{ width: "100%" }}
                  labelCol={{ span: 5, offset: 0 }}
                >
                  {column.component || <Input />}
                </Form.Item>
              ))}
            </>
          ) : null}
        </div>
      </Form>
    </Modal>
  );
};

export default ProgramModal;
