import {
  CloseCircleOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, notification, TimePicker } from "antd";
import React from "react";
import responseControl from "../assets/scripts/responseControl";
import { API_URL } from "../config";
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const SeasonModal = ({ day, title, visible, setVisible, currentSeasons }) => {
  const [ranges, setRanges] = React.useState([]);
  const [facilityId, setFacilityId] = React.useState(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    return fetch(`${API_URL}/api/v1/system_datas`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => responseControl(response))
      .then((result) => {
        setFacilityId(result[0].facility_id);
      })
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );
  }, []);

  const handleClick = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/system_datas`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: {
            seasons:
              currentSeasons instanceof Array && currentSeasons.length > 0
                ? currentSeasons.concat(ranges)
                : ranges,
          },
          where: { facility_id: facilityId },
        }),
      });
      await response.json();
      notification.success({ message: "Seans eklendi." });
      window.location.reload();
    } catch (error) {
      notification.error({ message: error?.message || error });
    }
  };

  const handleRanges = (range) => {
    const currentRanges = [...ranges];
    currentRanges.push(day + " " + range.join(" "));
    return setRanges(currentRanges);
  };

  const handleRemoveField = (field, remove) => {
    const currentRanges = [...ranges];
    currentRanges.splice(field.fieldKey, 1);
    setRanges(currentRanges);
    remove(field.name);
  };

  return (
    <Modal
      title={title}
      okButtonProps={{
        form: "season-form",
        key: "submit",
        htmlType: "submit",
        icon: <SaveOutlined />,
        onClick: handleClick,
      }}
      cancelButtonProps={{
        form: "season-form",
        key: "cancel",
        danger: true,
        type: "primary",
        icon: <CloseCircleOutlined />,
      }}
      okText="Kaydet"
      cancelText={"İptal"}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
    >
      <Form form={form} id="season-form">
        <Form.List
          name="names"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error("En az bir seans eklenmeli.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Seans" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item {...field} noStyle>
                    <TimePicker.RangePicker
                      format="HH:mm:ss"
                      onChange={(time, timeIndex) => handleRanges(timeIndex)}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        return handleRemoveField(field, remove);
                      }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Seans ekle
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default SeasonModal;
