import { Dropdown, Menu, notification } from "antd";
import React from "react";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { API_URL } from "../config";

const Whoami = ({ profilePhoto, username, handleExit }) => {
  const [settingsStyle, setSettingsStyle] = React.useState({
    color: "#08979c",
  });

  const handleTour = () => {
    localStorage.removeItem("toured");
    window.open("/", "_self");
  };

  const handleMouseOver = () =>
    setSettingsStyle({
      color: "#ffff",
      backgroundColor: "#08979c",
    });
  const handleMouseLeave = () =>
    setSettingsStyle({
      color: "#08979c",
    });

  const menu = (
    <Menu style={{ marginTop: 15 }}>
      <Menu.Item
        key="1"
        icon={<SettingOutlined />}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        style={settingsStyle}
      >
        <Link to="/me">Ayarlar</Link>
      </Menu.Item>
      <Menu.Item onClick={handleExit} key="2" icon={<LogoutOutlined />} danger>
        Çıkış
      </Menu.Item>
      <Menu.Item
        onClick={handleTour}
        key="3"
        icon={<QuestionCircleOutlined />}
        style={{ color: "#d48806" }}
      >
        Tanıtım Modunu Aç
      </Menu.Item>
    </Menu>
  );

  const [appVersion, setAppVersion] = React.useState(0);
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/version`)
      .then((response) => response.text())
      .then((result) => setAppVersion(result))
      .catch((error) => {
        //prettier-ignore
        return notification.error({ message: error instanceof Error ? error.message : error })
      });
  }, []);

  return (
    <Dropdown overlay={menu} className="exit-button" trigger="click">
      <div
        style={{
          fontSize: 20,
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {profilePhoto ? (
          <img
            src={`${API_URL}/${profilePhoto.split("/").pop()}`}
            style={{ width: 50, height: 50, borderRadius: "100%" }}
          />
        ) : (
          <UserOutlined />
        )}
        <p style={{ marginTop: "10%" }}>{username}</p>

        <span style={{ marginLeft: "1em" }}> {appVersion}</span>
      </div>
    </Dropdown>
  );
};

export default Whoami;
