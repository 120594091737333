import { API_URL } from "../config";
import { Tag } from "antd";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const initialColumns = (tariffs) => [
  {
    title: "Cihaz ID",
    dataIndex: "device_id",
    key: "device_id",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
  },
  {
    title: "Cihaz Adı",
    dataIndex: "device_name",
    key: "device_name",
    responsive: ["xs", "md", "lg"],
    defaultSelected: true,
    width: 150,
  },
  {
    title: "Lokasyon",
    dataIndex: "location",
    key: "location",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
  },
  {
    title: "Türü",
    dataIndex: "type",
    key: "type",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
  },
  {
    title: "Yönü",
    dataIndex: "direction",
    key: "direction",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render: (item) =>
      item === "enterance" ? (
        <Tag color={"success"}>Giriş</Tag>
      ) : item === "exit" ? (
        <Tag color={"error"}>Çıkış</Tag>
      ) : null,
    filters: [
      {
        text: "Giriş",
        value: "enterance",
      },
      {
        text: "Çıkış",
        value: "exit",
      },
    ],
    onFilter: (value, record) => record.direction === value,
    width: 150,
  },
  {
    title: "Tarife",
    dataIndex: "schedule_id",
    key: "schedule_id",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
    render: (id) => {
      const name = tariffs.find((tariff) => tariff.id === id)?.schedule_name;
      return name;
    },
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    width: 150,
    key: "facility",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    defaultSelected: true,
    width: 310,
    render: (item, row, idx) => (
      <TableActions
        deviceIP={row.ip}
        type="device"
        onClick={(event) => deleteRecord(`id=${row.id}`, "devices")}
      />
    ),
    fixed: "right",
  },
];

const Devices = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/schedules?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const cols = initialColumns(result);
        setColumns(cols);

        fetch(`${API_URL}/api/v1/devices?include=schedule,facilities`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            setDataSource(result);
          })
          .catch((error) => console.log(error))
          .finally(() => setIsLoading(false));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="CİHAZLAR"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="device"
        isLoading={isLoading}
      />
      ;
    </ErrorBoundary>
  );
};

export default Devices;
