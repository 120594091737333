import { API_URL } from "../config";
import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Modal,
  notification,
  Select,
  TimePicker
} from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined, MinusCircleOutlined, PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";
import moment from "moment";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const PoolUsageModal = (props) => {
  const [branches, setBranches] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [gsbClubs, setGsbClubs] = React.useState([]);
  const [districtLoading, setDistrictLoading] = React.useState(false);
  const [districts, setDistricts] = React.useState([]);
  const [district, setDistrict] = React.useState(null)
  React.useEffect(() => {
    setDistrictLoading(true);
    fetch(`${API_URL}/api/v1/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDistricts(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setDistrictLoading(false));
  }, [district]);

  React.useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URL}/api/v1/gsbClubs/?district=` + district, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setBranches(result.branches);
        setGsbClubs(result.clubs);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, [district]);

  const [form] = Form.useForm();
  React.useEffect(() => {
    form.resetFields();
    if (props.record?.id) {
      form.setFieldsValue({
        ...props.record,
        hour_range: props.record.hour_range.map(d => {
          const h = d.split(":")
          const date = moment({ hour: h[0], minute: h[1] })
          return date
        }),
        trainers: props.record.trainers && JSON.parse(props.record.trainers),
        time_range: props.record.time_range.map(d => moment(d))
      })
    }
  }, [form, props.record]);
  const [loading, setLoading] = React.useState(false)
  const handleClick = async () => {
    setLoading(true)
    const fields = form.getFieldsValue();

    const {
      club_number,
      branch,
      members_number,
      lines_number,
      days,
      time_range,
      cost,
      trainers,
    } = fields;
    const hour_range = fields.hour_range.map(d => d.format('HH:mm:ss'));

    if (
      club_number &&
      branch &&
      members_number &&
      lines_number &&
      days &&
      time_range &&
      hour_range
    ) {
      const club_name = gsbClubs.find(c => c.club_number === club_number)?.club_name;

      if (props.record?.id) {
        if (props.record?.is_paid) {
          return notification.error({ message: "Bu talep ödenmiş ve değiştirilemez" })
        }
        return await fetch(`${API_URL}/api/v1/pool_usages`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            body: {
              club_number,
              branch,
              members_number,
              lines_number,
              days,
              time_range,
              cost,
              hour_range,
              club_name,
              trainers: trainers && JSON.stringify(trainers)
            },
            where: { id: props.record.id }
          }),
        })
          .then((response) => responseControl(response))
          .then(() => window.location.reload())
          .catch((error) => console.log(error))
          .finally(() => setLoading(false))
      } else {
        return await fetch(`${API_URL}/api/v1/pool-usage-request`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            club_number,
            branch,
            members_number,
            lines_number,
            days,
            time_range,
            cost,
            hour_range,
            club_name,
            trainers: JSON.stringify(trainers)
          }),
        })
          .then((response) => responseControl(response))
          .then(() => window.location.reload())
          .catch((error) => console.log(error))
          .finally(() => setLoading(false))
      }
    } else {
      notification.error({ message: "Kırmızı yıldızlı alalnları doldurunuz!" });
      setLoading(false)
    }
  };
  const [searchedBranch, setSearchedBranch] = React.useState(null);
  const [searchedClub, setSearchedClub] = React.useState(null);
  return (
    <Modal
      width={"600px"}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Kulüp İlcesi" name="district">
          <Select
            loading={districtLoading}
            onChange={(e) => setDistrict(e)}
            placeholder="İl seçiniz..."
            name="district"
            allowClear
            showSearch
            filterOption={false}

          >
            {districts
              ?.sort((a, b) => a.IlceAdi.localeCompare(b.IlceAdi, "tr"))
              ?.map((f) => (
                <Select.Option value={f.IlceId}>{f.IlceAdi}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Kulüp Adı"
          rules={[{ required: true }]}
          name="club_name"
        >
          <Select loading={isLoading} onChange={e => {
            form.setFieldsValue({ club_number: e });
          }}
            allowClear
            showSearch
            filterOption={false}
            onSearch={(val) => {
              setSearchedClub(val);
            }}
          >
            {gsbClubs?.filter((a) =>
              searchedClub
                ? a.club_name.toLocaleUpperCase("tr-TR").includes(
                  searchedClub?.toLocaleUpperCase("tr-TR")
                )
                : a
            )
              ?.sort((a, b) => a.club_name.localeCompare(b.club_name, "tr"))
              ?.map((i => {
                return <Select.Option key={i.club_number}>{i.club_name}</Select.Option>
              }))}
          </Select>
        </Form.Item>

        <Form.Item
          name="club_number"
          label="Kulüp Sicil Numarası"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="branch" label="Branş" rules={[{ required: true }]}>
          <Select
            allowClear
            showSearch
            filterOption={false}
            onSearch={(val) => {
              setSearchedBranch(val);
            }}
          >
            {branches?.filter((a) =>
              searchedBranch
                ? a.branch_name.toLocaleUpperCase("tr-TR").includes(
                  searchedBranch?.toLocaleUpperCase("tr-TR")
                )
                : a
            )
              ?.sort((a, b) => a.branch_name.localeCompare(b.branch_name, "tr")).map((b) => {
                return <Select.Option value={b.branch_number}>{b.branch_name}</Select.Option>;
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="members_number"
          label="Üye Sayısı"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="lines_number"
          label="Kulvar Sayısı"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="days"
          label="Kullanım Günleri"
          rules={[{ required: true }]}
        >
          <Select mode="multiple">
            {[
              "Pazartesi",
              "Salı",
              "Çarşamba",
              "Perşembe",
              "Cuma",
              "Cumartesi",
              "Pazar",
            ].map((day) => (
              <Select.Option value={day}>{day}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="time_range"
          label="Zaman Aralığı"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item
          name="hour_range"
          label="Saat Aralığı"
          rules={[{ required: true }]}
        >
          <TimePicker.RangePicker />
        </Form.Item>
        <Form.Item name="cost" label="Ücret (TL)" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <h3 style={{ textAlign: "center" }}>Antrenörler</h3>
          <Form.List name="trainers">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'trainer_name']}
                      rules={[
                        {
                          required: true,
                          message: 'Ad soyad giriniz.',
                        },
                      ]}
                    >
                      <Input placeholder="İsim Soyisim" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'tc_no']}
                      rules={[
                        {
                          required: true,
                          message: 'TC numarası giriniz',
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "130px", marginLeft: "-10px", marginRight: "10px" }} placeholder="TC numarası" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" style={{ width: "120px", paddingLeft: "5px" }} onClick={() => {

                    return fields?.length < 4 && add()
                  }} block icon={<PlusOutlined />}>
                    Antrenör ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Modal>
  );
};

export default PoolUsageModal;
