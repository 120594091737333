import { API_URL } from "../config";
import { Tag, message } from "antd";
import React from "react";
import CTable from "../components/CTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { WarningFilled, } from "@ant-design/icons";
import { io } from "socket.io-client";

const initialColumns = [
  {
    title: "Ad",
    dataIndex: ["member", "first_name"],
    key: "first_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render: (n, row) => {

      return <span style={{ color: "red", fontWeight: "bold" }}><WarningFilled style={{ color: "red" }} /> {n}</span>

    },
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: <div><WarningFilled style={{ color: "white" }} /> {n}</div>
        };
      } else {
        return n
      }
    }
  },
  {
    title: "Soyad",
    dataIndex: ["member", "last_name"],
    key: "last_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },
  {
    title: "Sağlık Durumu",
    dataIndex: "message",
    key: "message",
    defaultSelected: true,
    render: (item) => {
      if (item) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: item
        };
      }
      else {
        return "Sağlıklı"
      }
    }
  },
  {
    title: "Kulüp Adı",
    dataIndex: "club_name",
    key: "club_name",
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },
  {
    title: "Kart Numarası",
    dataIndex: "cards",
    key: "card_number",
    defaultSelected: true,

    render(item, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: item[0]?.card_number
        };
      } else {
        return item[0]?.card_number
      }
    },
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis",
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },
  {
    title: "Giriş Cihazı",
    dataIndex: "login_device",
    key: "login_device",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },
  {
    title: "Çıkış Cihazı",
    dataIndex: "logout_device",
    key: "logout_device",
    defaultSelected: true,
    render(n, row) {
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },
  {
    title: "Giriş",
    dataIndex: "login",
    key: "login",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render(item, row) {
      if (item === null) return null;
      const date = <p>
        {new Date(item)
          .toLocaleDateString("tr-TR")
          .split(".")
          .reverse()
          .join("-")}{" "}
        {new Date(item)
          .toLocaleTimeString("tr-TR")
          .split(".")
          .reverse()
          .join("-")}
      </p>
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: date
        };
      } else {
        return date
      }
    },

  },
  {
    title: "Giriş Türü",
    dataIndex: "entrance_type",
    key: "entrance_type",
    defaultSelected: true,
    render(item, row) {
      let text = null;
      switch (item) {
        case "QR":
          text = "QR ile giriş";
        case "TC":
          text = "TC kartı ile giriş";
        default:
          text = "Kart ile giriş";
      }
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: text
        };
      } else {
        return text
      }
    },
  },
  {
    title: "Hareket Türü",
    dataIndex: "log_type",
    key: "log_type",
    defaultSelected: true,
    render(item, row) {
      let n = null;
      switch (item) {
        case "course":
          n = "Kurs";
        case "club":
          n = "Kulüp";
        default:
          n = "Genel";
      }
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: n
        };
      } else {
        return n
      }
    },
  },

  {
    title: "Çıkış",
    dataIndex: "logout",
    key: "logout",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render(item, row) {
      if (item === null) return null;
      const date = <p>
        {new Date(item)
          .toLocaleDateString("tr-TR")
          .split(".")
          .reverse()
          .join("-")}{" "}
        {new Date(item)
          .toLocaleTimeString("tr-TR")
          .split(".")
          .reverse()
          .join("-")}
      </p>
      if (row.message) {
        return {
          props: {
            style: { background: "red", color: "white" }
          },
          children: date
        };
      } else {
        return date
      }
    },
  },
  {
    title: "İçerdekiler",
    dataSource: "isInside",
    key: "isInside",
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => {
      if (row.logout === null) {
        return <Tag color="#52c41a">İçeride</Tag>;
      } else {
        return <Tag color="#cf1322">Çıktı</Tag>;
      }
    },
    filters: [
      {
        text: "İçeride",
        value: true,
      },
      {
        text: "Çıktı",
        value: false,
      },
    ],
    onFilter: (value, record) => record.isInside === value,
    defaultSelected: true,
  },
];

const Logs = () => {
  const [dangerLogs, setDangerLogs] = React.useState([]);
  React.useEffect(() => {

    const socket = io(API_URL, {
      auth: {
        token: "Bearer " + localStorage.getItem("access_token"),
      },
    });


    socket?.on("danger_log", (message) => {
      if (message) setDangerLogs([message]);
    });
    socket.emit("danger_log", "danger");
    socket.on("disconnect", () => {
      socket.connect();
    });
  }, []);

  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/logs?include=card,member,facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (result) => {
        const setIsInside = async () =>
          Promise.all(
            result.map((record) => {
              if (record.logout === null) {
                record.isInside = true;
              } else {
                record.isInside = false;
              }
              return record;
            })
          );
        setIsInside().then(() => setDataSource(result));
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="HAREKETLER"
        scrollX={3500}
        dataSource={[...dangerLogs, ...dataSource,]}
        columns={columns}
        timeFilter={true}
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Logs;
