import { API_URL } from "../config";
import { Tag } from "antd";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const initialColumns = [
  {
    title: "Ad",
    dataIndex: ["member", "first_name"],
    key: "first_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Soyad",
    dataIndex: ["member", "last_name"],
    key: "last_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Program Adı",
    dataIndex: "program_name",
    key: "program_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Randevu günü",
    dataIndex: "date",
    key: "date",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render: (d, r) => {
      return d && new Date(d).toLocaleString("tr-TR")
    }
  },
  {
    title: "Tesis Bilgisi",
    dataIndex: "system_data",
    key: "system_data",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item, row) => {
      return item?.province && item?.province + " / " + item?.district;
    },
  },

  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    responsive: ["xs", "md", "lg"],

    defaultSelected: true,
    render: (item, row, idx) => (
      <TableActions
        deviceIP={row.ip}
        onClick={(event) => deleteRecord(`id=${row.id}`, "appointments")}
      />
    ),
    fixed: "right",
  },
];

const Appointments = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/appointments?include=member,system_data`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="RANDEVULAR"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="appointment"
        isLoading={isLoading}
      />
    </ErrorBoundary>
  );
};

export default Appointments;
