import React from "react";
import { API_URL } from "../config";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { Button, Tag } from "antd";
import ClubPayment from "../components/ClubPayment";
export default function PoolUsage() {
  const [paymentModal, setPaymentModal] = React.useState({
    open: false,
    item: null,
  });
  const initialColumns = [
    {
      title: "Tesis Adı",
      dataIndex: "facility_name",
      key: "facility_name",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
    },

    {
      title: "Kulüp Adı",
      dataIndex: "club_name",
      key: "club_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],

    },
    {
      title: "Kulüp Numarası",
      dataIndex: "club_number",
      key: "club_number",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Antrenörler",
      dataIndex: "trainers",
      key: "trainers",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item, row) => {
        if (item) {
          const trainers = JSON.parse(item);
          return trainers.map(t => t.trainer_name + " - " + t.tc_no).join(", ")
        }
      }
    },
    {
      title: "Branş",
      dataIndex: "branch",
      key: "branch",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Üye Sayısı",
      dataIndex: "members_number",
      key: "members_number",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Kulvar Sayısı",
      dataIndex: "lines_number",
      key: "lines_number",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Kullanım Günleri",
      dataIndex: "days",
      key: "days",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
      render: (days) => {
        return days.join(", ");
      },
    },
    {
      title: "Zaman Aralığı",
      dataIndex: "time_range",
      key: "time_range",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],

      render: (item) => {

        return item[0].split("T")[0] + " - " + item[1].split("T")[0];
      },
    },
    {
      title: "Saat Aralığı",
      dataIndex: "hour_range",
      key: "hour_range",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item, row) => {
        return item?.length ? item.join(" | ") : null;
      },
    },
    {
      title: "Ücret",
      dataIndex: "cost",
      key: "cost",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Ücret ödendi mi?",
      dataIndex: "is_paid",
      key: "is_paid",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (bool) =>
        bool ? (
          <Tag color="green">Ödendi</Tag>
        ) : (
          <Tag color="red">Bekleniyor...</Tag>
        ),
    },
    {
      title: "İşlemler",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (item, row, idx) => (
        row.is_paid !== true && <TableActions
          type="pool_usage"
          onClick={(event) => deleteRecord(`id=${row.id}`, "pool_usages")}
        />
      ),
      fixed: "right",
    },
  ];

  const [dataSource, setDataSource] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/pool_usages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="HAVUZ KULLANIM TALEPLERİ"
        scrollX={3500}
        dataSource={dataSource}
        columns={initialColumns}
        type="poolUsage"
        isLoading={isLoading}
      />

      <ClubPayment
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
      />
    </ErrorBoundary>
  );
}
