import { API_URL } from "../config";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { notification } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const methods = {
  cash: "Nakit",
  kiosk: "Kiosk",
  credit_card: "Kredi kartı",
  "credit card": "Kredi kartı",
  card_selling: "Kart ücreti",
  credit_cutting: "Kesinti",
  course: "Kredi kartı ile kurs ödemesi",
  subscription: "Kredi kartı ile abonelik ödemesi",
  member_card_course: "Üye kartı ile kurs ödemesi",
  member_card_subscription: "Üye kartı ile abonelik ödemesi",
  credit_card_club: "Kredi Kart ile havuz ödemesi"
};

const initialColumns = [
  {
    title: "Ad",
    dataIndex: ["member", "first_name"],
    key: "first_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Soyad",
    dataIndex: ["member", "last_name"],
    key: "last_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "TC Kimlik Numarası",
    dataIndex: "member",
    key: "tckn",
    render: (item) => item?.tc_no,
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis",
    dataIndex: "facility",
    key: "facility",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tarife",
    dataIndex: "member",
    key: "Tarife",
    responsive: ["xs", "md", "lg"],

    render: (item) => {
      if (item?.schedules instanceof Array && item?.schedules?.length > 0) {
        return item?.schedules[0]?.schedule_name;
      }
    },
    defaultSelected: false,
  },
  {
    title: "Kulüp Adı",
    dataIndex: "member",
    key: "Kulüp",
    render: (item) => {
      if (item?.clubs instanceof Array && item?.clubs?.length > 0) {
        return item?.clubs[0]?.club_name;
      }
    },
    defaultSelected: false,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Kart Numarası",
    dataIndex: "card_number",
    key: "card_number",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => (item ? item : row?.card?.card_number),
  },
  {
    title: "Miktar",
    dataIndex: "amount",
    key: "amount",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item) =>
      item < 0 ? (
        <p
          style={{
            color: "red",
            fontWeight: "600",
            fontSize: 16,
            padding: "1%",
          }}
        >
          {item}
        </p>
      ) : (
        <p
          style={{
            color: "green",
            fontWeight: "600",
            fontSize: 16,
            padding: "1%",
          }}
        >
          {item}
        </p>
      ),
  },
  {
    title: "Ödeme Yöntemi",
    dataIndex: "payment_method",
    key: "payment_method",
    responsive: ["xs", "md", "lg"],

    defaultSelected: true,
    render: (method) => <p>{methods[method]}</p>,
  },

  {
    title: "Tarih",
    dataIndex: "createdAt",
    key: "createdAt",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],

    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}{" "}
          {new Date(item)
            .toLocaleTimeString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    defaultSelected: true,
    render: (item, row, idx) => (
      <TableActions
        type="member"
        onClick={() => deleteRecord(`id=${row.id}`, "payments")}
      />
    ),
  },
];

const Payment = () => {
  const [dataSource, setDataSource] = React.useState([]);

  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);
    try {
      fetch(`${API_URL}/api/v1/allpayments?include=facilities`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setDataSource(result);
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      notification.error({
        message: error instanceof Error ? error.message : error,
      });
    }
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="ÖDEMELER"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="payment"
        timeFilter={true}
        isLoading={isLoading}
        paymentMethods={methods}
      />
    </ErrorBoundary>
  );
};

export default Payment;
