import { API_URL } from "../../config";
const getAllowedEnpoints = (role, stateFunction) => {
  return (
    role &&
    fetch(`${API_URL}/api/v1/roles?role_id=${role}`)
      .then((response) => response.json())
      .then((result) => {
        const allowedEndpointsArray = [];
        const set = new Set();

        const { get, post, put, ...rest } = result[0];

        get?.split(",").map((endpoint) => set.add(endpoint));
        post?.split(",").map((endpoint) => set.add(endpoint));
        put?.split(",").map((endpoint) => set.add(endpoint));
        rest?.delete?.split(",").map((endpoint) => set.add(endpoint));

        set.forEach((val) => allowedEndpointsArray.push(val));

        return allowedEndpointsArray;
      })
      .then((result) => stateFunction(result))
      .catch((error) => console.log(error))
  );
};

export default getAllowedEnpoints;
