import { API_URL } from "../config";
import React from "react";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};
const SchedulesModal = (props) => {
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      form.setFieldsValue({ ...props.record });
    }
    if (Object.keys(props.record).length > 0) {
      setFields(props.record);
      console.log(props.record);
      setIsUpdate(true);
    } else if (Object.keys(props.record).length === 0) {
      form.resetFields();
      setFields({});
    }
  }, [props]);

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    const { newPassword, ...rest } = fields;
    return await fetch(`${API_URL}/api/v1/schedules`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
            body: { ...fields },
            where: { id: props.record.id },
          })
        : JSON.stringify({ ...fields }),
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <Button type="primary" icon={<SaveOutlined />} onClick={handleClick}>
          Kaydet
        </Button>,
        <Button
          type="primary"
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => props.setVisible(false)}
        >
          {props.type === "request" ? "Reddet" : "İptal"}
        </Button>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Tarife Adı" name="schedule_name">
          <Input name="schedule_name" onChange={handleChange} />
        </Form.Item>

        <Form.Item label="Kapasite" name="capacity">
          <InputNumber
            name="capacity"
            onChange={(value) =>
              handleChange({ target: { name: "capacity", value: value } })
            }
          />
        </Form.Item>

        <Form.Item label="Ücret" name="price">
          <InputNumber
            min="0"
            step="0.1"
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange({ target: { name: "price", value: value } })
            }
          />
        </Form.Item>
        <Form.Item label="Birim" name="currency">
          <Select
            showArrow
            onSelect={(value) =>
              handleChange({ target: { name: "currency", value } })
            }
          >
            <Option value="TL">TL</Option>
            <Option value="USD">USD</Option>
            <Option value="EURO">EURO</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Onay gerekiyor" name="verification_required">
          <Select
            showArrow
            onSelect={(value) =>
              handleChange({ target: { name: "verification_required", value } })
            }
          >
            <Option value={true}>Evet</Option>
            <Option value={false}>Hayır</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchedulesModal;
