import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Typography,
  Row,
  Select,
  TimePicker,
  Checkbox,
  Upload,
  message,
  Image,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  UploadOutlined,
  DeleteOutlined, InboxOutlined
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord, responseControl } from "../assets/scripts";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const RuleModal = (props) => {

  const [form] = Form.useForm();


  React.useEffect(() => {
    if (props.record.id) {
      const data = { ...props.record };

      form.setFieldsValue(data);
    }
  }, [props.record]);


  const handleClick = async () => {
    const data = form.getFieldsValue();

    return await fetch(`${API_URL}/api/v1/rules`, {
      method: props.record.id ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: props.record.id ? JSON.stringify({ body: { ...data, from: "terminal" }, where: { id: props.record.id } }) : JSON.stringify({ ...data, from: "terminal" }),
    })
      .then(async (response) => {
        if (response.ok) {
          const contentType = response.headers.get('content-type');

          if (contentType && contentType.includes('application/json')) {
            return response.json()
          } else {
            const result = await response.text();
            throw new Error(result)
          }
        }
      })
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );
  };

  const onClose = () => {
    if (props.record.id) {
      return fetch(`${API_URL}/api/v1/rules/?id=` + props.record.id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      })
        .then(() => window.location.reload())
        .catch((error) =>
          notification.error({ message: error?.message || error })
        );
    } else {
      form.resetFields();
      props.setVisible(false);
    }
  }


  return (
    <Modal
      visible={props.visible}
      onCancel={() => { form.resetFields(); props.setVisible(false) }}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >

            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={onClose}
            >
              {props.record.id ? "SİL" : "İptal"}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
            >
              Kaydet
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Typography.Title level={4}>{props.record.id ? "Tesis Kuralı Düzenle" : "Yeni Tesis Kuralı"}</Typography.Title>

        <Form.Item label="Başlıkı" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Mesaj" name="rule">
          <Input.TextArea rows={4} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default RuleModal;
