import React from "react";
import { Button, Card, Form, Input, Typography } from "antd";
import logo from "../assets/images/logo512.svg";
const Login = (props) => {
  return (
    <Card
      className="login-card"
      cover={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "10%",
          }}
        >
          <img
            alt="Sistemik Havuz"
            src={logo}
            style={{ width: "40%", marginBottom: "7%" }}
          />
          <Typography.Title level={3} className="login-header">
            TESİS YÖNETİM TERMİNALİ
          </Typography.Title>
        </div>
      }
    >
      <Form
        layout="vertical"
        style={{
          margin: "0 auto",
          marginTop: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Form.Item>
          <Input
            className="username-input"
            placeholder="Kullanıcı adınızı giriniz."
            style={{
              borderLeft: "none",
              borderTop: "none",
              borderRight: "none",
              width: "calc(100% + 34px)",
              marginLeft: "-17px",
            }}
            // size="large"
            name="username"
            onChange={props.handleChange}
            autoCapitalize="off"
            autoComplete="off"
            spellCheck={false}
            autoCorrect="off"
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            className="password-input"
            placeholder="Parolanızı giriniz."
            style={{
              fontStyle: "italic",
              borderLeft: "none",
              borderTop: "none",
              borderRight: "none",
            }}
            // size="large"
            name="password"
            onChange={props.handleChange}
            autoCapitalize="off"
            autoComplete="off"
            spellCheck={false}
            autoCorrect="off"
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={props?.loading}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              borderRadius: 5,
              backgroundColor: "#a30303",
              borderColor: "#a30303",
            }}
            type="primary"
            htmlType="submit"
            onClick={props.handleClick}
          >
            Giriş
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Login;
