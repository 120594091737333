import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Typography,
  Row,
  Select,
  TimePicker,
  Checkbox,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord, responseControl } from "../assets/scripts";





const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const SubscriptionModal = (props) => {
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (props.record) {
      form.setFieldsValue({ ...props.record, expiry_date: moment(props.record.expiry_date) })
    }
  }, [props.record])
  const [schedules, setSchedules] = React.useState([]);
  React.useEffect(() => {
    fetch(
      `${API_URL}/api/v1/schedules`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => {
        return response.ok && response.json();
      })
      .then(res => setSchedules(res))
      .catch(e => console.log(e))
  }, [])

  const [loading, setLoading] = React.useState(false)
  const handleClick = async () => {
    setLoading(true)
    const data = form.getFieldsValue();


    return await fetch(`${API_URL}/api/v1/subscriptions`, {
      method: props.record.id ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: props.record.id ? JSON.stringify({ body: { ...data, from: "terminal" }, where: { id: props.record.id } }) : JSON.stringify({ ...data, from: "terminal" }),
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      ).finally(() => setLoading(false));
  };

  const handleDelete = async () => {

    return await fetch(`${API_URL}/api/v1/subscriptions/?id=` + props.record.id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) =>
        notification.error({ message: error?.message || error })
      );
  }
  return (
    <Modal
      visible={props.visible}
      onCancel={() => { form.resetFields(); props.setVisible(false) }}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => {
                if (props.record) { handleDelete() } else {
                  form.resetFields(); props.setVisible(false);
                }
              }
              }
            >
              {props.record.id ? "SİL" : "İptal"}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
              loading={loading}
            >
              Kaydet
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Typography.Title level={4}>{props.record.id ? "Aboneliği Düzenle" : "Yeni Abonelik"}</Typography.Title>
        <Form.Item label="İsim" name="name">
          <Input />
        </Form.Item>
        <Form.Item valuePropName="checked" label="Personel için" name="employee">
          <Checkbox>EVET</Checkbox>
        </Form.Item>
        <Form.Item label="Tarife" name="schedule_id">
          <Select
            allowClear
            showArrow
          >
            {schedules.map((m) => (
              <Option value={m.id}>{m.schedule_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Fiyat (TL)" name="cost">
          <Input min={"0"} type="number" />
        </Form.Item>
        <Form.Item label="Geçiş Hakkı" name="entries">
          <Input min={"1"} type="number" />
        </Form.Item>
        <Form.Item label="Kaç ay geçerli?" name="duration">
          <Input type="number" min={"1"} />
        </Form.Item>

        <Form.Item label="Kapasite" name="capacity">
          <Input min={"1"} type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscriptionModal;
