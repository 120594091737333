import { API_URL } from "../config";
import { Tag } from "antd";
import React from "react";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { useLocation } from "react-router-dom";


const columns = [
    {
        title: "Hastalıklar",
        dataIndex: "name",
        defaultSelected: true
    },
    {
        title: "Hastalık var mı?",
        dataIndex: "has_illness",
        width: 200,
        render: (bool, record) => {
            return bool === "evet" ? <Tag color="red">EVET</Tag> : bool === "hayır" ? <Tag color="green">HAYIR</Tag> : <Tag>BİLİNMİYOR</Tag>
        },
        defaultSelected: true
    },

    {
        title: "Açıklamalar",
        dataIndex: "explanation",
        editable: true,
        defaultSelected: true

    },
];


const SingleHealthReport = () => {
    let location = useLocation();
    const [dataSource, setDataSource] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [kvkkCheck, setKvkkCheck] = React.useState(false);
    const [fullName, setFullName] = React.useState("");
    React.useEffect(() => {
        setIsLoading(true);
        setFullName(location.state.fullName)
        fetch(`${API_URL}/api/v1/health_checks?tc_no=` + (location.state.tcNo || ""), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((r) => {
                if (r?.health_report) {
                    setDataSource(r.health_report);
                    setKvkkCheck(r?.kvkk_checked);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false))
    }, [location]);

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <CTable
                title={<><span>{fullName} - SAĞLIK RAPORU </span> <Tag color={kvkkCheck ? "green" : "red"}>{kvkkCheck ? "KVKK KABUL EDİLDİ" : "KVKK KABUL EDİLMEDİ"}</Tag></>}
                scrollX={3500}
                dataSource={dataSource}
                columns={columns}
                type="health_checks"
                excel={false}
                isLoading={isLoading}
            />
        </ErrorBoundary>
    );
};

export default SingleHealthReport;
