import { API_URL } from "../config";
import React from "react";
import { Tag } from "antd";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";

const excelColumns = [
  { title: "Ad", dataIndex: "first_name" },
  { title: "Soyad", dataIndex: "last_name" },
  { title: "T.C. Kimlik Numarası", dataIndex: "tc_no" },
  { title: "Kart Numarası", dataIndex: "card_number" },
  { title: "Bakiye", dataIndex: "credits" },
  { title: "Kulüp Adı", dataIndex: "club" },
];

const initialColumns = [
  {
    title: "Kulüp Adı",
    dataIndex: "club_name",
    key: "club_name",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    width: 150,
  },
  {
    title: "Antrenörler",
    width: 150,
    children: [
      {
        width: 150,
        title: "Adı Soyadı",
        dataIndex: "trainer",
        key: "trainer",
        responsive: ["xs", "md", "lg"],

        //prettier-ignore
        sorter: (a, b) => a?.trainer?.localeCompare instanceof Function ? a?.trainer?.localeCompare(b?.trainer) : null,
      },
      {
        width: 150,
        title: "Telefon Numarası",
        dataIndex: "trainer_phone",
        key: "trainer_phone",
        responsive: ["xs", "md", "lg"],
      },
      {
        width: 150,
        title: "E-posta Adresi",
        dataIndex: "trainer_email",
        key: "trainer_email",
        responsive: ["xs", "md", "lg"],
      },
    ],
  },
  {
    title: "Kulüp Adresi",
    width: 150,
    dataIndex: "club_address",
    key: "club_address",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Kulüp Telefonu",
    width: 150,
    dataIndex: "club_phone",
    key: "club_phone",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Tesis",
    width: 150,
    dataIndex: ["facility", "name"],
    key: "facility",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
  },
  {
    title: "Durumu",
    width: 150,
    dataIndex: "is_active",
    key: "is_active",
    defaultSelected: true,
    responsive: ["xs", "md", "lg"],
    render: (status) =>
      status === true ? (
        <Tag color="success">Aktif</Tag>
      ) : (
        <Tag color="error">Pasif</Tag>
      ),
    filters: [
      {
        text: "Aktif",
        value: true,
      },
      {
        text: "Pasif",
        value: false,
      },
    ],
    onFilter: (value, record) => {
      return record?.is_active === value;
    },
  },
  {
    title: "Oluşturma Tarihi",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    defaultSelected: true,
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "Son Değişiklik Tarihi",
    dataIndex: "updatedAt",
    width: 150,
    key: "updatedAt",
    defaultSelected: true,
    render: (item, row, idx) => {
      if (item === null) return null;

      return (
        <p>
          {new Date(item)
            .toLocaleDateString("tr-TR")
            .split(".")
            .reverse()
            .join("-")}
        </p>
      );
    },
  },
  {
    title: "İşlemler",
    dataIndex: "actions",
    key: "actions",
    width: 300,
    defaultSelected: true,
    render: (item, row, idx) => (
      <TableActions
        type="club"
        row={row}
        columns={excelColumns}
        onClick={(event) => deleteRecord(`id=${row.id}`, "clubs")}
      />
    ),
    fixed: "right",
  },
];

const Clubs = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/clubs?include=facilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log("RESULT ", result);
        const data = result.map(club => {
          const obj = { ...club };
          console.log("CLUB ", club, club.trainers);
          if (club.trainers) {
            obj.users = club?.trainers?.map(trainer => JSON.parse(trainer)) || [];
          }
          return obj
        });
        console.log("DATA ", data)
        setDataSource(data);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="KULÜPLER"
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        type="club"
        isLoading={isLoading}
      />
      ;
    </ErrorBoundary>
  );
};

export default Clubs;
