import { API_URL } from "../config";
import React from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { deleteRecord } from "../assets/scripts";

const getClubs = () =>
  fetch(`${API_URL}/api/v1/clubs`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log(error));

const getSchedules = () =>
  fetch(`${API_URL}/api/v1/schedules`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => notification.error({ message: error?.message || error }));

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16, offset: 2 },
};

const CalendarModal = (props) => {
  const [schedules, setSchedules] = React.useState([]);
  const [fields, setFields] = React.useState({});
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [clubs, setClubs] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [openInner, setOpenInner] = React.useState(false);
  const [openRanger, setOpenRanger] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [innerFilterObject, setInnerFilterObject] = React.useState({});
  const [rangerFilterObject, setRangerFilterObject] = React.useState({});
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (props?.record && Object.keys(props?.record).length > 0) {
      const { event_date, start_time, finish_time, filter, ...rest } =
        props.record;

      setInnerFilterObject(filter);
      setRangerFilterObject(filter);
      form.setFieldsValue({
        event_date: moment(event_date),
        start_time:
          start_time === undefined || start_time === null
            ? null
            : moment(start_time, "HH:mm:ss"),
        finish_time:
          finish_time === undefined || finish_time === null
            ? null
            : moment(finish_time, "HH:mm:ss"),
        ...rest,
        filter,
      });
    }

    if (
      ![undefined, null].includes(props?.record) &&
      Object.keys(props.record).length > 0
    ) {
      const { event_date, start_time, finish_time, ...rest } = props.record;

      setFields({
        event_date: event_date,
        start_time:
          start_time === undefined || start_time === null
            ? null
            : moment(start_time, "HH:mm:ss"),
        finish_time:
          finish_time === undefined || finish_time === null
            ? null
            : moment(finish_time, "HH:mm:ss"),
        ...rest,
      });
      if (
        ![undefined, null].includes(props?.record) &&
        Object.keys(props.record).length > 1
      ) {
        setIsUpdate(true);
      }
    } else if (
      ![undefined, null].includes(props?.record) &&
      Object.keys(props.record).length === 0
    ) {
      form.resetFields();
      setFields({});
    }

    if (
      [undefined, null].includes(props.record) ||
      Object.keys(props.record).length === 0
    ) {
      setIsUpdate(false);
    }

    getSchedules()
      .then((result) => {
        if (result instanceof Array) {
          return setSchedules(result);
        }
        setSchedules(["unauthorized"]);
        return notification.error({
          message:
            "Tarifeleri görüntüleme yetkiniz yok. Dilerseniz amirinizden bu yetkiyi talep edebilirsiniz.",
          duration: 0,
        });
      })
      .catch((error) => console.log(error));
  }, [props]);

  React.useEffect(() => {
    if (props.visible) {
      getClubs().then((result) => {
        if (result instanceof Array) {
          return setClubs(result);
        }
        setClubs(["unauthorized"]);
        return notification.error({
          message:
            "Spor kulüplerini görüntüleme yetkiniz yok. Dilerseniz amirinizden bu yetkiyi talep edebilirsiniz.",
          duration: 0,
        });
      });
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (innerFilterObject && innerFilterObject.hasOwnProperty("in")) {
      setOpenInner(true);
      const defaultSelectedIds =
        innerFilterObject.in[
          innerFilterObject.in.findIndex(
            (object) => Object.keys(object)[0] === "card_id"
          )
        ]?.card_id;
      let arr = [];
      defaultSelectedIds?.map((id) => {
        return fetch(`${API_URL}/api/v1/members?include=card&card_id=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            arr.push(
              result[0]?.first_name +
                " " +
                result[0]?.last_name +
                "-" +
                result[0]?.tc_no
            );
          })
          .then(() => setSelectedMembers(arr))
          .catch((error) => console.log(error));
      });
    }
  }, [innerFilterObject]);

  React.useEffect(() => {
    if (rangerFilterObject && rangerFilterObject.hasOwnProperty("between")) {
      setOpenRanger(true);
    }
  }, [rangerFilterObject]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    return setFields({ ...fields, [name]: value });
  };

  const handleSearch = (value) => {
    const valueArr = value.split(" ");
    return fetch(
      valueArr.length < 2 || (valueArr.length >= 2 && valueArr[1].length < 1)
        ? `${API_URL}/api/v1/members?include=card&first_name=${valueArr[0].toUpperCase()}`
        : `${API_URL}/api/v1/members?include=card&first_name=${valueArr[0].toUpperCase()}&last_name=${valueArr[1].toUpperCase()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result instanceof Array) {
          return setMembers(result);
        }
        setMembers(["unauthorized"]);
        return notification.error({
          message:
            "Üyeleri görüntüleme yetkiniz yok. Dilerseniz amirinizden bu yetkiyi talep edebilirsiniz.",
        });
      })
      .catch((error) => console.log(error));
  };

  const handleClick = async () => {
    let fieldsWillSend = {};
    const { finish_time, start_time, createdAt, updatedAt, filter, ...rest } =
      fields;

    const innerFilterFields = Object.keys(innerFilterObject).map((key) => {
      if (key === "in") {
        return {
          [key]: innerFilterObject[key].map((object) => {
            if (
              Object.values(object)[0] &&
              Object.values(object)[0][0] instanceof moment
            ) {
              return {
                [Object.keys(object)[0]]: [
                  Object.values(object)[0][0].format("YYYY-MM-DD"),
                ],
              };
            } else {
              return object;
            }
          }),
        };
      }
    });

    const rangerFilterFields = Object.keys(rangerFilterObject).map((key) => {
      if (key === "between") {
        return {
          [key]: rangerFilterObject[key].map((object) => {
            if (
              Object.values(object)[0][0][0] instanceof moment &&
              Object.values(object)[0][0][1] instanceof moment
            ) {
              return {
                [Object.keys(object)[0]]: [
                  Object.values(object)[0][0][0].format("YYYY-MM-DD"),
                  Object.values(object)[0][0][1].format("YYYY-MM-DD"),
                ],
              };
            } else {
              return object;
            }
          }),
        };
      }
    });

    fieldsWillSend = {
      start_time:
        start_time instanceof moment
          ? start_time.format("HH:mm:ss")
          : start_time,
      finish_time:
        finish_time instanceof moment
          ? finish_time.format("HH:mm:ss")
          : finish_time,
      ...rest,
      filter: JSON.stringify(
        {
          ...innerFilterFields[0],
          ...rangerFilterFields.filter((item) => item)[0],
        } || {}
      ),
    };

    return await fetch(`${API_URL}/api/v1/calendars`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: isUpdate
        ? JSON.stringify({
            body: fieldsWillSend,
            where: { id: props.record.id },
          })
        : JSON.stringify(fieldsWillSend),
    })
      .then((response) => response.json())
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };

  const innerColumns = [
    {
      label: "Doğum yılı",
      value: "birthdate",
      // prettier-ignore
      fieldName: innerFilterObject && innerFilterObject.in instanceof Array ? ["filter", "in", innerFilterObject.in.findIndex(object => Object.keys(object)[0] === "birthdate"), "birthdate", 0] : undefined,
      component: (
        <DatePicker
          style={{ width: "100%" }}
          locale={{ ...locale }}
          format="YYYY-MM-DD"
          onChange={(date, dateIndex) => {
            let filterData = { ...innerFilterObject };

            if (innerFilterObject?.in instanceof Array) {
              const propOrder = innerFilterObject.in.findIndex(
                (object) => Object.keys(object)[0] === "birthdate"
              );
              if (propOrder > -1) {
                filterData.in[propOrder] = { birthdate: [date] };
              } else {
                filterData.in.push({ birthdate: [date] });
              }
              setInnerFilterObject(innerFilterObject);
            } else {
              setInnerFilterObject({ in: [{ birthdate: [date] }] });
            }
          }}
        />
      ),
    },
    {
      label: "Cinsiyet",
      value: "gender",
      // prettier-ignore
      fieldName: innerFilterObject && innerFilterObject.in instanceof Array ? ["filter", "in", innerFilterObject.in.findIndex(object => Object.keys(object)[0] === "gender"), "gender"] : undefined,
      component: (
        <Select
          onChange={(value) => {
            let filterData = { ...innerFilterObject };
            if (innerFilterObject?.in instanceof Array) {
              const propOrder = innerFilterObject.in.findIndex(
                (object) => Object.keys(object)[0] === "gender"
              );
              if (propOrder > -1) {
                filterData.in[propOrder] = { gender: [value] };
              } else {
                filterData.in.push({ gender: [value] });
              }
              setInnerFilterObject(filterData);
            } else {
              setInnerFilterObject({
                ...innerFilterObject,
                in: [{ gender: [value] }],
              });
            }
          }}
        >
          <Option value="male">Erkek</Option>
          <Option value="female">Kadın</Option>
        </Select>
      ),
    },
    {
      label: "Kulüp",
      value: "club",
      // prettier-ignore
      fieldName: innerFilterObject && innerFilterObject.in instanceof Array ? ["filter", "in", innerFilterObject.in.findIndex(object => Object.keys(object)[0] === "club"), "club"] : undefined,
      component: (
        <Select
          disabled={clubs.length === 1 && clubs.includes("unauthorized")}
          onChange={(value) => {
            let filterData = { ...innerFilterObject };
            if (innerFilterObject?.in instanceof Array) {
              const propOrder = innerFilterObject.in.findIndex(
                (object) => Object.keys(object)[0] === "club"
              );
              if (propOrder > -1) {
                filterData.in[propOrder] = { club: [value] };
              } else {
                filterData.in.push({ club: [value] });
              }
              setInnerFilterObject(filterData);
            } else {
              setInnerFilterObject({
                ...innerFilterObject,
                in: [{ club: [value] }],
              });
            }
          }}
        >
          {clubs.map((club) => (
            <Option value={club.id}>{club.club_name}</Option>
          ))}
        </Select>
      ),
    },
    {
      label: "Üyeler",
      value: "card_id",
      component: (
        <Select
          disabled={members.length === 1 && members.includes("unauthorized")}
          allowClear
          mode="multiple"
          defaultValue={selectedMembers}
          removeIcon
          showArrow
          showSearch
          onClear={() => {
            delete innerFilterObject.in[
              innerFilterObject.in.findIndex(
                (object) => Object.keys(object)[0] === "card_id"
              )
            ].card_id;
          }}
          filterOption={(input, option) =>
            option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
          }
          onChange={(change) => {
            let value;
            if (change.slice(selectedMembers.length) === undefined) {
              value = [];
            } else {
              value = change.slice(selectedMembers.length);
            }
            let filterData = { ...innerFilterObject };
            if (innerFilterObject?.in instanceof Array) {
              const propOrder = innerFilterObject.in.findIndex(
                (object) => Object.keys(object)[0] === "card_id"
              );
              if (propOrder > -1) {
                value.push(
                  ...innerFilterObject.in[
                    innerFilterObject.in.findIndex(
                      (object) => Object.keys(object)[0] === "card_id"
                    )
                  ].card_id
                );
                filterData.in[propOrder] = { card_id: value };
              } else {
                filterData.in.push({ card_id: value });
              }
              setInnerFilterObject(filterData);
            } else {
              setInnerFilterObject({
                ...innerFilterObject,
                in: [{ card_id: value }],
              });
            }
          }}
          onSearch={handleSearch}
        >
          {members.length > 0
            ? members.map((member) =>
                member.card_id ? (
                  <Option
                    key={
                      member.first_name +
                      " " +
                      member.last_name +
                      "-" +
                      member.tc_no
                    }
                    value={member.card_id}
                  >
                    {member.first_name +
                      " " +
                      member.last_name +
                      "-" +
                      member.tc_no}
                  </Option>
                ) : null
              )
            : null}
        </Select>
      ),
    },
  ];

  const rangerColumns = [
    {
      label: "Doğum yılı",
      value: "birthdate",
      // prettier-ignore
      fieldName: rangerFilterObject && rangerFilterObject.between instanceof Array ? ["filter", "between", rangerFilterObject.between.findIndex(object => Object.keys(object)[0] === "birthdate"), "birthdate"] : undefined,
      component: (
        <DatePicker.RangePicker
          style={{ width: "150%" }}
          locale={{ ...locale }}
          format="YYYY-MM-DD"
          onChange={(date, dateIndex) => {
            let filterData = { ...rangerFilterObject };
            if (rangerFilterObject?.between instanceof Array) {
              const propOrder = rangerFilterObject.between.findIndex(
                (object) => Object.keys(object)[0] === "birthdate"
              );
              if (propOrder > -1) {
                filterData.between[propOrder] = { birthdate: [date] };
              } else {
                filterData.between.push({ birthdate: [date] });
              }
              setRangerFilterObject(rangerFilterObject);
            } else {
              setRangerFilterObject({
                ...rangerFilterObject,
                between: [{ birthdate: [date] }],
              });
            }
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      onOk={() => props.setVisible(false)}
      closeIcon={
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            marginTop: 10,
            marginLeft: 5,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          <CloseOutlined />
        </div>
      }
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {isUpdate ? (
            <Popconfirm
              title="Kaydı silmek istediğinize emin misiniz?"
              onConfirm={() =>
                deleteRecord(`id=${props.record.id}`, "calendars")
              }
              onCancel={() => {}}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Etkinliği Sil
              </Button>
            </Popconfirm>
          ) : (
            <div></div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleClick}
            >
              Kaydet
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => props.setVisible(false)}
            >
              {props.type === "request" ? "Reddet" : "İptal"}
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form style={{ margin: "5% 1%", padding: "5%" }} {...layout} form={form}>
        <Form.Item label="Title" name="title">
          <Input name="title" onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Açıklama" name="comment">
          <Input.TextArea
            name="comment"
            onChange={handleChange}
            allowClear
            showCount
          />
        </Form.Item>
        <Form.Item label="Tarih" name="event_date">
          <DatePicker
            style={{ width: "100%" }}
            locale={{ ...locale }}
            format="YYYY-MM-DD"
            onChange={(date, dateIndex) =>
              handleChange({ target: { name: "event_date", value: dateIndex } })
            }
          />
        </Form.Item>
        <Form.Item label="Zaman aralığı" style={{ marginBottom: 0 }}>
          <Row gutter={24}>
            <Col md={12}>
              <Form.Item name="start_time">
                <TimePicker
                  placeholder="Başlangıç"
                  locale={{ ...locale }}
                  onChange={(date, timeIndex) =>
                    handleChange({
                      target: {
                        name: "start_time",
                        value: timeIndex,
                      },
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name="finish_time">
                <TimePicker
                  placeholder="Bitiş"
                  locale={{ ...locale }}
                  onChange={(date, timeIndex) =>
                    handleChange({
                      target: {
                        name: "finish_time",
                        value: timeIndex,
                      },
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Tarife" name="schedule">
          <Select
            disabled={
              schedules.length === 1 && schedules.includes("unauthorized")
            }
            allowClear
            showArrow
            onSelect={(value) => {
              handleChange({ target: { name: "schedule", value } });
            }}
          >
            {typeof schedules?.map === "function"
              ? schedules?.map((schedule) => {
                  const schedulename =
                    schedule.schedule_name.charAt(0).toUpperCase() +
                    schedule.schedule_name.slice(1);
                  return (
                    <>
                      <Option value={schedule.id}>{schedulename}</Option>
                    </>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={() => setShowFilters(!showFilters)}>
            Filtreleri Göster
          </Checkbox>
        </Form.Item>
        {showFilters ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%",
            }}
          >
            <Form.Item style={{ width: "100%", marginLeft: 10 }}>
              <Checkbox
                style={{ marginBottom: 10 }}
                defaultChecked={
                  innerFilterObject &&
                  innerFilterObject.hasOwnProperty("in") === true
                    ? true
                    : false
                }
                onChange={() => setOpenInner(!openInner)}
              >
                İçindedir
              </Checkbox>
              {openInner ? (
                <>
                  {innerColumns.map((column) => (
                    <Form.Item
                      name={column?.fieldName}
                      label={column.label}
                      style={{ width: "100%" }}
                      labelCol={{ span: 10, offset: 0 }}
                    >
                      {column.component || <Input />}
                    </Form.Item>
                  ))}
                </>
              ) : null}
            </Form.Item>
            <Form.Item style={{ width: "100%", marginLeft: 10 }}>
              <Checkbox
                defaultChecked={
                  rangerFilterObject &&
                  rangerFilterObject.hasOwnProperty("between") === true
                    ? true
                    : false
                }
                onChange={() => setOpenRanger(!openRanger)}
                style={{ marginBottom: 10 }}
              >
                Arasındadır
              </Checkbox>
              {openRanger ? (
                <>
                  {rangerColumns.map((column) => (
                    <Form.Item
                      name={column?.fieldName}
                      label={column.label}
                      style={{ width: "100%" }}
                      labelCol={{ span: 10, offset: 0 }}
                    >
                      {column.component || <Input />}
                    </Form.Item>
                  ))}
                </>
              ) : null}
            </Form.Item>
          </div>
        ) : null}
      </Form>
    </Modal>
  );
};

export default CalendarModal;
