import { API_URL } from "../config";
import React from "react";
import { Button, Tag } from "antd";
import { deleteRecord } from "../assets/scripts";
import CTable from "../components/CTable";
import TableActions from "../components/TableActions";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import ListMembersCell from "../components/ListMembersCell";

const days = { Monday: "Pazertesi", Tuesday: "Salı", Wednesday: "Çarşamba", Thursday: "Perşembe", Friday: "Cuma", Saturday: "Cumartesi", "Sunday": "Pazar" };

const excelColumns = [
  { title: "Kurs Adı", dataIndex: "name" },
  { title: "Antrenör", dataIndex: "trainer_name" },
  { title: "Günler", dataIndex: "days" },
  { title: "Saat Aralığı", dataIndex: "time_range" },
  { title: "Başlangıç Tarihi", dataIndex: "start_date" },
  { title: "Bitiş Tarihi", dataIndex: "end_date" },
  { title: "Kriterler", dataIndex: "filter" },
  { title: "Ücret", dataIndex: "cost" },
  { title: "Tekrar sayısı", dataIndex: "repetition" },
  { title: "Tesis", dataIndex: "facility_id" },
];


const Courses = () => {
  const [modalMemberListInfo, setModalMemberListInfo] = React.useState({ data: null, open: false });
  const initialColumns = [
    {
      title: "Kurs Adı",
      dataIndex: "name",
      key: "name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      width: 150,
    },
    {
      title: "Kurs ID",
      dataIndex: "course_id",
      key: "course_id",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
      width: 150,
    },
    {
      title: "Antrenörler",
      dataIndex: "trainer_name",
      key: "trainer_name",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      width: 150,
    },
    {
      title: "Günler",
      width: 150,
      dataIndex: "days",
      key: "days",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: items => {
        return items?.map(i => days[i])?.join(" - ");
      }
    },
    {
      title: "Saat Aralığı",
      width: 150,
      dataIndex: "time_range",
      key: "time_range",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (_, row) => {
        return row.start_time && row.start_time + " - " + row.finish_time;
      }
    },
    {
      title: "Başlangıç Tarihi",
      width: 150,
      dataIndex: "start_date",
      key: "start_date",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item?.split("T")?.[0]
      }
    },
    {
      title: "Bitiş Tarihi",
      width: 150,
      dataIndex: "end_date",
      key: "end_date",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item?.split("T")?.[0]
      }
    },

    {
      title: "Kriterler",
      width: 150,
      dataIndex: "filter",
      key: "filter",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: item => {
        const parsed = JSON.parse(item);
        const genders = { female: "Kadın", male: "Erkek" };
        const gender = parsed.gender.map(i => genders[i]).join(" - ");
        const age = parsed.ages?.finish_age ? parsed.ages.start_age + " - " + parsed.ages.finish_age : parsed.ages.start_age + " + ";

        return <div>
          <p>Cinsiyetler : {gender}</p>
          <p>Yaş Aralığı : {age}</p>
        </div>

      }
    },
    {
      title: "Ücret",
      width: 150,
      dataIndex: "cost",
      key: "cost",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item + " TL"
      }
    },
    {
      title: "Tekrar sayısı",
      width: 150,
      dataIndex: "repetition",
      key: "repetition",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Kayıtlı kursiyerler",
      width: 150,
      dataIndex: "tcnos",
      key: "tcnos",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
      render: i => i?.length
    },

    {
      title: "Üyeler",
      dataIndex: "members",
      key: "members",
      responsive: ["xs", "md", "lg"],
      width: 100,
      defaultSelected: true,
      render: (_, row) => {
        return (
          row.members && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setAll(true)
                  setModalMemberListInfo({
                    open: true,
                    data: row.members,
                  });
                }}
              >
                Üyeleri görüntüle
              </Button>
            </div>
          )
        );
      },
    },
    {
      title: "Kursiyerler kontenjanı",
      width: 150,
      dataIndex: "total_students",
      key: "total_students",
      defaultSelected: true,
      responsive: ["xs", "md", "lg"],
    },
    {
      title: "Antrenör girişi gerekli",
      width: 150,
      dataIndex: "required_trainer",
      key: "required_trainer",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
      render: (item) => {
        return item ? "Evet" : "Hayır"
      }
    },
    {
      title: "Tesis",
      width: 150,
      dataIndex: "facility_id",
      key: "facility_id",
      defaultSelected: false,
      responsive: ["xs", "md", "lg"],
    },

    {
      title: "İşlemler",
      dataIndex: "actions",
      key: "actions",
      width: 90,
      defaultSelected: true,
      render: (item, row, idx) => (
        <TableActions
          type="courses"
          row={row}
          columns={excelColumns}
          onClick={(event) => {
            return deleteRecord(`id=${row.id}`, "courses")
          }}
        />
      ),
      fixed: "right",
    },
  ];
  const [all, setAll] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setColumns(initialColumns);

    fetch(`${API_URL}/api/v1/courses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setDataSource(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <CTable
        title="KURSLAR"
        excel={false}
        scrollX={3500}
        dataSource={dataSource}
        columns={columns}
        isLoading={isLoading}
        type="course"
      />
      <ListMembersCell
        datasource={modalMemberListInfo.data}
        all={all}
        modalInfo={modalMemberListInfo}
        setModalInfo={setModalMemberListInfo}
        setAll={setAll}
      />
    </ErrorBoundary>
  );
};

export default Courses;
