import { Card, Tag, Typography } from "antd";
import React from "react";

const CalendarCell = (props) => {
  const [dataWillRender, setDataWillRender] = React.useState({});

  React.useEffect(() => {
    if (Object.keys(props.data).length > 0) {
      setDataWillRender(props.data);
    }
  }, [props]);

  return (
    <Card
      style={{
        backgroundColor: props?.type === "Program" ? "#e6fffb" : "#d9f7be",
        border:
          props?.type === "Program" ? "1px solid #87e8de" : "1px solid #73d13d",
        marginBottom: "3%",
        borderRadius: 10,
        boxShadow: "none",
      }}
    >
      <Typography style={{ marginTop: 0, marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography.Title level={5}>{dataWillRender?.title}</Typography.Title>
          <span style={{ width: 3 }}></span>
          <h6>({props?.type})</h6>
        </div>
        <Typography.Paragraph>{dataWillRender?.comment}</Typography.Paragraph>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              marginBottom: 10,
            }}
          >
            Etkinlik zamanı: {dataWillRender?.start_time} -{" "}
            {dataWillRender?.finish_time}1
          </div>
        </div>
      </Typography>
    </Card>
  );
};

export default CalendarCell;
