const nations = [
  "Abhazya",
  "Afganistan",
  "Almanya",
  "Amerika Birleşik Devletleri",
  "Andorra",
  "Angola",
  "Antigua ve Barbuda",
  "Arjantin",
  "Arnavutluk",
  "Avustralya",
  "Avusturya",
  "Azerbaycan",
  "Bahamalar",
  "Bahreyn",
  "Bangladeş",
  "Barbados",
  "Batı Sahra",
  "Belarus",
  "Belçika",
  "Belize",
  "Benin",
  "Bhutan",
  "Birleşik Arap Emirlikleri",
  "Bolivya",
  "Bosna Hersek",
  "Botsvana",
  "Brezilya",
  "Brunei",
  "Bulgaristan",
  "Burkina Faso",
  "Burundi",
  "Cezayir",
  "Cibuti Cumhuriyeti",
  "Çad",
  "Çek Cumhuriyeti",
  "Çin Halk Cumhuriyeti",
  "Dağlık Karabağ Cumhuriyeti",
  "Danimarka",
  "Doğu Timor",
  "Dominik Cumhuriyeti",
  "Dominika",
  "Ekvador",
  "Ekvator Ginesi",
  "El Salvador",
  "Endonezya",
  "Eritre",
  "Ermenistan",
  "Estonya",
  "Etiyopya",
  "Fas",
  "Fiji",
  "Fildişi Sahilleri",
  "Filipinler",
  "Filistin",
  "Finlandiya",
  "Fransa",
  "Gabon",
  "Gambiya",
  "Gana",
  "Gine Bissau",
  "Gine",
  "Grenada",
  "Guyana",
  "Guatemala",
  "Güney Afrika Cumhuriyeti",
  "Güney Kore",
  "Güney Osetya",
  "Gürcistan",
  "Haiti",
  "Hırvatistan",
  "Hindistan",
  "Hollanda",
  "Honduras",
  "Irak",
  "İngiltere",
  "İran",
  "İrlanda",
  "İspanya",
  "İsrail",
  "İsveç",
  "İsviçre",
  "İtalya",
  "İzlanda",
  "Jamaika",
  "Japonya",
  "Kamboçya",
  "Kamerun",
  "Kanada",
  "Karadağ",
  "Katar",
  "Kazakistan",
  "Kenya",
  "Kırgızistan",
  "Kıbrıs Cumhuriyeti",
  "Kiribati",
  "Kolombiya",
  "Komorlar",
  "Kongo",
  "Kongo Demokratik Cumhuriyeti",
  "Kosova",
  "Kosta Rika",
  "Kuveyt",
  "Kuzey Kıbrıs Türk Cumhuriyeti",
  "Kuzey Kore",
  "Küba",
  "Lakota Cumhuriyeti",
  "Laos",
  "Lesotho",
  "Letonya",
  "Liberya",
  "Libya",
  "Liechtenstein",
  "Litvanya",
  "Lübnan",
  "Lüksemburg",
  "Macaristan",
  "Madagaskar",
  "Makedonya Cumhuriyeti",
  "Malavi",
  "Maldivler",
  "Malezya",
  "Mali",
  "Malta",
  "Marshall Adaları",
  "Meksika",
  "Mısır",
  "Mikronezya",
  "Moğolistan",
  "Moldova",
  "Monako",
  "Moritanya",
  "Moritus",
  "Mozambik",
  "Myanmar",
  "Namibya",
  "Nauru",
  "Nepal",
  "Nikaragua",
  "Nijer",
  "Nijerya",
  "Norveç",
  "Orta Afrika Cumhuriyeti",
  "Özbekistan",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua Yeni Gine",
  "Paraguay",
  "Peru",
  "Polonya",
  "Portekiz",
  "Romanya",
  "Ruanda",
  "Rusya Federasyonu",
  "Saint Kitts ve Nevis",
  "Saint Lucia",
  "Saint Vincent ve Grenadinler",
  "Samoa",
  "San Marino",
  "Sao Tome ve Principe",
  "Sealand",
  "Senegal",
  "Seyşeller",
  "Sırbistan",
  "Sierra Leone",
  "Singapur",
  "Slovakya",
  "Slovenya",
  "Solomon Adaları",
  "Somali",
  "Somaliland",
  "Sri Lanka",
  "Sudan",
  "Surinam",
  "Suudi Arabistan",
  "Suriye",
  "Svaziland",
  "Şili",
  "Tacikistan",
  "Tamil Eelam",
  "Tanzanya",
  "Tayland",
  "Tayvan",
  "Togo",
  "Tonga",
  "Transdinyester",
  "Trinidad ve Tobago",
  "Tunus",
  "Tuvalu",
  "Türkiye",
  "Türkmenistan",
  "Uganda",
  "Ukrayna",
  "Umman",
  "Uruguay",
  "Ürdün",
  "Vanuatu",
  "Vatikan",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Yeni Zelanda",
  "Yeşil Burun",
  "Yunanistan",
  "Zambiya",
  "Zimbabve",
  "Diğer",
];
export default nations;
