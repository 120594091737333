import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Upload,
  Tooltip,
  Image,
} from "antd";
import { API_URL } from "../config";
import { responseControl } from "../assets/scripts";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../helpers/ErrorFallback";
import { UploadOutlined } from "@ant-design/icons";

const UserSettings = () => {
  const [userData, setUserData] = React.useState({});
  const [userId, setUserId] = React.useState(null);
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const [form] = Form.useForm();
  React.useEffect(() => {
    fetch(`${API_URL}/api/v1/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        return responseControl(response);
      })
      .then((result) => {
        setUserId(result[0]?.user_id);
        //prettier-ignore
        setProfilePhoto(`${API_URL}/${result[0]?.profile_photo?.split("/")?.pop()}`)
        form.setFieldsValue(result[0]);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (event) =>
    setUserData({ ...userData, [event.target.name]: event.target.value });
  const handleClick = () =>
    fetch(`${API_URL}/api/v1/me`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => responseControl(response))
      .then(() => localStorage.removeItem("access_token"))
      .then(() => window.location.reload())
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
          ? message.error(error)
          : console.log(error)
      );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleProfilePhotoChange = (info) => {
    try {
      getBase64(info.file.originFileObj, (imageUrl) =>
        setProfilePhoto(imageUrl)
      );
    } catch (error) {
      notification.error({ message: error?.message || error });
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = ["image/png", "image/jpg", "image/jpeg"].includes(
      file.type
    );
    if (!isJpgOrPng) {
      message.error(
        "Sadece PNG, JPG ve JPEG formatında dosyaları yükleyebilirsiniz!"
      );
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 150;
    if (!isLt2M) {
      message.error("Dosya boyutu en fazla 150MB olabilir!");
      return Upload.LIST_IGNORE;
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <ErrorBoundary
      FallbackComponnt={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Card style={{ margin: "1%" }}>
        <Row gutter={24}>
          <Col md={12}>
            <Form
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 14,
              }}
              form={form}
            >
              <Image
                src={profilePhoto}
                style={{
                  width: "50%",
                  height: "50%",
                }}
              />
              <Form.Item
                name="profile_photo"
                style={{
                  displa: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Upload
                  action={`${API_URL}/api/v1/profile_photo`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(
                      "access_token"
                    )}`,
                    user_id: userId,
                  }}
                  method="POST"
                  onChange={handleProfilePhotoChange}
                  maxCount={1}
                  multiple={false}
                  progress
                  showUploadList={true}
                  beforeUpload={beforeUpload}
                  accept=".png,.jpg"
                >
                  <Tooltip
                    placement="right"
                    title={"Yükleme yapılırsa, mevcut fotoğraf silinecektir."}
                    visible
                    color={"red"}
                  >
                    <Button icon={<UploadOutlined />}>Fotoğraf yükle</Button>
                  </Tooltip>
                </Upload>
              </Form.Item>
              <Form.Item label="Ad Soyad" name="full_name">
                <Input name="full_name" onChange={handleChange} />
              </Form.Item>
              <Form.Item label="Kullanıcı Adı" name="username">
                <Input name="username" onChange={handleChange} />
              </Form.Item>
              <Form.Item label="Eposta" name="email">
                <Input name="email" onChange={handleChange} />
              </Form.Item>
              <Form.Item label="Telefon" name="phone">
                <Input name="phone" onChange={handleChange} />
              </Form.Item>
              <Form.Item label="Rol" name={["user_role", "role_name"]}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Parola"
                name="password"
                rules={[
                  {
                    warningOnly: false,
                    type: "string",
                    whitespace: true,
                  },
                  () => ({
                    async validator(_, value) {
                      if (value?.length < 6) {
                        return Promise.reject(
                          new Error("Parola en az 6 karakter olmalı.")
                        );
                      }
                      if (!/\d/.test(value)) {
                        return Promise.reject(
                          new Error("Parola en az bir numara içermelidir.")
                        );
                      }
                      if (!/[a-z]/.test(value)) {
                        return Promise.reject(
                          new Error("Parola en az bir küçük harf içermelidir.")
                        );
                      }
                      if (!/[A-Z]/.test(value)) {
                        return Promise.reject(
                          new Error("Parola en az bir büyük harf içermelidir.")
                        );
                      }
                    },
                  }),
                ]}
                validateTrigger="onBlur"
                tooltip={
                  <div>
                    Parola aşağıdaki kurallarda yazılmalıdır:
                    <ol>
                      <li>En az 6 karakter uzunluğunda olmalı,</li>
                      <li>En az bir numara içermeli,</li>
                      <li>En az bir küçük harf içermeli,</li>
                      <li>En az bir büyük harf içermeli.</li>
                    </ol>
                  </div>
                }
              >
                <Input
                  name="password"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
                <Popconfirm
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "start",
                      }}
                    >
                      <p>
                        Bilgilerinizi değiştirirseniz yeniden giriş yapmanız
                        gerekecektir.{" "}
                      </p>
                      <p>Devam etmek ister misiniz?</p>
                    </div>
                  }
                  onConfirm={handleClick}
                  onCancel={() => {
                    return;
                  }}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button htmlType="submit" type="primary">
                    Gönder
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          </Col>
          <Col md={12}></Col>
        </Row>
      </Card>
    </ErrorBoundary>
  );
};

export default UserSettings;
