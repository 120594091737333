import React from "react";
import { Button, Input, Space, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/tr_TR";

const { RangePicker } = DatePicker;

const FilterDropdown = (props) => {
  const handleChange = (value, dateString) =>
    props.setSelectedKeys([dateString.join(","), ""]);

  return (
    <div style={{ padding: 8 }}>
      {props.isDate ? (
        <div style={{ display: "block" }}>
          <RangePicker
            showTime={props?.timeFilter === true}
            locale={locale}
            onChange={handleChange}
            style={{ marginBottom: 8 }}
            placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
          />
        </div>
      ) : (
        <Input
          ref={(node) => {
            props.setSearchInput.current = node;
          }}
          placeholder={`Arama`}
          value={props.selectedKeys[0]}
          onChange={(e) =>
            props.setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            props.handleSearch(
              props.selectedKeys,
              props.confirm,
              props.dataIndex
            )
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      )}
      <Space>
        <Button
          type="primary"
          onClick={() =>
            props.handleSearch(
              props.selectedKeys,
              props.confirm,
              props.dataIndex
            )
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Arama
        </Button>
        <Button
          onClick={() =>
            props.handleReset(
              props.setSelectedKeys,
              props.clearFilters,
              props.confirm
            )
          }
          size="small"
          style={{ width: 90 }}
        >
          Temizle
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            props.confirm({ closeDropdown: false });
            props.setSearchText(props.selectedKeys[0]);
            props.setSearchedColumn(props.dataIndex);
          }}
        >
          Filtrele
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropdown;
