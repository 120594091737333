import { API_URL } from "../config";
import {
  Button,
  message,
  Popconfirm,
  Space,
  Upload,
  Tag,
  notification,
  DatePicker,
} from "antd";
import { useReactToPrint } from "react-to-print";
import React from "react";
import {
  DeleteOutlined,
  UploadOutlined,
  SendOutlined,
  FilePdfOutlined,
  VerifiedOutlined,
  CreditCardOutlined,
  FileExcelOutlined,
  CloseCircleFilled,
  CheckCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { responseControl } from "../assets/scripts";
import PDFTemplate from "./PDFTemplate";
import CardTemplate from "./CardTemplate";
import XLSX from "xlsx";
import moment from "moment";
import { useHistory } from "react-router-dom";

const pageStyle = `
  @page {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    size: legal;
    margin: 1in;
    
  }
  @media all {
    .pagebreak {
      page-break-before: always;
    }
  }
  div {
    display: inline-block;
  }
  `;
const cardStyle = `
  @page {
    size: auto; 
    margin: 0
  }
  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    body { 
      -webkit-print-color-adjust: exact; 
    }
  }
  `;
const TableActions = (props) => {
  const [fileWillSend, setFileWillSend] = React.useState({});
  const [uploading, setUploading] = React.useState(false);
  const [deviceStatus, setDeviceSatus] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const componentRef = React.useRef();
  const cardTemplate = React.useRef();
  const nextYear = new Date();
  nextYear.setFullYear(nextYear.getFullYear() + 1)
  const [expiryDate, setExpiryDate] = React.useState(nextYear);
  React.useEffect(() => {
    if (Object.keys(fileWillSend).length > 0) {
      setUploading(false);
    }

    if (props?.type === "device") {
      checkDeviceStatus();
    }
  }, [fileWillSend, props]);

  const handleConfirm = (event) => {
    event.stopPropagation();
    if (props.type === "verification") {
      return props.onClick(expiryDate);
    }
    return props.onClick();
  };

  const handleRefuse = (event) => {
    event.stopPropagation();
    if (props.type === "verification") {
      return props.onRefuse();
    }
    return props.onRefuse();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    return;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleUpload = (file) => {
    setUploading(true);

    getBase64(file, (imageUrl) =>
      setFileWillSend({ base64: imageUrl, file_name: file.name })
    );
    return false;
  };

  const uploadFile = (event) => {
    const data = {
      ...fileWillSend,
      card_id: props.card_id,
      date: new Date().getTime(),
    };

    fetch(`${API_URL}/api/v1/files`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          return responseControl(response);
        } else {
          return message.error(response.statusText);
        }
      })
      .then((result) => result)
      .catch((error) => message.error(error.message))
      .finally(() => {
        setFileWillSend({});
        setUploading(false);
      });
  };

  const checkDeviceStatus = async (event) => {
    event?.stopPropagation();
    setIsLoading(true);
    if (Boolean(props.deviceIP)) {
      try {
        await fetch(`${API_URL}/api/v1/device_status/${props.deviceIP}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((response) => response.json())
          .then((result) => setDeviceSatus(result));
      } catch (error) {
        if (error instanceof Error) {
          return notification.error({ message: error.message });
        }
        return notification.error({ message: error });
      }
    } else {
      return notification.error({ message: "IP BOŞ" });
    }
    setIsLoading(false);
  };

  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentRef.current,
  });

  const handleCard = useReactToPrint({
    pageStyle: cardStyle,
    content: () => cardTemplate.current,
  });

  const handleClubMember = async (event) => {
    event.stopPropagation();
    return await fetch(
      `${API_URL}/api/v1/clubMembers/?id=${props.row.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => responseControl(response))
      .then((result) => exportToExcel(result, "club"))
      .catch((error) =>
        error instanceof Error
          ? message.error(error.message)
          : typeof error === "string"
            ? message.error(error)
            : console.log(error)
      );
  };

  const exportToExcel = (data, type) => {
    if (!data?.length) {
      notification.error({ message: "Veri yok" })
    }
    const fileName = `${window.location.pathname.split("/")[1]}-${new Date()
      .toLocaleDateString("tr-TR")
      .split(".")
      .reverse()
      .join("-")}.xlsx`;

    const dataWillRender = [];
    /* make the worksheet */
    const selectedColumn = type === "club" ? Object.keys(data?.[0]) : props.columns.map((column) => column.dataIndex);

    data.map((obj) => {
      const objWillPush = {};
      Object.keys(obj).map((key) => {
        if (selectedColumn.includes(key)) {
          objWillPush[key] = obj[key];
        }
      });
      if (type !== "club") {
        objWillPush.card_number = obj.cards[0].card_number;
        objWillPush.credits = obj.cards[0].credits;
        objWillPush.club = props.row.club_name;
      }
      dataWillRender.push(objWillPush);
    });

    var ws = XLSX.utils.json_to_sheet(dataWillRender);
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C

      if (!ws[address]) continue;

      props.columns.map((column) => {
        if (ws[address].v === column.dataIndex) {
          ws[address].v = column.title;
        }
      });
    }
    /* add to workbook */
    var wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "People");

    /* generate an XLSX file */
    return XLSX.writeFile(wb, fileName);
  };

  const location = {
    pathname: '/health-check',
    state: { tcNo: props.tcNo, fullName: props.fullName }
  };
  let history = useHistory();

  const navigate = (e) => {
    e.stopPropagation();
    return history.push(location);
  }
  return (
    <div style={{ display: "flex" }}>
      <Space>
        {props.getPDF === true ? (
          <Button
            className="pdf-export-action-btn"
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              handlePrint();
            }}
            icon={<FilePdfOutlined />}
          >
            PDF Aktar
          </Button>
        ) : null}
        {props.create_card === true ? (
          <Button
            className="print-card"
            type="primary"
            onClick={(event) => {
              event.stopPropagation();
              if (props?.record?.cards[0]?.card_number?.length > 0) {
                handleCard();
              } else {
                message.error({
                  content: "Kullanıcıya kart numarası atanmalı!",
                  style: {
                    marginTop: "25vh",
                    padding: 50,
                    fontSize: 18,
                  },
                });
              }
            }}
            icon={<CreditCardOutlined />}
            style={{ backgroundColor: "#fa8c16", borderColor: "#fa8c16" }}
          >
            Kart Bastır
          </Button>
        ) : null}
        {props.type === "member" ? (
          <div>
            <Button
              type="primary"
              onClick={navigate}
              icon={<EyeOutlined />}
              style={{ marginRight: ".5em" }}
            >
              Sağlık Raporuna Git
            </Button>
            {Object.keys(fileWillSend).length > 0 ? (
              <Button
                type="primary"
                onClick={uploadFile}
                icon={<SendOutlined />}
              >
                Dosyayı Gönder
              </Button>
            ) : (
              <Upload beforeUpload={handleUpload} accept=".pdf">
                <Button
                  loading={uploading}
                  icon={<UploadOutlined />}
                  className="upload-file"
                >
                  Dosya Yükle
                </Button>
              </Upload>
            )}
          </div>
        ) : props.type === "verification" ? (
          ""
        ) : props.type === "club" ? (
          <Button icon={<FileExcelOutlined />} onClick={handleClubMember}>
            Üye Listesini Al
          </Button>
        ) : props.type === "device" ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#52c41a",
                borderColor: "#52c41a",
                marginLeft: "3%",
              }}
              onClick={(event) => checkDeviceStatus(event)}
              loading={isLoading}
            >
              Kontrol Et
            </Button>
            <Tag
              style={{
                marginLeft: "3%",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              color={deviceStatus?.alive ? "success" : "error"}
              //prettier-ignore
              icon={deviceStatus?.alive ? <CheckCircleOutlined /> : <CloseCircleFilled />}
            >
              {deviceStatus?.alive ? "Aktif" : "Pasif"}
            </Tag>
          </div>
        ) : null}

        {
          props.type === "refunds" ?
            <div style={{ display: "flex", gap: "1em" }}>
              <Button
                type="primary"
                danger
                icon={<VerifiedOutlined />}
                disabled={props.disabled}
                style={{ marginRight: "1em" }}
                onClick={handleRefuse}
              >
                Reddet
              </Button>
              <Popconfirm
                title="Üyeyi onaylamak istediğinize emin misiniz?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                okText="Evet"
                cancelText="Hayır"
                disabled={props.disabled}
              >
                <Button
                  type="primary"
                  icon={<VerifiedOutlined />}
                  disabled={props.disabled}
                >
                  Onayla
                </Button>
              </Popconfirm>
            </div>
            :
            props.deleteButton === false ? props.type === "verification" ?

              (<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "250px" }}>
                <p>Tarife güncelleme tarihi</p>
                <DatePicker style={{ marginBottom: "1em" }} disabledDate={props.disabledDate} value={moment(expiryDate)} onChange={(date,) => {
                  setExpiryDate(date)
                }} />
                <div>
                  <Button
                    type="primary"
                    danger
                    icon={<VerifiedOutlined />}
                    disabled={props.disabled}
                    style={{ marginRight: "1em" }}
                    onClick={handleRefuse}
                  >
                    Reddet
                  </Button>
                  <Popconfirm
                    title="Üyeyi onaylamak istediğinize emin misiniz?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    okText="Evet"
                    cancelText="Hayır"
                    disabled={props.disabled}
                  >
                    <Button
                      type="primary"
                      icon={<VerifiedOutlined />}
                      disabled={props.disabled}
                    >
                      Onayla
                    </Button>
                  </Popconfirm>
                </div>
              </div>
              )
              : (
                <Popconfirm
                  title="Üyeyi onaylamak istediğinize emin misiniz?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  okText="Evet"
                  cancelText="Hayır"
                  disabled={props.disabled}
                >
                  <Button
                    type="primary"
                    icon={<VerifiedOutlined />}
                    disabled={props.disabled}
                  >
                    Onayla
                  </Button>
                </Popconfirm>
              ) : props.update ? (
                <Popconfirm
                  title="İade talepini onaylamak istiyor musunuz?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button type="primary" icon={<VerifiedOutlined />}>
                    Onayla
                  </Button>
                </Popconfirm>
              ) : props.type !== "reports" ? (
                <Popconfirm
                  title="Kaydı silmek istediğinize emin misiniz?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button type="primary" danger icon={<DeleteOutlined />}>
                    Kaydı Sil
                  </Button>
                </Popconfirm>
              ) : null}
      </Space>
      <div style={{ display: "none" }}>
        <PDFTemplate data={props.record} ref={componentRef} />
        <CardTemplate data={props.record} ref={cardTemplate} />
      </div>
    </div>
  );
};

export default TableActions;
